// Luo tiedosto fi/index.js
const fi = {
  route: {
    home: 'Koti',
    shopCart: 'Ostoskori',
    popularStore: 'Suosittu kauppa',
    storeDetails: 'Kaupan tiedot',
    hotSellingProducts: 'Kuumin myynti',
    productClassification: 'Tuoteluokitus',
    productDetails: 'Tuotetiedot',
    orderPay: 'Tilauksen maksu',
  },
  home: "Koti",
  wallet: "Lompakko",
  order: "Tilauskeskus",
  sell: "Myyntikeskus",
  cart: "Kori",
  search: "Haku",
  deals: "Päivän tarjoukset",
  featuredStore: "Suositeltu kauppa",
  spotlight: "Spotlight",
  hotSale: "Kuuma myynti",
  fashion: "Muoti",
  beautyPersonalCare: "Kauneus & henkilökohtainen hoito",
  homeKitchen: "Koti & keittiö",
  sportsOutdoors: "Urheilu & ulkoilu",
  electronicsAccessories: "Elektroniikka & tarvikkeet",
  seeAll: "Näytä kaikki",
  category: "Kategoria",
  beauty: 'Kauneus',
  personalCare: 'Henkilökohtainen hoito',
  homeFurnishing: 'Sisustus',
  kitchen: 'Keittiö',
  sports: 'Urheilu',
  outdoors: 'Ulkoilu',
  electronics: 'Elektroniikka',
  accessories: 'Tarvikkeet',
  office: 'Toimisto',
  products: 'Tuotteet',
  food: 'Ruoka',
  drinks: 'Juomat',
  collectibles: 'Keräilyesineet',
  automotive: 'Autoilu',
  storeOnTikTokShop:'Kauppa TikTok Shopissa',
  viewShop:'Näytä kauppa',
  reviews:'Arvostelut',
  topProducts:'Parhaat tuotteet',
  newReleases:'Uutuudet',
  balance: 'Saldo',
  recharge: 'Lataa',
  withdraw: 'Nosta',
  fundAccount: 'Rahastotili',
  add: 'Lisää',
  addFundAccount: 'Lisää rahastotili',
  crypto: 'Kryptovaluutta',
  mainnet: 'Pääverkko',
  address: 'Osoite',
  debitCards: 'Pankkikortit',
  iban: 'IBAN',
  account: 'Tili',
  cardNumber: 'Kortin numero',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount:'Määrä',
  qrCode:'QR-koodi',
  rechargeAmountUsdt:'Latausmäärä (USDT)',
  pleaseEnterTheRechargeAmountUsdt:'Syötä latausmäärä (USDT)',
  submitForReview:'Lähetä tarkastettavaksi',
  purchased: "Ostettu",
  carriage: "Kuljetus",
  completed: "Valmis",
  awaitingShipment:'Odottaa lähettämistä',
  orderDetails: "Tilauksen tiedot",
  receive: "Vastaanota",
  review:'Arvostelu',
  enterprise: 'Yritys',
  personal:'Henkilökohtainen',
  businessName: 'Yrityksen nimi',
  headOfEnterprise: 'Yrityksen johtaja',
  headPhoneNumber: 'Johtajan puhelinnumero',
  headWhatsAppNumber: 'Johtajan WhatsApp-numero',
  submit: 'Lähetä',
  uploadLogo: 'Lataa logo',
  storeName: 'Kaupan nimi',
  storeEmail: 'Kaupan sähköposti',
  storeHeadName: 'Kaupan johtajan nimi',
  storeHeadIdNumber: 'Kaupan johtajan ID-numero',
  idFrontPhoto: 'ID:n etupuolen kuva',
  idBackPhoto: 'ID:n takapuolen kuva',
  personalHandheldIdPhoto: 'Henkilökohtainen kädessä pidettävä ID-kuva',
  storeHeadPhoneNumber: 'Kaupan johtajan puhelinnumero',
  storeSupportNumber: 'Kaupan tukinumero',
  invitationCode: 'Kutsukoodi',
  login: 'Kirjaudu sisään',
  createAccount: 'Luo tili',
  emailOrPhone: 'Sähköposti tai puhelinnumero',
  password: 'Salasana',
  signIn: 'Kirjaudu',
  yourName: 'Nimesi',
  reEnterPassword: 'Syötä salasana uudelleen',
  emailOrPhoneCode: 'Sähköposti- tai puhelinkoodi',
  request: 'Pyydä',
  createAccountButton: 'Luo tili',
  privacyPolicy: '《TikTokin tietosuojakäytäntö》',
  acknowledgmentText: 'Napsauttamalla Tallenna vahvistat lukeneesi {privacyPolicy}. Tietojasi voidaan käyttää kokemuksesi personointiin, mukaan lukien mainokset, asetustesi mukaan.',
  buy: 'Osta',
  exploreBargains: 'Tutustu laadukkaisiin tarjouksiin',
  aboutSpotlight: 'Tietoja Spotlightista',
  freeShipping: 'Ilmainen toimitus',
  freeShippingDescription: 'Tilauksiin yli 25 dollaria',
  freeReturns: 'Ilmainen palautus',
  freeReturnsDescription: '30 päivän sisällä',
  shopPerformance: 'Kaupan suorituskyky',
  betterThan: 'Parempi kuin 80% muista kaupoista',
  starRating: '4+ tähden arvio',
  shipsWithin: 'Lähetetään 2 päivän sisällä',
  reviewsCount: '({count} arvostelua)',
  cartEmpty: 'Ostoskorisi on tyhjä',
  selectAll: 'Valitse kaikki',
  totalItems: 'Yhteensä ({num} tuotetta)',
  checkout: 'Kassa',
  shippingReturnsTitle: 'Toimitus & palautus',
  estimatedDelivery: 'Arvioitu toimitus 11-13. heinäkuuta, $7.43',
  shippingTo: 'Toimitus osoitteeseen Denver, 17517',
  shipsOut: 'Yleensä lähetetään 1 arkipäivän sisällä',
  returnPolicyTitle: 'Palautuskäytäntö',
  addToCart: 'Lisää ostoskoriin',
  aboutThisProduct: 'Tietoja tästä tuotteesta',
  shippingAddress: 'Toimitusosoite',
  addAddress: 'Lisää osoite',
  paymentMethod: 'Maksutapa',
  shippingMethod: 'Toimitustapa',
  orderSummary: 'Tilauksen yhteenveto',
  subtotal: 'Välisumma',
  shipping: 'Toimitus',
  shippingToBeCalculated: 'laskettavana',
  total: 'Yhteensä ({num} tuotetta)',
  firstName: 'Etunimi',
  lastName: 'Sukunimi',
  street: 'Katu',
  apartment: 'Asunto, sviitti jne. (vapaaehtoinen)',
  city: 'Kaupunki',
  state: 'Osavaltio',
  zipCode: 'Postinumero',
  phone: 'Puhelin',
  save: 'Tallenna',
  privacyPolicyDescription: 'Napsauttamalla Tallenna vahvistat lukeneesi {privacyPolicy}. Tietojasi voidaan käyttää kokemuksesi personointiin, mukaan lukien mainokset, asetustesi mukaan.',
  usCountryCode: 'US+1',
  logout:'Kirjaudu ulos',
  selectShippingMethod: 'Valitse toimitustapa',
  standardShipping: "Normaali toimitus: ${amount}",
  estimatedDeliverys: "Arvioitu toimitus: {date}",
  taxes: "Verot",
  breadcrumbs: {
    classification: 'Luokitus',
    subClassification: 'Alaluokitus',
    selectedProduct: 'Valittu tuote',
  },
  downloadApp: 'Lataa sovellus',
  register: 'Rekisteröidy',
  verifiedPurchase: 'Vahvistettu ostos',
  pleaseInput: 'Ole hyvä ja anna ',
  code:'Koodi',
  effective:'Tehokas ',
  pleaseEnterPasswordAgain:'Syötä salasana uudelleen',
  passwordInconsistent:'Kaksi kertaa syötetyt salasanat eivät täsmää!',
  createdSuccessfully:'Luotu onnistuneesti',
  addedSuccessfully:'Lisätty onnistuneesti',
  modifyAddress:'Muokkaa osoitetta',
  modifiedSuccessfully:'Muokattu onnistuneesti',
  copy:'Teksti on kopioitu leikepöydälle!',
  rechargeSuccessful:'Lataus onnistui',
  deleteSuccessfully:'Poistettu onnistuneesti',
  paymentSuccessful:'Maksu onnistui',
  payOrder: 'Maksa tilaus',
  pay: 'Maksa',
  available: 'Saatavilla',
  enterPaymentPassword: 'Syötä 6-numeroinen maksusalasana',
  confirmSuccessfulReceiptOfGoods:'Vahvista onnistunut vastaanotto',
  correct:' Oikein ',
  pleaseUploadInFull:'Lataa tarvittavat kuvat kokonaan!',
  submittedSuccessfully:'Lähetetty onnistuneesti',
  evaluate:'Arvioi',
  PleaseFillInTheFormCompletely:'Täytä lomake kokonaan!',
  qrCodePlaceholder:'QR-koodi',
  verificationCodeSentSuccessfully:'Vahvistuskoodi lähetetty onnistuneesti, täytä ja lähetä 60 sekunnin kuluessa!',
  pleaseEnterYourEmailFirst:'Syötä ensin sähköpostiosoitteesi',
  ContactTheMerchant: "Ota yhteyttä kauppiaaseen",
  inputIssues: 'Syötä ongelmat',
}
export default fi

