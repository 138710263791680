// Creare fișier ro/index.js
const ro = {
  route: {
    home: 'Acasă',
    shopCart: 'Coș de cumpărături',
    popularStore: 'Magazin popular',
    storeDetails: 'Detalii magazin',
    hotSellingProducts: 'Produse vândute rapid',
    productClassification: 'Clasificare produse',
    productDetails: 'Detalii produs',
    orderPay: 'Plata comenzii',
  },
  home: "Acasă",
  wallet: "Portofel",
  order: "Centrul de comenzi",
  sell: "Centrul de vânzări",
  cart: "Coș",
  search: "Căutare",
  deals: "Ofertele de astăzi",
  featuredStore: "Magazin recomandat",
  spotlight: "Spotlight",
  hotSale: "Reduceri mari",
  fashion: "Modă",
  beautyPersonalCare: "Îngrijire personală și frumusețe",
  homeKitchen: "Casă și bucătărie",
  sportsOutdoors: "Sport și activități în aer liber",
  electronicsAccessories: "Electronice și accesorii",
  seeAll: "Vezi tot",
  category: "Categorie",
  beauty: 'Frumusețe',
  personalCare: 'Îngrijire personală',
  homeFurnishing: 'Mobilă',
  kitchen: 'Bucătărie',
  sports: 'Sport',
  outdoors: 'Activități în aer liber',
  electronics: 'Electronice',
  accessories: 'Accesorii',
  office: 'Birou',
  products: 'Produse',
  food: 'Mâncare',
  drinks: 'Băuturi',
  collectibles: 'Colecționabile',
  automotive: 'Automobile',
  storeOnTikTokShop:'Magazin pe TikTok Shop',
  viewShop:'Vezi magazinul',
  reviews:'Recenzii',
  topProducts:'Produse de top',
  newReleases:'Lansări noi',
  balance: 'Sold',
  recharge: 'Reîncarcă',
  withdraw: 'Retrage',
  fundAccount: 'Cont de fonduri',
  add: 'Adaugă',
  addFundAccount: 'Adaugă cont de fonduri',
  crypto: 'Crypto',
  mainnet: 'Mainnet',
  address: 'Adresă',
  debitCards: 'Carduri de debit',
  iban: 'IBAN',
  account: 'Cont',
  cardNumber: 'Număr de card',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount:'Sumă',
  qrCode:'Cod QR',
  rechargeAmountUsdt:'Suma reîncărcată (USDT)',
  pleaseEnterTheRechargeAmountUsdt:'Introduceți suma de reîncărcare (USDT)',
  submitForReview:'Trimite pentru revizuire',
  purchased: "Achiziționat",
  carriage: "Transport",
  completed: "Finalizat",
  awaitingShipment:'Așteptând expedierea',
  orderDetails: "Detalii comandă",
  receive: "Primește",
  review:'Recenzie',
  enterprise: 'Întreprindere',
  personal:'Personal',
  businessName: 'Nume afacere',
  headOfEnterprise: 'Șeful întreprinderii',
  headPhoneNumber: 'Număr de telefon al șefului',
  headWhatsAppNumber: 'Număr WhatsApp al șefului',
  submit: 'Trimite',
  uploadLogo: 'Încarcă siglă',
  storeName: 'Nume magazin',
  storeEmail: 'E-mail magazin',
  storeHeadName: 'Nume șef de magazin',
  storeHeadIdNumber: 'Număr ID șef de magazin',
  idFrontPhoto: 'Fotografie față ID',
  idBackPhoto: 'Fotografie spate ID',
  personalHandheldIdPhoto: 'Fotografie ID personal ținut în mână',
  storeHeadPhoneNumber: 'Număr de telefon șef de magazin',
  storeSupportNumber: 'Număr de suport magazin',
  invitationCode: 'Cod de invitație',
  login: 'Conectează-te',
  createAccount: 'Creează cont',
  emailOrPhone: 'E-mail sau număr de telefon mobil',
  password: 'Parolă',
  signIn: 'Autentifică-te',
  yourName: 'Numele tău',
  reEnterPassword: 'Reintroduceți parola',
  emailOrPhoneCode: 'Cod e-mail sau telefon',
  request: 'Solicită',
  createAccountButton: 'Creează cont',
  privacyPolicy: '《Politica de confidențialitate TikTok》',
  acknowledgmentText: 'Făcând clic pe Salvează, recunoști că ai citit {privacyPolicy}. Informațiile tale pot fi utilizate pentru personalizarea experienței tale, inclusiv a reclamelor, în funcție de setările tale.',
  buy: 'Cumpără',
  exploreBargains: 'Explorează oferte de calitate',
  aboutSpotlight: 'Despre Spotlight',
  freeShipping: 'Transport gratuit',
  freeShippingDescription: 'Pentru comenzi de peste 25$',
  freeReturns: 'Retur gratuit',
  freeReturnsDescription: 'În termen de 30 de zile',
  shopPerformance: 'Performanță magazin',
  betterThan: 'Mai bun decât 80% dintre alte magazine',
  starRating: 'Rating de 4+ stele',
  shipsWithin: 'Expediere în 2 zile',
  reviewsCount: '({count} recenzii)',
  cartEmpty: 'Coșul tău este gol',
  selectAll: 'Selectează tot',
  totalItems: 'Total ({num} articole)',
  checkout: 'Finalizare comandă',
  shippingReturnsTitle: 'Transport și retururi',
  estimatedDelivery: 'Livrare estimată 11-13 iulie, 7,43$',
  shippingTo: 'Expediere în Denver, 17517',
  shipsOut: 'De obicei se expediază într-o zi lucrătoare',
  returnPolicyTitle: 'Politica de retur',
  addToCart: 'Adaugă în coș',
  aboutThisProduct: 'Despre acest produs',
  shippingAddress: 'Adresă de livrare',
  addAddress: 'Adaugă o adresă',
  paymentMethod: 'Metodă de plată',
  shippingMethod: 'Metodă de transport',
  orderSummary: 'Sumar comandă',
  subtotal: 'Subtotal',
  shipping: 'Transport',
  shippingToBeCalculated: 'de calculat',
  total: 'Total ({num} articole)',
  firstName: 'Prenume',
  lastName: 'Nume',
  street: 'Stradă',
  apartment: 'Apartament, suită etc. (opțional)',
  city: 'Oraș',
  state: 'Stat',
  zipCode: 'Cod poștal',
  phone: 'Telefon',
  save: 'Salvează',
  privacyPolicyDescription: 'Făcând clic pe Salvează, recunoști că ai citit {privacyPolicy}. Informațiile tale pot fi utilizate pentru personalizarea experienței tale, inclusiv a reclamelor, în funcție de setările tale.',
  usCountryCode: 'SUA+1',
  logout:'Deconectare',
  selectShippingMethod: 'Selectați metoda de transport',
  standardShipping: "Transport standard: ${amount}",
  estimatedDeliverys: "Livrare estimată: {date}",
  taxes: "Taxe",
  breadcrumbs: {
    classification: 'Clasificare',
    subClassification: 'Subclasificare',
    selectedProduct: 'Produs selectat',
  },
  downloadApp: 'Descarcă aplicația',
  register: 'Înregistrare',
  verifiedPurchase: 'Achiziție verificată',
  pleaseInput: 'Vă rugăm să introduceți ',
  code:'Cod',
  effective:'Eficient ',
  pleaseEnterPasswordAgain:'Vă rugăm să introduceți din nou parola',
  passwordInconsistent:'Parolele introduse nu se potrivesc!',
  createdSuccessfully:'Creat cu succes',
  addedSuccessfully:'Adăugat cu succes',
  modifyAddress:'Modifică adresa',
  modifiedSuccessfully:'Modificat cu succes',
  copy:'Textul a fost copiat în clipboard!',
  rechargeSuccessful:'Reîncărcare reușită',
  deleteSuccessfully:'Șters cu succes',
  paymentSuccessful:'Plată reușită',
  payOrder: 'Plătește comanda',
  pay: 'Plătește',
  available: 'Disponibil',
  enterPaymentPassword: 'Introduceți parola de plată de 6 cifre',
  confirmSuccessfulReceiptOfGoods:'Confirmați primirea cu succes a bunurilor',
  correct:' Corect ',
  pleaseUploadInFull:'Vă rugăm să încărcați complet imaginile necesare!',
  submittedSuccessfully:'Trimis cu succes',
  evaluate:'Evaluează',
  PleaseFillInTheFormCompletely:'Vă rugăm să completați complet formularul!',
  qrCodePlaceholder:'Cod QR',
  verificationCodeSentSuccessfully:'Codul de verificare a fost trimis cu succes, vă rugăm să completați și să trimiteți în 60 de secunde!',
  pleaseEnterYourEmailFirst:'Vă rugăm să introduceți mai întâi adresa de e-mail',
  ContactTheMerchant: "Contactați comerciantul",
  inputIssues: 'Introduceți probleme',
}
export default ro

