// 创建文件et/index.js
const et = {
  route: {
    home: 'Avaleht',
    shopCart: 'Ostukorv',
    popularStore: 'Populaarne Pood',
    storeDetails: 'Poedetailid',
    hotSellingProducts: 'Kuumad Müügid',
    productClassification: 'Tooteklassifikatsioon',
    productDetails: 'Toote üksikasjad',
    orderPay: 'Tellimuse Maksmine',
  },
  home: "Avaleht",
  wallet: "Rahakott",
  order: "Tellimiskeskus",
  sell: "Müügikeskus",
  cart: "Ostukorv",
  search: "Otsi",
  deals: "Tänased pakkumised",
  featuredStore: "Esiletõstetud Pood",
  spotlight: "Spotlight",
  hotSale: "Kuum Müük",
  fashion: "Mood",
  beautyPersonalCare: "Ilutooted ja isiklik hügieen",
  homeKitchen: "Kodu ja köök",
  sportsOutdoors: "Sport ja välitegevused",
  electronicsAccessories: "Elektroonika ja tarvikud",
  seeAll: "Vaata Kõiki",
  category: "Kategooria",
  beauty: 'Ilu',
  personalCare: 'Isiklik Hügieen',
  homeFurnishing: 'Kodu',
  kitchen: 'Köök',
  sports: 'Sport',
  outdoors: 'Välitegevused',
  electronics: 'Elektroonika',
  accessories: 'Tarvikud',
  office: 'Kontor',
  products: 'Tooted',
  food: 'Toit',
  drinks: 'Joogid',
  collectibles: 'Kogumisobjektid',
  automotive: 'Autondus',
  storeOnTikTokShop:'Pood TikTok Shopis',
  viewShop:'Vaata Poodi',
  reviews:'Arvustused',
  topProducts:'Populaarsed Tooted',
  newReleases:'Uued Väljalasked',
  balance: 'Saldo',
  recharge: 'Laadimine',
  withdraw: 'Väljamakse',
  fundAccount: 'Konto rahastamine',
  add: 'Lisa',
  addFundAccount: 'Lisa Fondikonto',
  crypto: 'Krüpto',
  mainnet: 'Põhivõrk',
  address: 'Aadress',
  debitCards: 'Deebetkaardid',
  iban: 'IBAN',
  account: 'Konto',
  cardNumber: 'Kaardinumber',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount:'Summa',
  qrCode:'QR-kood',
  rechargeAmountUsdt:'Laadimise summa (USDT)',
  pleaseEnterTheRechargeAmountUsdt:'Palun sisestage laadimise summa (USDT)',
  submitForReview:'Esita ülevaatamiseks',
  purchased: "Ostetud",
  carriage: "Transport",
  completed: "Lõpetatud",
  awaitingShipment:'Ootab Saatmist',
  orderDetails: "Tellimuse Üksikasjad",
  receive: "Võta vastu",
  review:'Arvustus',
  enterprise: 'Ettevõte',
  personal:'Isiklik',
  businessName: 'Ettevõtte Nimi',
  headOfEnterprise: 'Ettevõtte Juht',
  headPhoneNumber: 'Juhi Telefoninumber',
  headWhatsAppNumber: 'Juhi WhatsApp Number',
  submit: 'Esita',
  uploadLogo: 'Lae Logo Üles',
  storeName: 'Poe Nimi',
  storeEmail: 'Poe E-post',
  storeHeadName: 'Poe Juhi Nimi',
  storeHeadIdNumber: 'Poe Juhi ID Number',
  idFrontPhoto: 'ID Esikülje Foto',
  idBackPhoto: 'ID Tagakülje Foto',
  personalHandheldIdPhoto: 'Isiklik käes hoitav ID-foto',
  storeHeadPhoneNumber: 'Poe Juhi Telefoninumber',
  storeSupportNumber: 'Poe Tugitelefon',
  invitationCode: 'Kutse Kood',
  login: 'Logi Sisse',
  createAccount: 'Loo Konto',
  emailOrPhone: 'E-post või telefoninumber',
  password: 'Parool',
  signIn: 'Logi sisse',
  yourName: 'Teie nimi',
  reEnterPassword: 'Sisesta parool uuesti',
  emailOrPhoneCode: 'E-posti või telefoni kood',
  request: 'Päring',
  createAccountButton: 'Loo Konto',
  privacyPolicy: '《TikTok Privaatsuspoliitika》',
  acknowledgmentText: 'Klõpsates Salvesta, kinnitate, et olete lugenud {privacyPolicy}. Teie teavet võidakse kasutada teie kogemuse isikupärastamiseks, sealhulgas reklaamide jaoks, sõltuvalt teie seadistustest.',
  buy: 'Osta',
  exploreBargains: 'Avasta kvaliteetseid sooduspakkumisi',
  aboutSpotlight: 'Spotlighti kohta',
  freeShipping: 'Tasuta saatmine',
  freeShippingDescription: 'Tellimustele üle $25 tasuta saatmine',
  freeReturns: 'Tasuta tagastamine',
  freeReturnsDescription: '30 päeva jooksul',
  shopPerformance: 'Poe jõudlus',
  betterThan: 'Parem kui 80% teistest poodidest',
  starRating: '4+ tärni hinnang',
  shipsWithin: 'Laevad välja 2 päeva jooksul',
  reviewsCount: '({count} arvustust)',
  cartEmpty: 'Teie ostukorv on tühi',
  selectAll: 'Vali kõik',
  totalItems: 'Kokku ({num} eset)',
  checkout: 'Vormista ost',
  shippingReturnsTitle: 'Saatmine ja tagastamine',
  estimatedDelivery: 'Eeldatav tarneaeg 11.-13. juuli, $7.43',
  shippingTo: 'Saatmine kohta: Denver, 17517',
  shipsOut: 'Tavaliselt saadetakse välja 1 tööpäeva jooksul',
  returnPolicyTitle: 'Tagastuspoliitika',
  addToCart: 'Lisa ostukorvi',
  aboutThisProduct: 'Selle toote kohta',
  shippingAddress: 'Saatmisaadress',
  addAddress: 'Lisa aadress',
  paymentMethod: 'Makseviis',
  shippingMethod: 'Saatmisviis',
  orderSummary: 'Tellimuse kokkuvõte',
  subtotal: 'Vahesumma',
  shipping: 'Saatmine',
  shippingToBeCalculated: 'Arvutatakse',
  total: 'Kokku ({num} eset)',
  firstName: 'Eesnimi',
  lastName: 'Perekonnanimi',
  street: 'Tänav',
  apartment: 'Korter, üksus jne (valikuline)',
  city: 'Linn',
  state: 'Maakond',
  zipCode: 'Postiindeks',
  phone: 'Telefon',
  save: 'Salvesta',
  privacyPolicyDescription: 'Klõpsates Salvesta, kinnitate, et olete lugenud {privacyPolicy}. Teie teavet võidakse kasutada teie kogemuse isikupärastamiseks, sealhulgas reklaamide jaoks, sõltuvalt teie seadistustest.',
  usCountryCode: 'US+1',
  logout:'Logi Välja',
  selectShippingMethod: 'Vali saatmisviis',
  standardShipping: "Standardne saatmine: ${amount}",
  estimatedDeliverys: "Eeldatav tarneaeg: {date}",
  taxes: "Maksud",
  breadcrumbs: {
    classification: 'Klassifikatsioon',
    subClassification: 'Alaklassifikatsioon',
    selectedProduct: 'Valitud toode',
  },
  downloadApp: 'Lae alla rakendus',
  register: 'Registreeru',
  verifiedPurchase: 'Kinnitatud ost',
  pleaseInput: 'Palun sisestage ',
  code:'Kood',
  effective:'Efektiivne ',
  pleaseEnterPasswordAgain:'Palun sisestage oma parool uuesti',
  passwordInconsistent:'Kaks korda sisestatud parool ei ühti!',
  createdSuccessfully:'Loodud edukalt',
  addedSuccessfully:'Lisatud edukalt',
  modifyAddress:'Muuda aadressi',
  modifiedSuccessfully:'Muudetud edukalt',
  copy:'Tekst on kopeeritud lõikelauale!',
  rechargeSuccessful:'Laadimine edukas',
  deleteSuccessfully:'Kustutamine edukas',
  paymentSuccessful:'Makse edukas',
  payOrder: 'Makske tellimus',
  pay: 'Maksma',
  available: 'Saadaval',
  enterPaymentPassword: 'Sisestage 6-kohaline makseparool',
  confirmSuccessfulReceiptOfGoods:'Kinnitage kaupade edukas kättesaamine',
  correct:' Õige ',
  pleaseUploadInFull:'Palun laadige kõik nõutavad pildid täielikult üles!',
  submittedSuccessfully:'Esitamine õnnestus',
  evaluate:'Hinda',
  PleaseFillInTheFormCompletely:'Palun täitke vorm täielikult!',
  qrCodePlaceholder:'QR-kood',
  verificationCodeSentSuccessfully:'Verifikatsioonikood on edukalt saadetud, palun täitke ja esitage see 60 sekundi jooksul!',
  pleaseEnterYourEmailFirst:'Palun sisestage esmalt oma e-post',
  ContactTheMerchant: "Võta ühendust kaupmehega",
  inputIssues: 'Sisesta probleemid',
}
export default et

