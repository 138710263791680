// 创建文件nl/index.js
const nl = {
  route: {
    home: 'Home',
    shopCart: 'Winkelwagen',
    popularStore: 'Populaire Winkel',
    storeDetails: 'Winkeldetails',
    hotSellingProducts: 'Bestsellers',
    productClassification: 'Productclassificatie',
    productDetails: 'Productdetails',
    orderPay: 'Bestelling Betalen',
  },
  home: "Home",
  wallet: "Portemonnee",
  order: "Bestelcentrum",
  sell: "Verkoopcentrum",
  cart: "Winkelwagen",
  search: "Zoeken",
  deals: "Aanbiedingen van vandaag",
  featuredStore: "Aanbevolen Winkel",
  spotlight: "In de Schijnwerpers",
  hotSale: "Populaire Verkoop",
  fashion: "Mode",
  beautyPersonalCare: "Schoonheid & Persoonlijke Verzorging",
  homeKitchen: "Huis & Keuken",
  sportsOutdoors: "Sport & Buiten",
  electronicsAccessories: "Elektronica & Accessoires",
  seeAll: "Bekijk Alles",
  category: "Categorie",
  beauty: 'Schoonheid',
  personalCare: 'Persoonlijke Verzorging',
  homeFurnishing: 'Huisinrichting',
  kitchen: 'Keuken',
  sports: 'Sport',
  outdoors: 'Buiten',
  electronics: 'Elektronica',
  accessories: 'Accessoires',
  office: 'Kantoor',
  products: 'Producten',
  food: 'Eten',
  drinks: 'Drinken',
  collectibles: 'Verzamelobjecten',
  automotive: 'Automotive',
  storeOnTikTokShop:'Winkel op TikTok Shop',
  viewShop:'Bekijk Winkel',
  reviews:'Beoordelingen',
  topProducts:'Topproducten',
  newReleases:'Nieuwe Releases',
  balance: 'Balans',
  recharge: 'Opladen',
  withdraw: 'Opnemen',
  fundAccount: 'Rekening Opladen',
  add: 'Toevoegen',
  addFundAccount: 'Rekening Opladen Toevoegen',
  crypto: 'Crypto',
  mainnet: 'Mainnet',
  address: 'Adres',
  debitCards: 'Debetkaarten',
  iban: 'IBAN',
  account: 'Rekening',
  cardNumber: 'Kaartnummer',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount:'Bedrag',
  qrCode:'QR-code',
  rechargeAmountUsdt:'Oplaadbedrag (USDT)',
  pleaseEnterTheRechargeAmountUsdt:'Voer het oplaadbedrag in (USDT)',
  submitForReview:'Verzenden voor beoordeling',
  purchased: "Gekocht",
  carriage: "Vervoer",
  completed: "Voltooid",
  awaitingShipment:'Wachten op Verzending',
  orderDetails: "Bestelgegevens",
  receive: "Ontvangen",
  review:'Beoordelen',
  enterprise: 'Onderneming',
  personal:'Persoonlijk',
  businessName: 'Bedrijfsnaam',
  headOfEnterprise: 'Hoofd van de Onderneming',
  headPhoneNumber: 'Telefoonnummer Hoofd',
  headWhatsAppNumber: 'WhatsApp Nummer Hoofd',
  submit: 'Verzenden',
  uploadLogo: 'Logo Uploaden',
  storeName: 'Winkelnaam',
  storeEmail: 'Winkel E-mail',
  storeHeadName: 'Naam Winkelhoofd',
  storeHeadIdNumber: 'ID-nummer Winkelhoofd',
  idFrontPhoto: 'ID Voorzijde Foto',
  idBackPhoto: 'ID Achterzijde Foto',
  personalHandheldIdPhoto: 'Persoonlijke Hand-gehouden ID-foto',
  storeHeadPhoneNumber: 'Telefoonnummer Winkelhoofd',
  storeSupportNumber: 'Winkel Ondersteuningsnummer',
  invitationCode: 'Uitnodigingscode',
  login: 'Inloggen',
  createAccount: 'Account aanmaken',
  emailOrPhone: 'E-mail of telefoonnummer',
  password: 'Wachtwoord',
  signIn: 'Inloggen',
  yourName: 'Uw naam',
  reEnterPassword: 'Voer wachtwoord opnieuw in',
  emailOrPhoneCode: 'E-mail of telefooncode',
  request: 'Verzoeken',
  createAccountButton: 'Account aanmaken',
  privacyPolicy: '《TikTok Privacybeleid》',
  acknowledgmentText: 'Door op Opslaan te klikken, erkent u dat u het {privacyPolicy} heeft gelezen. Uw informatie kan worden gebruikt om uw ervaring te personaliseren, inclusief advertenties, afhankelijk van uw instellingen.',
  buy: 'Kopen',
  exploreBargains: 'Verken kwaliteitsaanbiedingen',
  aboutSpotlight: 'Over Spotlight',
  freeShipping: 'Gratis Verzending',
  freeShippingDescription: 'Op bestellingen boven de $25',
  freeReturns: 'Gratis Retourneren',
  freeReturnsDescription: 'Binnen 30 dagen',
  shopPerformance: 'Winkelprestatie',
  betterThan: 'Beter dan 80% van de andere winkels',
  starRating: '4+ ster beoordeling',
  shipsWithin: 'Verzending binnen 2 dagen',
  reviewsCount: '({count} beoordelingen)',
  cartEmpty: 'Uw winkelwagen is leeg',
  selectAll: 'Alles selecteren',
  totalItems: 'Totaal ({num} item)',
  checkout: 'Afrekenen',
  shippingReturnsTitle: 'Verzending & retourneren',
  estimatedDelivery: 'Geschatte levering 11-13 juli, $7.43',
  shippingTo: 'Verzending naar Denver, 17517',
  shipsOut: 'Verzendt meestal binnen 1 werkdag',
  returnPolicyTitle: 'Retourbeleid',
  addToCart: 'Toevoegen aan winkelwagen',
  aboutThisProduct: 'Over dit product',
  shippingAddress: 'Verzendadres',
  addAddress: 'Adres toevoegen',
  paymentMethod: 'Betaalmethode',
  shippingMethod: 'Verzendmethode',
  orderSummary: 'Besteloverzicht',
  subtotal: 'Subtotaal',
  shipping: 'Verzending',
  shippingToBeCalculated: 'wordt berekend',
  total: 'Totaal ({num} item)',
  firstName: 'Voornaam',
  lastName: 'Achternaam',
  street: 'Straat',
  apartment: 'Appartement, suite, etc. (optioneel)',
  city: 'Stad',
  state: 'Staat',
  zipCode: 'Postcode',
  phone: 'Telefoon',
  save: 'Opslaan',
  privacyPolicyDescription: 'Door op Opslaan te klikken, erkent u dat u het {privacyPolicy} heeft gelezen. Uw informatie kan worden gebruikt om uw ervaring te personaliseren, inclusief advertenties, afhankelijk van uw instellingen.',
  usCountryCode: 'VS+1',
  logout:'Uitloggen',
  selectShippingMethod: 'Selecteer verzendmethode',
  standardShipping: "Standaard Verzending: ${amount}",
  estimatedDeliverys: "Geschatte Levering: {date}",
  taxes: "Belastingen",
  breadcrumbs: {
    classification: 'Classificatie',
    subClassification: 'Subclassificatie',
    selectedProduct: 'Geselecteerd Product',
  },
  downloadApp: 'App Downloaden',
  register: 'Registreren',
  verifiedPurchase: 'Geverifieerde Aankoop',
  pleaseInput: 'Voer in ',
  code:'Code',
  effective:'Effectief ',
  pleaseEnterPasswordAgain:'Voer uw wachtwoord opnieuw in',
  passwordInconsistent:'Het ingevoerde wachtwoord komt niet overeen!',
  createdSuccessfully:'Succesvol aangemaakt',
  addedSuccessfully:'Succesvol toegevoegd',
  modifyAddress:'Adres Wijzigen',
  modifiedSuccessfully:'Succesvol gewijzigd',
  copy:'De tekst is naar het klembord gekopieerd!',
  rechargeSuccessful:'Opladen Succesvol',
  deleteSuccessfully:'Succesvol verwijderd',
  paymentSuccessful:'Betaling Succesvol',
  payOrder: 'Bestelling Betalen',
  pay: 'Betalen',
  available: 'Beschikbaar',
  enterPaymentPassword: 'Voer 6-cijferig betalingswachtwoord in',
  confirmSuccessfulReceiptOfGoods:'Bevestig succesvolle ontvangst van goederen',
  correct:' Correct ',
  pleaseUploadInFull:'Upload alstublieft de vereiste afbeeldingen volledig!',
  submittedSuccessfully:'Succesvol ingediend',
  evaluate:'Evalueren',
  PleaseFillInTheFormCompletely:'Vul alstublieft het formulier volledig in!',
  qrCodePlaceholder:'QR-code',
  verificationCodeSentSuccessfully:'Verificatiecode succesvol verzonden, vul deze binnen 60 seconden in en dien in!',
  pleaseEnterYourEmailFirst:'Voer alstublieft eerst uw e-mail in',
  ContactTheMerchant: "Neem contact op met de verkoper",
  inputIssues: 'Voer problemen in',
}
export default nl
