// 创建文件fr/index.js
const fr = {
  route: {
    home: 'Accueil',
    shopCart: 'Panier',
    popularStore: 'Magasin Populaire',
    storeDetails: 'Détails du Magasin',
    hotSellingProducts: 'Produits en Vente',
    productClassification: 'Classification des Produits',
    productDetails: 'Détails du Produit',
    orderPay: 'Paiement de la Commande',
  },
  home: "Accueil",
  wallet: "Portefeuille",
  order: "Centre de Commande",
  sell: "Centre de Vente",
  cart: "Panier",
  search: "Recherche",
  deals: "Offres du Jour",
  featuredStore: "Magasin en Vedette",
  spotlight: "Projecteur",
  hotSale: "Vente Chaude",
  fashion: "Mode",
  beautyPersonalCare: "Beauté & Soins Personnels",
  homeKitchen: "Maison & Cuisine",
  sportsOutdoors: "Sports & Plein Air",
  electronicsAccessories: "Électronique & Accessoires",
  seeAll: "Voir Tout",
  category: "Catégorie",
  beauty: 'Beauté',
  personalCare: 'Soins Personnels',
  homeFurnishing: 'Maison',
  kitchen: 'Cuisine',
  sports: 'Sports',
  outdoors: 'Plein Air',
  electronics: 'Électronique',
  accessories: 'Accessoires',
  office: 'Bureau',
  products: 'Produits',
  food: 'Nourriture',
  drinks: 'Boissons',
  collectibles: 'Objets de Collection',
  automotive: 'Automobile',
  storeOnTikTokShop:'Magasin sur TikTok',
  viewShop:'Voir le Magasin',
  reviews:'Avis',
  topProducts:'Produits Phare',
  newReleases:'Nouveautés',
  balance: 'Solde',
  recharge: 'Recharger',
  withdraw: 'Retirer',
  fundAccount: 'Compte de Fonds',
  add: 'Ajouter',
  addFundAccount: 'Ajouter un Compte de Fonds',
  crypto: 'Crypto',
  mainnet: 'Réseau Principal',
  address: 'Adresse',
  debitCards: 'Cartes de Débit',
  iban: 'IBAN',
  account: 'Compte',
  cardNumber: 'Numéro de Carte',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount:'Montant',
  qrCode:'QR Code',
  rechargeAmountUsdt:'Montant de la Recharge (USDT)',
  pleaseEnterTheRechargeAmountUsdt:'Veuillez Entrer le Montant de la Recharge (USDT)',
  submitForReview:'Soumettre pour Révision',
  purchased: "Acheté",
  carriage: "Transport",
  completed: "Terminé",
  awaitingShipment:'En Attente d\'Expédition',
  orderDetails: "Détails de la Commande",
  receive: "Recevoir",
  review:'Évaluation',
  enterprise: 'Entreprise',
  personal:'Personnel',
  businessName: 'Nom de l\'Entreprise',
  headOfEnterprise: 'Responsable de l\'Entreprise',
  headPhoneNumber: 'Numéro de Téléphone du Responsable',
  headWhatsAppNumber: 'Numéro WhatsApp du Responsable',
  submit: 'Soumettre',
  uploadLogo: 'Télécharger le Logo',
  storeName: 'Nom du Magasin',
  storeEmail: 'E-mail du Magasin',
  storeHeadName: 'Nom du Responsable du Magasin',
  storeHeadIdNumber: 'Numéro d\'Identification du Responsable',
  idFrontPhoto: 'Photo de Face de l\'ID',
  idBackPhoto: 'Photo de Dos de l\'ID',
  personalHandheldIdPhoto: 'Photo d\'ID Tenu en Main',
  storeHeadPhoneNumber: 'Numéro de Téléphone du Responsable',
  storeSupportNumber: 'Numéro de Support du Magasin',
  invitationCode: 'Code d\'Invitation',
  login: 'Se Connecter',
  createAccount: 'Créer un Compte',
  emailOrPhone: 'E-mail ou Numéro de Téléphone',
  password: 'Mot de Passe',
  signIn: 'Connexion',
  yourName: 'Votre Nom',
  reEnterPassword: 'Ressaisir le Mot de Passe',
  emailOrPhoneCode: 'Code E-mail ou Téléphone',
  request: 'Demander',
  createAccountButton: 'Créer un Compte',
  privacyPolicy: '《Politique de Confidentialité TikTok》',
  acknowledgmentText: 'En cliquant sur Enregistrer, vous reconnaissez avoir lu la {privacyPolicy}. Vos informations peuvent être utilisées pour personnaliser votre expérience, y compris les publicités, en fonction de vos paramètres.',
  buy: 'Acheter',
  exploreBargains: 'Explorer les Bons Plans',
  aboutSpotlight: 'À Propos de Spotlight',
  freeShipping: 'Livraison Gratuite',
  freeShippingDescription: 'Sur les commandes de plus de 25 $',
  freeReturns: 'Retours Gratuits',
  freeReturnsDescription: 'Sous 30 jours',
  shopPerformance: 'Performance du Magasin',
  betterThan: 'Meilleur que 80% des autres magasins',
  starRating: 'Note 4+ Étoiles',
  shipsWithin: 'Expédié sous 2 jours',
  reviewsCount: '({count} avis)',
  cartEmpty: 'Votre panier est vide',
  selectAll: 'Tout Sélectionner',
  totalItems: 'Total ({num} article)',
  checkout: 'Passer à la Caisse',
  shippingReturnsTitle: 'Expédition & Retours',
  estimatedDelivery: 'Livraison estimée entre le 11 et le 13 juillet, 7,43 $',
  shippingTo: 'Expédition vers Denver, 17517',
  shipsOut: 'Habituellement expédié sous 1 jour ouvré',
  returnPolicyTitle: 'Politique de Retour',
  addToCart: 'Ajouter au Panier',
  aboutThisProduct: 'À Propos de ce Produit',
  shippingAddress: 'Adresse de Livraison',
  addAddress: 'Ajouter une Adresse',
  paymentMethod: 'Méthode de Paiement',
  shippingMethod: 'Méthode d\'Expédition',
  orderSummary: 'Résumé de la Commande',
  subtotal: 'Sous-total',
  shipping: 'Expédition',
  shippingToBeCalculated: 'à calculer',
  total: 'Total ({num} article)',
  firstName: 'Prénom',
  lastName: 'Nom de Famille',
  street: 'Rue',
  apartment: 'Appartement, suite, etc. (facultatif)',
  city: 'Ville',
  state: 'État',
  zipCode: 'Code Postal',
  phone: 'Téléphone',
  save: 'Enregistrer',
  privacyPolicyDescription: 'En cliquant sur Enregistrer, vous reconnaissez avoir lu la {privacyPolicy}. Vos informations peuvent être utilisées pour personnaliser votre expérience, y compris les publicités, en fonction de vos paramètres.',
  usCountryCode: 'US+1',
  logout:'Se Déconnecter',
  selectShippingMethod: 'Sélectionner la Méthode d\'Expédition',
  standardShipping: "Expédition Standard: ${amount}",
  estimatedDeliverys: "Livraison Estimée: {date}",
  taxes: "Taxes",
  breadcrumbs: {
    classification: 'Classification',
    subClassification: 'Sous-classification',
    selectedProduct: 'Produit Sélectionné',
  },
  downloadApp: 'Télécharger l\'Application',
  register: 'S\'inscrire',
  verifiedPurchase: 'Achat Vérifié',
  pleaseInput: 'Veuillez Saisir ',
  code:'Code',
  effective:'Efficace ',
  pleaseEnterPasswordAgain:'Veuillez Saisir à Nouveau Votre Mot de Passe',
  passwordInconsistent:'Les Mots de Passe Saisis ne Correspondent Pas!',
  createdSuccessfully:'Créé avec Succès',
  addedSuccessfully:'Ajouté avec Succès',
  modifyAddress:'Modifier l\'Adresse',
  modifiedSuccessfully:'Modifié avec Succès',
  copy:'Le Texte a été Copié dans le Presse-papiers!',
  rechargeSuccessful:'Recharge Réussie',
  deleteSuccessfully:'Supprimé avec Succès',
  paymentSuccessful:'Paiement Réussi',
  payOrder: 'Payer la Commande',
  pay: 'Payer',
  available: 'Disponible',
  enterPaymentPassword: 'Entrer le Mot de Passe de Paiement à 6 Chiffres',
  confirmSuccessfulReceiptOfGoods:'Confirmer la Réception Réussie des Marchandises',
  correct:' Correct ',
  pleaseUploadInFull:'Veuillez Télécharger toutes les Images Requises!',
  submittedSuccessfully:'Soumis avec Succès',
  evaluate:'Évaluer',
  PleaseFillInTheFormCompletely:'Veuillez Remplir Complètement le Formulaire!',
  qrCodePlaceholder:'QR Code',
  verificationCodeSentSuccessfully:'Code de Vérification Envoyé avec Succès, Veuillez le Saisir et Soumettre dans les 60 Secondes!',
  pleaseEnterYourEmailFirst:'Veuillez d\'Abord Entrer Votre E-mail',
  ContactTheMerchant: "Contacter le marchand",
  inputIssues: 'Saisir les problèmes',
}
export default fr
