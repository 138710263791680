import Vue from 'vue'
import App from './App.vue'
import router from "@/router";
import pinia from "@/stores";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/global.scss'
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import VueI18n from 'vue-i18n'
import i18n from '@/i18n/index'
import websocket from "@/utils/websocket";

let  webClenit = new websocket("wss://tktest.tiktokseller.de/socket.io");
Vue.prototype.$socket = webClenit;

Vue.component(CollapseTransition.name, CollapseTransition)
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(pinia);
// 创建一个全局事件总线
Vue.prototype.$bus = new Vue();

new Vue({
  VueI18n,
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
