/**
 * pinia 数据
 * @returns {{user_info: {}, Stid: string, token: string}}
 */
export default function () {
  return {
    token: '', // token
    uid: '', // uid
    load:false, // 动态路由状态
    user_info: {}, // 登录用户信息
    routingMenu: [], // 权限菜单
    routerPath: '/index', // 默认路由页面
    order_number:'',
  }
}
