// 创建文件zh/index.js
const zh = {
  route: {
    home: '首页',
    shopCart: '购物车',
    popularStore: '推荐店铺',
    storeDetails: '店铺详情',
    hotSellingProducts: '热卖产品',
    productClassification: '分类',
    productDetails: '商品详情',
    orderPay: '订单支付',
  },
  home: "主页",
  wallet: "钱包",
  order: "订单中心",
  sell: "卖家中心",
  cart: "购物车",
  search: "搜索",
  deals: "今日热销",
  featuredStore: "特色商店",
  spotlight: "聚光灯",
  hotSale: "热销",
  fashion: "时尚",
  beautyPersonalCare: "美容及个人护理",
  homeKitchen: "家居与厨房",
  sportsOutdoors: "运动与户外",
  electronicsAccessories: "电子产品和配件",
  seeAll: "查看全部",
  category: "类别",
  beauty: '美妆',
  personalCare: '个人护理',
  homeFurnishing: '家居',
  kitchen: '厨房',
  sports: '运动',
  outdoors: '户外',
  electronics: '电子',
  accessories: '配件',
  office: '办公',
  products: '产品',
  food: '食品',
  drinks: '饮品',
  collectibles: '收藏品',
  automotive: '汽车',
  storeOnTikTokShop:'在TikTok商店购物',
  viewShop:'查看店铺',
  reviews:'评审',
  topProducts:'热门产品',
  newReleases:'新发行',
  balance: '余额',
  recharge: '充值',
  withdraw: '提现',
  fundAccount: '资金账户',
  add: '添加',
  addFundAccount: '添加资金账户',
  crypto: '加密货币',
  mainnet: '主网',
  address: '地址',
  debitCards: '借记卡',
  iban: 'IBAN',
  account: '账户',
  cardNumber: '卡号',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: '比特币',
  eth: '以太坊',
  hsbc: '汇丰银行',
  ubs: '瑞银集团',
  bnp: '法国巴黎银行',
  amount:'数量',
  qrCode:'二维码',
  rechargeAmountUsdt:'充值金额(USDT)',
  pleaseEnterTheRechargeAmountUsdt:'请输入充值金额(USDT)',
  submitForReview:'提交审核',
  purchased: "已购买",
  carriage: "运输中",
  completed: "已完成",
  awaitingShipment:'等待发货',
  orderDetails: "订单详情",
  receive: "接收",
  review:'回顾',
  enterprise: '企业',
  personal:'个人',
  businessName: '企业名称',
  headOfEnterprise: '企业负责人',
  headPhoneNumber: '负责人电话号码',
  headWhatsAppNumber: '负责人 WhatsApp 号码',
  submit: '提交',
  uploadLogo: '上传 Logo',
  storeName: '店铺名称',
  storeEmail: '店铺邮箱',
  storeHeadName: '店主姓名',
  storeHeadIdNumber: '店主身份证号',
  idFrontPhoto: '身份证正面照',
  idBackPhoto: '身份证背面照',
  personalHandheldIdPhoto: '个人手持身份证照片',
  storeHeadPhoneNumber: '店主电话',
  storeSupportNumber: '店铺客服电话',
  invitationCode: '邀请码',
  login: '登录',
  createAccount: '创建账户',
  emailOrPhone: '电子邮箱或手机号码',
  password: '密码',
  signIn: '登录',
  yourName: '您的名字',
  reEnterPassword: '重新输入密码',
  emailOrPhoneCode: '电子邮箱或手机验证码',
  request: '请求',
  createAccountButton: '创建账户',
  privacyPolicy: '《TikTok隐私政策》',
  acknowledgmentText: '点击保存即表示您已阅读{privacyPolicy}。您的信息可能会用于个性化您的体验，包括广告，具体取决于您的设置。',
  buy: '购买',
  exploreBargains: '探索优质优惠',
  aboutSpotlight: '关于聚光灯',
  freeShipping: '免运费',
  freeShippingDescription: '订单满 $25 免运费',
  freeReturns: '免费退货',
  freeReturnsDescription: '30天内退货',
  shopPerformance: '店铺表现',
  betterThan: '优于80%的其他商店',
  starRating: '4星以上评级',
  shipsWithin: '2天内发货',
  reviewsCount: '({count} 条评论)',
  cartEmpty: '购物车为空',
  selectAll: '全选',
  totalItems: '总计（{num} 件）',
  checkout: '结账',
  shippingReturnsTitle: '运输与退货',
  estimatedDelivery: '预计交货时间：7月11日至13日，$7.43',
  shippingTo: '发货到：丹佛，17517',
  shipsOut: '通常在1个工作日内发货',
  returnPolicyTitle: '退货政策',
  addToCart: '添加到购物车',
  aboutThisProduct: '关于此产品',
  shippingAddress: '配送地址',
  addAddress: '添加地址',
  paymentMethod: '支付方式',
  shippingMethod: '配送方式',
  orderSummary: '订单总结',
  subtotal: '小计',
  shipping: '运费',
  shippingToBeCalculated: '待计算',
  total: '总计（{num} 项）',
  firstName: '名字',
  lastName: '姓氏',
  street: '街道',
  apartment: '公寓，套房等（可选）',
  city: '城市',
  state: '州',
  zipCode: '邮政编码',
  phone: '电话',
  save: '保存',
  privacyPolicyDescription: '点击“保存”即表示您已阅读 {privacyPolicy}。您的信息可能会用于根据您的设置个性化您的体验，包括广告。',
  usCountryCode: 'US+1',
  logout:'登出',
  selectShippingMethod: '选择配送方式',
  standardShipping: "标准运费:${amount}",
  estimatedDeliverys: "预计送达: {date}",
  taxes: "税款",
  breadcrumbs: {
    classification: '分类',
    subClassification: '小分类',
    selectedProduct: '选定产品',
  },
  downloadApp: '下载APP',
  register: '注册',
  verifiedPurchase: '已验证的购买',
  pleaseInput:'请输入',
  code:'验证码',
  effective:'有效的',
  pleaseEnterPasswordAgain:'请再次输入密码',
  passwordInconsistent:'两次输入密码不一致!',
  createdSuccessfully:'创建成功',
  addedSuccessfully:'添加成功',
  modifyAddress:'修改地址',
  modifiedSuccessfully:'修改成功',
  copy:'文本已复制到剪贴板!',
  rechargeSuccessful:'充值成功',
  deleteSuccessfully:'删除成功',
  paymentSuccessful:'支付成功',
  payOrder: '支付订单',
  pay: '支付',
  available: '可用',
  enterPaymentPassword: '输入6位数支付密码',
  confirmSuccessfulReceiptOfGoods:'确认收货成功',
  correct:'正确的',
  pleaseUploadInFull:'请完整上传所需的图片!',
  submittedSuccessfully:'提交成功',
  evaluate:'评价',
  PleaseFillInTheFormCompletely:'请完整填写!',
  qrCodePlaceholder:'二维码',
  verificationCodeSentSuccessfully:'验证码发送成功，请在60秒内进行填写提交！',
  pleaseEnterYourEmailFirst:'请先输入邮箱',
  ContactTheMerchant:'联系商家',
  inputIssues:'输入问题',
}
export default zh
