const pl = {
  route: {
    home: 'Strona główna',
    shopCart: 'Koszyk',
    popularStore: 'Popularny Sklep',
    storeDetails: 'Szczegóły Sklepu',
    hotSellingProducts: 'Najgorętsze Produkty',
    productClassification: 'Klasyfikacja Produktów',
    productDetails: 'Szczegóły Produktu',
    orderPay: 'Płatność za Zamówienie',
  },
  home: "Strona główna",
  wallet: "Portfel",
  order: "Centrum Zamówień",
  sell: "Centrum Sprzedaży",
  cart: "Koszyk",
  search: "Szukaj",
  deals: "Oferty dnia",
  featuredStore: "Wyróżniony Sklep",
  spotlight: "Wyróżnienie",
  hotSale: "Gorąca Wyprzedaż",
  fashion: "Moda",
  beautyPersonalCare: "Uroda i Pielęgnacja",
  homeKitchen: "Dom i Kuchnia",
  sportsOutdoors: "Sport i Aktywność na Świeżym Powietrzu",
  electronicsAccessories: "Elektronika i Akcesoria",
  seeAll: "Zobacz Wszystko",
  category: "Kategoria",
  beauty: 'Uroda',
  personalCare: 'Pielęgnacja',
  homeFurnishing: 'Dom',
  kitchen: 'Kuchnia',
  sports: 'Sport',
  outdoors: 'Na Świeżym Powietrzu',
  electronics: 'Elektronika',
  accessories: 'Akcesoria',
  office: 'Biuro',
  products: 'Produkty',
  food: 'Jedzenie',
  drinks: 'Napoje',
  collectibles: 'Kolekcjonerskie',
  automotive: 'Motoryzacja',
  storeOnTikTokShop: 'Sklep na TikTok Shop',
  viewShop: 'Zobacz Sklep',
  reviews: 'Opinie',
  topProducts: 'Najlepsze Produkty',
  newReleases: 'Nowości',
  balance: 'Saldo',
  recharge: 'Doładuj',
  withdraw: 'Wypłać',
  fundAccount: 'Zasil Konto',
  add: 'Dodaj',
  addFundAccount: 'Dodaj Konto Funduszy',
  crypto: 'Kryptowaluty',
  mainnet: 'Mainnet',
  address: 'Adres',
  debitCards: 'Karty Debetowe',
  iban: 'IBAN',
  account: 'Konto',
  cardNumber: 'Numer Karty',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount: 'Kwota',
  qrCode: 'Kod QR',
  rechargeAmountUsdt: 'Kwota Doładowania (USDT)',
  pleaseEnterTheRechargeAmountUsdt: 'Proszę wprowadzić kwotę doładowania (USDT)',
  submitForReview: 'Prześlij do Recenzji',
  purchased: "Kupione",
  carriage: "Transport",
  completed: "Zakończone",
  awaitingShipment: 'Oczekiwanie na Wysyłkę',
  orderDetails: "Szczegóły Zamówienia",
  receive: "Odbierz",
  review: 'Recenzja',
  enterprise: 'Firma',
  personal: 'Osobisty',
  businessName: 'Nazwa Firmy',
  headOfEnterprise: 'Kierownik Firmy',
  headPhoneNumber: 'Numer Telefonu Kierownika',
  headWhatsAppNumber: 'Numer WhatsApp Kierownika',
  submit: 'Prześlij',
  uploadLogo: 'Prześlij Logo',
  storeName: 'Nazwa Sklepu',
  storeEmail: 'Email Sklepu',
  storeHeadName: 'Imię Kierownika Sklepu',
  storeHeadIdNumber: 'Numer ID Kierownika Sklepu',
  idFrontPhoto: 'Zdjęcie ID Z Przodu',
  idBackPhoto: 'Zdjęcie ID Z Tyłu',
  personalHandheldIdPhoto: 'Zdjęcie ID Z Ręki',
  storeHeadPhoneNumber: 'Numer Telefonu Kierownika Sklepu',
  storeSupportNumber: 'Numer Wsparcia Sklepu',
  invitationCode: 'Kod Zaproszenia',
  login: 'Zaloguj się',
  createAccount: 'Utwórz Konto',
  emailOrPhone: 'Email lub Numer Telefonu',
  password: 'Hasło',
  signIn: 'Zaloguj się',
  yourName: 'Twoje Imię',
  reEnterPassword: 'Wprowadź Hasło Ponownie',
  emailOrPhoneCode: 'Kod Email lub Telefoniczny',
  request: 'Wniosek',
  createAccountButton: 'Utwórz Konto',
  privacyPolicy: '《Polityka Prywatności TikTok》',
  acknowledgmentText: 'Klikając Zapisz, akceptujesz, że przeczytałeś {privacyPolicy}. Twoje dane mogą być używane do personalizacji Twojego doświadczenia, w tym reklam, w zależności od Twoich ustawień.',
  buy: 'Kup',
  exploreBargains: 'Odkryj Okazje',
  aboutSpotlight: 'O Wyróżnieniu',
  freeShipping: 'Darmowa Wysyłka',
  freeShippingDescription: 'Na zamówienia powyżej 25 $',
  freeReturns: 'Darmowe Zwroty',
  freeReturnsDescription: 'W ciągu 30 dni',
  shopPerformance: 'Wydajność Sklepu',
  betterThan: 'Lepszy niż 80% innych sklepów',
  starRating: 'Ocena 4+ gwiazdki',
  shipsWithin: 'Wysyłka w ciągu 2 dni',
  reviewsCount: '({count} recenzji)',
  cartEmpty: 'Twój koszyk jest pusty',
  selectAll: 'Wybierz wszystko',
  totalItems: 'Łącznie ({num} przedmiot)',
  checkout: 'Przejdź do kasy',
  shippingReturnsTitle: 'Wysyłka i Zwroty',
  estimatedDelivery: 'Szacowany czas dostawy: 11-13 lipca, 7,43 $',
  shippingTo: 'Wysyłka do Denver, 17517',
  shipsOut: 'Zazwyczaj wysyłane w ciągu 1 dnia roboczego',
  returnPolicyTitle: 'Polityka Zwrotów',
  addToCart: 'Dodaj do koszyka',
  aboutThisProduct: 'O tym produkcie',
  shippingAddress: 'Adres dostawy',
  addAddress: 'Dodaj adres',
  paymentMethod: 'Metoda płatności',
  shippingMethod: 'Metoda wysyłki',
  orderSummary: 'Podsumowanie zamówienia',
  subtotal: 'Suma cząstkowa',
  shipping: 'Wysyłka',
  shippingToBeCalculated: 'do obliczenia',
  total: 'Łącznie ({num} przedmiot)',
  firstName: 'Imię',
  lastName: 'Nazwisko',
  street: 'Ulica',
  apartment: 'Mieszkanie, suite itp. (opcjonalnie)',
  city: 'Miasto',
  state: 'Województwo',
  zipCode: 'Kod pocztowy',
  phone: 'Telefon',
  save: 'Zapisz',
  privacyPolicyDescription: 'Klikając Zapisz, akceptujesz, że przeczytałeś {privacyPolicy}. Twoje dane mogą być używane do personalizacji Twojego doświadczenia, w tym reklam, w zależności od Twoich ustawień.',
  usCountryCode: 'US+1',
  logout: 'Wyloguj się',
  selectShippingMethod: 'Wybierz metodę wysyłki',
  standardShipping: "Standardowa wysyłka: ${amount}",
  estimatedDeliverys: "Szacowany czas dostawy: {date}",
  taxes: "Podatki",
  breadcrumbs: {
    classification: 'Klasyfikacja',
    subClassification: 'Podklasyfikacja',
    selectedProduct: 'Wybrany produkt',
  },
  downloadApp: 'Pobierz aplikację',
  register: 'Zarejestruj się',
  verifiedPurchase: 'Zweryfikowany zakup',
  pleaseInput: 'Proszę wprowadzić',
  code: 'Kod',
  effective: 'Skuteczny',
  pleaseEnterPasswordAgain: 'Proszę wprowadzić hasło ponownie',
  passwordInconsistent: 'Wprowadzone hasło różni się!',
  createdSuccessfully: 'Utworzono pomyślnie',
  addedSuccessfully: 'Dodano pomyślnie',
  modifyAddress: 'Zmień adres',
  modifiedSuccessfully: 'Pomyślnie zmieniono',
  copy: 'Tekst skopiowany do schowka!',
  rechargeSuccessful: 'Doładowanie pomyślne',
  deleteSuccessfully: 'Pomyślnie usunięto',
  paymentSuccessful: 'Płatność pomyślna',
  payOrder: 'Opłać zamówienie',
  pay: 'Opłać',
  available: 'Dostępne',
  enterPaymentPassword: 'Wprowadź 6-cyfrowe hasło płatności',
  confirmSuccessfulReceiptOfGoods: 'Potwierdź pomyślne odebranie towaru',
  correct: 'Poprawny',
  pleaseUploadInFull: 'Proszę przesłać wszystkie wymagane zdjęcia!',
  submittedSuccessfully: 'Pomyślnie przesłano',
  evaluate: 'Oceń',
  PleaseFillInTheFormCompletely: 'Proszę wypełnić formularz całkowicie!',
  qrCodePlaceholder: 'Kod QR',
  verificationCodeSentSuccessfully: 'Kod weryfikacyjny został wysłany pomyślnie, proszę wprowadzić i przesłać w ciągu 60 sekund!',
  pleaseEnterYourEmailFirst: 'Proszę najpierw wprowadzić swój adres e-mail',
  ContactTheMerchant: "Skontaktuj się z sprzedawcą",
  inputIssues: 'Wprowadź problemy',
}
export default pl
