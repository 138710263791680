const sk = {
  route: {
    home: 'Domov',
    shopCart: 'Nákupný košík',
    popularStore: 'Obľúbený obchod',
    storeDetails: 'Podrobnosti o obchode',
    hotSellingProducts: 'Najpredávanejšie produkty',
    productClassification: 'Klasifikácia produktov',
    productDetails: 'Podrobnosti o produkte',
    orderPay: 'Platba za objednávku',
  },
  home: "Domov",
  wallet: "Peňaženka",
  order: "Centrum objednávok",
  sell: "Centrum predaja",
  cart: "Košík",
  search: "Hľadať",
  deals: "Ponuky dňa",
  featuredStore: "Odporúčaný obchod",
  spotlight: "Výber",
  hotSale: "Horúce výpredaje",
  fashion: "Móda",
  beautyPersonalCare: "Krása a osobná starostlivosť",
  homeKitchen: "Dom a kuchyňa",
  sportsOutdoors: "Šport a outdoor",
  electronicsAccessories: "Elektronika a príslušenstvo",
  seeAll: "Zobraziť všetko",
  category: "Kategória",
  beauty: 'Krása',
  personalCare: 'Osobná starostlivosť',
  homeFurnishing: 'Dom',
  kitchen: 'Kuchyňa',
  sports: 'Šport',
  outdoors: 'Outdoor',
  electronics: 'Elektronika',
  accessories: 'Príslušenstvo',
  office: 'Kancelária',
  products: 'Produkty',
  food: 'Jedlo',
  drinks: 'Nápoje',
  collectibles: 'Zberateľské predmety',
  automotive: 'Automobilový priemysel',
  storeOnTikTokShop: 'Obchod na TikTok Shop',
  viewShop: 'Zobraziť obchod',
  reviews: 'Recenzie',
  topProducts: 'Top produkty',
  newReleases: 'Nové vydania',
  balance: 'Zostatok',
  recharge: 'Dobitie',
  withdraw: 'Výber',
  fundAccount: 'Financovanie účtu',
  add: 'Pridať',
  addFundAccount: 'Pridať účet na financovanie',
  crypto: 'Kryptomena',
  mainnet: 'Mainnet',
  address: 'Adresa',
  debitCards: 'Debetné karty',
  iban: 'IBAN',
  account: 'Účet',
  cardNumber: 'Číslo karty',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount: 'Čiastka',
  qrCode: 'QR kód',
  rechargeAmountUsdt: 'Čiastka dobíjania (USDT)',
  pleaseEnterTheRechargeAmountUsdt: 'Zadajte prosím čiastku dobíjania (USDT)',
  submitForReview: 'Odoslať na recenziu',
  purchased: "Zakúpené",
  carriage: "Doprava",
  completed: "Dokončené",
  awaitingShipment: 'Čaká sa na odoslanie',
  orderDetails: "Podrobnosti objednávky",
  receive: "Prijať",
  review: 'Recenzia',
  enterprise: 'Podnik',
  personal: 'Osobné',
  businessName: 'Názov firmy',
  headOfEnterprise: 'Vedúci podniku',
  headPhoneNumber: 'Telefónne číslo vedúceho',
  headWhatsAppNumber: 'Číslo WhatsApp vedúceho',
  submit: 'Odoslať',
  uploadLogo: 'Nahrať logo',
  storeName: 'Názov obchodu',
  storeEmail: 'E-mail obchodu',
  storeHeadName: 'Meno vedúceho obchodu',
  storeHeadIdNumber: 'Číslo ID vedúceho obchodu',
  idFrontPhoto: 'Fotografia ID z prednej strany',
  idBackPhoto: 'Fotografia ID zo zadnej strany',
  personalHandheldIdPhoto: 'Fotografia ID v ruke',
  storeHeadPhoneNumber: 'Telefónne číslo vedúceho obchodu',
  storeSupportNumber: 'Podporné číslo obchodu',
  invitationCode: 'Pozývací kód',
  login: 'Prihlásiť sa',
  createAccount: 'Vytvoriť účet',
  emailOrPhone: 'E-mail alebo telefónne číslo',
  password: 'Heslo',
  signIn: 'Prihlásiť sa',
  yourName: 'Tvoje meno',
  reEnterPassword: 'Zadajte heslo znova',
  emailOrPhoneCode: 'Kód e-mailu alebo telefónu',
  request: 'Požiadavka',
  createAccountButton: 'Vytvoriť účet',
  privacyPolicy: '《Zásady ochrany osobných údajov TikTok》',
  acknowledgmentText: 'Kliknutím na Uložiť potvrdzujete, že ste si prečítali {privacyPolicy}. Vaše údaje môžu byť použité na personalizáciu vášho zážitku, vrátane reklám, v závislosti od vašich nastavení.',
  buy: 'Kúpiť',
  exploreBargains: 'Objavte kvalitné ponuky',
  aboutSpotlight: 'O výbere',
  freeShipping: 'Doprava zdarma',
  freeShippingDescription: 'Pri objednávkach nad 25 $',
  freeReturns: 'Bezplatné vrátenie',
  freeReturnsDescription: 'Do 30 dní',
  shopPerformance: 'Výkon obchodu',
  betterThan: 'Lepší ako 80% ostatných obchodov',
  starRating: 'Hodnotenie 4+ hviezdičkami',
  shipsWithin: 'Odosiela sa do 2 dní',
  reviewsCount: '({count} recenzií)',
  cartEmpty: 'Váš košík je prázdny',
  selectAll: 'Vybrať všetko',
  totalItems: 'Spolu ({num} položka)',
  checkout: 'Prejsť k pokladni',
  shippingReturnsTitle: 'Doprava a vrátenie',
  estimatedDelivery: 'Odhadovaný čas dodania: 11-13 júla, 7,43 $',
  shippingTo: 'Doprava do Denveru, 17517',
  shipsOut: 'Zvyčajne odosiela do 1 pracovného dňa',
  returnPolicyTitle: 'Politika vrátenia',
  addToCart: 'Pridať do košíka',
  aboutThisProduct: 'O tomto produkte',
  shippingAddress: 'Adresa doručenia',
  addAddress: 'Pridať adresu',
  paymentMethod: 'Spôsob platby',
  shippingMethod: 'Spôsob dopravy',
  orderSummary: 'Prehľad objednávky',
  subtotal: 'Medzisúčet',
  shipping: 'Doprava',
  shippingToBeCalculated: 'bude vypočítané',
  total: 'Spolu ({num} položka)',
  firstName: 'Meno',
  lastName: 'Priezvisko',
  street: 'Ulica',
  apartment: 'Byt, suite atď. (voliteľné)',
  city: 'Mesto',
  state: 'Štát',
  zipCode: 'PSČ',
  phone: 'Telefón',
  save: 'Uložiť',
  privacyPolicyDescription: 'Kliknutím na Uložiť potvrdzujete, že ste si prečítali {privacyPolicy}. Vaše údaje môžu byť použité na personalizáciu vášho zážitku, vrátane reklám, v závislosti od vašich nastavení.',
  usCountryCode: 'US+1',
  logout: 'Odhlásiť sa',
  selectShippingMethod: 'Vyberte spôsob dopravy',
  standardShipping: "Štandardná doprava: ${amount}",
  estimatedDeliverys: "Odhadovaný čas dodania: {date}",
  taxes: "Daň",
  breadcrumbs: {
    classification: 'Klasifikácia',
    subClassification: 'Podklasifikácia',
    selectedProduct: 'Vybraný produkt',
  },
  downloadApp: 'Stiahnuť aplikáciu',
  register: 'Registrovať',
  verifiedPurchase: 'Overený nákup',
  pleaseInput: 'Prosím zadajte',
  code: 'Kód',
  effective: 'Účinný',
  pleaseEnterPasswordAgain: 'Prosím zadajte heslo znova',
  passwordInconsistent: 'Heslo, ktoré ste zadali, sa nezhoduje!',
  createdSuccessfully: 'Úspešne vytvorené',
  addedSuccessfully: 'Úspešne pridané',
  modifyAddress: 'Upraviť adresu',
  modifiedSuccessfully: 'Úspešne upravené',
  copy: 'Text bol skopírovaný do schránky!',
  rechargeSuccessful: 'Dobitie bolo úspešné',
  deleteSuccessfully: 'Úspešne odstránené',
  paymentSuccessful: 'Platba bola úspešná',
  payOrder: 'Oplaťte objednávku',
  pay: 'Oplaťte',
  available: 'Dostupné',
  enterPaymentPassword: 'Zadajte 6-ciferné heslo na platbu',
  confirmSuccessfulReceiptOfGoods: 'Potvrďte úspešné prevzatie tovaru',
  correct: 'Správne',
  pleaseUploadInFull: 'Prosím nahrajte všetky požadované obrázky!',
  submittedSuccessfully: 'Úspešne odoslané',
  evaluate: 'Ohodnoťte',
  PleaseFillInTheFormCompletely: 'Prosím vyplňte formulár úplne!',
  qrCodePlaceholder: 'QR kód',
  verificationCodeSentSuccessfully: 'Overovací kód bol úspešne odoslaný, prosím zadajte a odošlite do 60 sekúnd!',
  pleaseEnterYourEmailFirst: 'Prosím najskôr zadajte svoju e-mailovú adresu',
  ContactTheMerchant: "Kontaktujte predajcu",
  inputIssues: 'Zadajte problémy',
}
export default sk
