// Δημιουργήστε αρχείο el/index.js
const el = {
  route: {
    home: 'Αρχική',
    shopCart: 'Καλάθι Αγορών',
    popularStore: 'Δημοφιλές Κατάστημα',
    storeDetails: 'Λεπτομέρειες Καταστήματος',
    hotSellingProducts: 'Δημοφιλή Προϊόντα',
    productClassification: 'Κατηγοριοποίηση Προϊόντων',
    productDetails: 'Λεπτομέρειες Προϊόντος',
    orderPay: 'Πληρωμή Παραγγελίας',
  },
  home: "Αρχική",
  wallet: "Πορτοφόλι",
  order: "Κέντρο Παραγγελιών",
  sell: "Κέντρο Πωλήσεων",
  cart: "Καλάθι",
  search: "Αναζήτηση",
  deals: "Προσφορές της Ημέρας",
  featuredStore: "Προτεινόμενο Κατάστημα",
  spotlight: "Προβολή",
  hotSale: "Δημοφιλείς Πωλήσεις",
  fashion: "Μόδα",
  beautyPersonalCare: "Ομορφιά & Προσωπική Φροντίδα",
  homeKitchen: "Σπίτι & Κουζίνα",
  sportsOutdoors: "Αθλητισμός & Υπαίθριες Δραστηριότητες",
  electronicsAccessories: "Ηλεκτρονικά & Αξεσουάρ",
  seeAll: "Δείτε Όλα",
  category: "Κατηγορία",
  beauty: 'Ομορφιά',
  personalCare: 'Προσωπική Φροντίδα',
  homeFurnishing: 'Επίπλωση Σπιτιού',
  kitchen: 'Κουζίνα',
  sports: 'Αθλητισμός',
  outdoors: 'Υπαίθριες Δραστηριότητες',
  electronics: 'Ηλεκτρονικά',
  accessories: 'Αξεσουάρ',
  office: 'Γραφείο',
  products: 'Προϊόντα',
  food: 'Τρόφιμα',
  drinks: 'Ποτά',
  collectibles: 'Συλλεκτικά',
  automotive: 'Αυτοκίνητα',
  storeOnTikTokShop:'Κατάστημα στο TikTok',
  viewShop:'Δείτε Κατάστημα',
  reviews:'Κριτικές',
  topProducts:'Κορυφαία Προϊόντα',
  newReleases:'Νέες Κυκλοφορίες',
  balance: 'Υπόλοιπο',
  recharge: 'Φόρτιση',
  withdraw: 'Ανάληψη',
  fundAccount: 'Λογαριασμός Κεφαλαίων',
  add: 'Προσθήκη',
  addFundAccount: 'Προσθήκη Λογαριασμού Κεφαλαίων',
  crypto: 'Κρυπτονόμισμα',
  mainnet: 'Κυρίως Δίκτυο',
  address: 'Διεύθυνση',
  debitCards: 'Χρεωστικές Κάρτες',
  iban: 'IBAN',
  account: 'Λογαριασμός',
  cardNumber: 'Αριθμός Κάρτας',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount:'Ποσό',
  qrCode:'QR Κωδικός',
  rechargeAmountUsdt:'Ποσό Φόρτισης (USDT)',
  pleaseEnterTheRechargeAmountUsdt:'Εισάγετε το ποσό φόρτισης (USDT)',
  submitForReview:'Υποβολή για έλεγχο',
  purchased: "Αγοράστηκε",
  carriage: "Μεταφορά",
  completed: "Ολοκληρώθηκε",
  awaitingShipment:'Αναμονή Αποστολής',
  orderDetails: "Λεπτομέρειες Παραγγελίας",
  receive: "Παραλαβή",
  review:'Ανασκόπηση',
  enterprise: 'Επιχείρηση',
  personal:'Προσωπικό',
  businessName: 'Όνομα Επιχείρησης',
  headOfEnterprise: 'Επικεφαλής Επιχείρησης',
  headPhoneNumber: 'Τηλέφωνο Επικεφαλής',
  headWhatsAppNumber: 'WhatsApp Επικεφαλής',
  submit: 'Υποβολή',
  uploadLogo: 'Ανεβάστε Λογότυπο',
  storeName: 'Όνομα Καταστήματος',
  storeEmail: 'Email Καταστήματος',
  storeHeadName: 'Όνομα Υπεύθυνου Καταστήματος',
  storeHeadIdNumber: 'Αριθμός Ταυτότητας Υπεύθυνου',
  idFrontPhoto: 'Φωτογραφία Μπροστινής Όψης Ταυτότητας',
  idBackPhoto: 'Φωτογραφία Πίσω Όψης Ταυτότητας',
  personalHandheldIdPhoto: 'Φωτογραφία Προσωπικής Ταυτότητας στο Χέρι',
  storeHeadPhoneNumber: 'Τηλέφωνο Υπεύθυνου Καταστήματος',
  storeSupportNumber: 'Αριθμός Υποστήριξης Καταστήματος',
  invitationCode: 'Κωδικός Πρόσκλησης',
  login: 'Σύνδεση',
  createAccount: 'Δημιουργία Λογαριασμού',
  emailOrPhone: 'Email ή Αριθμός Τηλεφώνου',
  password: 'Κωδικός',
  signIn: 'Είσοδος',
  yourName: 'Το όνομά σας',
  reEnterPassword: 'Εισάγετε ξανά τον Κωδικό',
  emailOrPhoneCode: 'Κωδικός Email ή Τηλεφώνου',
  request: 'Αίτημα',
  createAccountButton: 'Δημιουργία Λογαριασμού',
  privacyPolicy: '《Πολιτική Απορρήτου TikTok》',
  acknowledgmentText: 'Κάνοντας κλικ στην Αποθήκευση, αναγνωρίζετε ότι έχετε διαβάσει την {privacyPolicy}. Οι πληροφορίες σας μπορεί να χρησιμοποιηθούν για να εξατομικευτεί η εμπειρία σας, συμπεριλαμβανομένων των διαφημίσεων, ανάλογα με τις ρυθμίσεις σας.',
  buy: 'Αγορά',
  exploreBargains: 'Εξερευνήστε τις Ποιοτικές Προσφορές',
  aboutSpotlight: 'Σχετικά με το Spotlight',
  freeShipping: 'Δωρεάν Αποστολή',
  freeShippingDescription: 'Για παραγγελίες άνω των $25',
  freeReturns: 'Δωρεάν Επιστροφές',
  freeReturnsDescription: 'Εντός 30 ημερών',
  shopPerformance: 'Απόδοση Καταστήματος',
  betterThan: 'Καλύτερο από το 80% των άλλων καταστημάτων',
  starRating: 'Αξιολόγηση άνω των 4 αστεριών',
  shipsWithin: 'Αποστέλλεται εντός 2 ημερών',
  reviewsCount: '({count} κριτικές)',
  cartEmpty: 'Το καλάθι σας είναι άδειο',
  selectAll: 'Επιλογή όλων',
  totalItems: 'Σύνολο ({num} προϊόντα)',
  checkout: 'Ολοκλήρωση Παραγγελίας',
  shippingReturnsTitle: 'Αποστολή & Επιστροφές',
  estimatedDelivery: 'Εκτιμώμενη παράδοση 11-13 Ιουλίου, $7.43',
  shippingTo: 'Αποστολή στο Ντένβερ, 17517',
  shipsOut: 'Συνήθως αποστέλλεται εντός 1 εργάσιμης ημέρας',
  returnPolicyTitle: 'Πολιτική Επιστροφών',
  addToCart: 'Προσθήκη στο Καλάθι',
  aboutThisProduct: 'Σχετικά με αυτό το Προϊόν',
  shippingAddress: 'Διεύθυνση Αποστολής',
  addAddress: 'Προσθήκη Διεύθυνσης',
  paymentMethod: 'Μέθοδος Πληρωμής',
  shippingMethod: 'Μέθοδος Αποστολής',
  orderSummary: 'Περίληψη Παραγγελίας',
  subtotal: 'Υποσύνολο',
  shipping: 'Αποστολή',
  shippingToBeCalculated: 'να υπολογιστεί',
  total: 'Σύνολο ({num} προϊόντα)',
  firstName: 'Όνομα',
  lastName: 'Επώνυμο',
  street: 'Οδός',
  apartment: 'Διαμέρισμα, Σουίτα, κτλ. (προαιρετικό)',
  city: 'Πόλη',
  state: 'Πολιτεία',
  zipCode: 'ΤΚ',
  phone: 'Τηλέφωνο',
  save: 'Αποθήκευση',
  privacyPolicyDescription: 'Κάνοντας κλικ στην Αποθήκευση, αναγνωρίζετε ότι έχετε διαβάσει την {privacyPolicy}. Οι πληροφορίες σας μπορεί να χρησιμοποιηθούν για να εξατομικευτεί η εμπειρία σας, συμπεριλαμβανομένων των διαφημίσεων, ανάλογα με τις ρυθμίσεις σας.',
  usCountryCode: 'ΗΠΑ+1',
  logout: 'Αποσύνδεση',
  selectShippingMethod: 'Επιλογή Μεθόδου Αποστολής',
  standardShipping: "Κανονική Αποστολή: ${amount}",
  estimatedDeliverys: "Εκτιμώμενη Παράδοση: {date}",
  taxes: "Φόροι",
  breadcrumbs: {
    classification: 'Κατηγοριοποίηση',
    subClassification: 'Υποκατηγοριοποίηση',
    selectedProduct: 'Επιλεγμένο Προϊόν',
  },
  downloadApp: 'Κατεβάστε την Εφαρμογή',
  register: 'Εγγραφή',
  verifiedPurchase: 'Επαληθευμένη Αγορά',
  pleaseInput: 'Παρακαλώ Εισάγετε ',
  code: 'Κωδικός',
  effective: 'Αποτελεσματικός ',
  pleaseEnterPasswordAgain: 'Εισάγετε ξανά τον κωδικό σας',
  passwordInconsistent: 'Ο κωδικός που εισάγατε δύο φορές είναι ασύμφωνος!',
  createdSuccessfully: 'Δημιουργήθηκε με επιτυχία',
  addedSuccessfully: 'Προστέθηκε με επιτυχία',
  modifyAddress: 'Τροποποίηση Διεύθυνσης',
  modifiedSuccessfully: 'Τροποποιήθηκε με επιτυχία',
  copy: 'Το κείμενο έχει αντιγραφεί στο πρόχειρο!',
  rechargeSuccessful: 'Φόρτιση Εγχειρίστηκε με επιτυχία',
  deleteSuccessfully: 'Διαγράφηκε με επιτυχία',
  paymentSuccessful: 'Πληρωμή Εγχειρίστηκε με επιτυχία',
  payOrder: 'Πληρωμή Παραγγελίας',
  pay: 'Πληρωμή',
  available: 'Διαθέσιμο',
  enterPaymentPassword: 'Εισάγετε 6-ψήφιο Κωδικό Πληρωμής',
  confirmSuccessfulReceiptOfGoods: 'Επιβεβαίωση Επιτυχούς Παραλαβής Εμπορευμάτων',
  correct: 'Σωστό',
  pleaseUploadInFull: 'Παρακαλώ ανεβάστε πλήρως τις απαιτούμενες εικόνες!',
  submittedSuccessfully: 'Υποβλήθηκε με επιτυχία',
  evaluate: 'Αξιολογήστε',
  PleaseFillInTheFormCompletely: 'Παρακαλώ συμπληρώστε πλήρως τη φόρμα!',
  qrCodePlaceholder: 'QR Κωδικός',
  verificationCodeSentSuccessfully: 'Ο κωδικός επαλήθευσης στάλθηκε με επιτυχία, παρακαλώ συμπληρώστε και υποβάλετε εντός 60 δευτερολέπτων!',
  pleaseEnterYourEmailFirst: 'Παρακαλώ εισάγετε πρώτα το email σας',
  ContactTheMerchant: "Επικοινωνήστε με τον έμπορο",
  inputIssues: 'Εισάγετε προβλήματα',
};
export default el;

