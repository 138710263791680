import { createPinia } from 'pinia';
import { useCounterStore } from "@/stores/modules/user";
// pinia 持久化插件
import piniaPersist from 'pinia-plugin-persist'

const pinia = createPinia();
pinia.use(piniaPersist);

export { useCounterStore };
export default pinia;
