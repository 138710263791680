// Creare file it/index.js
const it = {
  route: {
    home: 'Home',
    shopCart: 'Carrello',
    popularStore: 'Negozio Popolare',
    storeDetails: 'Dettagli Negozio',
    hotSellingProducts: 'Prodotti Più Venduti',
    productClassification: 'Classificazione Prodotti',
    productDetails: 'Dettagli Prodotto',
    orderPay: 'Pagamento Ordine',
  },
  home: "Home",
  wallet: "Portafoglio",
  order: "Centro Ordini",
  sell: "Centro Vendite",
  cart: "Carrello",
  search: "Cerca",
  deals: "Offerte di oggi",
  featuredStore: "Negozio in evidenza",
  spotlight: "Riflettori",
  hotSale: "Vendita Calda",
  fashion: "Moda",
  beautyPersonalCare: "Bellezza & Cura Personale",
  homeKitchen: "Casa & Cucina",
  sportsOutdoors: "Sport & Outdoor",
  electronicsAccessories: "Elettronica & Accessori",
  seeAll: "Vedi Tutto",
  category: "Categoria",
  beauty: 'Bellezza',
  personalCare: 'Cura Personale',
  homeFurnishing: 'Arredamento Casa',
  kitchen: 'Cucina',
  sports: 'Sport',
  outdoors: 'Outdoor',
  electronics: 'Elettronica',
  accessories: 'Accessori',
  office: 'Ufficio',
  products: 'Prodotti',
  food: 'Cibo',
  drinks: 'Bevande',
  collectibles: 'Collezionabili',
  automotive: 'Automotive',
  storeOnTikTokShop:'Negozio su TikTok Shop',
  viewShop:'Vedi Negozio',
  reviews:'Recensioni',
  topProducts:'Prodotti Migliori',
  newReleases:'Nuove Uscite',
  balance: 'Saldo',
  recharge: 'Ricarica',
  withdraw: 'Ritira',
  fundAccount: 'Conto Fondi',
  add: 'Aggiungi',
  addFundAccount: 'Aggiungi Conto Fondi',
  crypto: 'Crypto',
  mainnet: 'Mainnet',
  address: 'Indirizzo',
  debitCards: 'Carte di Debito',
  iban: 'IBAN',
  account: 'Conto',
  cardNumber: 'Numero di Carta',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount:'Importo',
  qrCode:'Codice QR',
  rechargeAmountUsdt:'Importo Ricarica (USDT)',
  pleaseEnterTheRechargeAmountUsdt:'Inserisci limporto della ricarica (USDT)',
  submitForReview:'Invia per revisione',
  purchased: "Acquistato",
  carriage: "Trasporto",
  completed: "Completato",
  awaitingShipment:'In Attesa di Spedizione',
  orderDetails: "Dettagli Ordine",
  receive: "Ricevi",
  review:'Recensione',
  enterprise: 'Impresa',
  personal:'Personale',
  businessName: 'Nome Azienda',
  headOfEnterprise: 'Capo Impresa',
  headPhoneNumber: 'Numero di Telefono del Capo',
  headWhatsAppNumber: 'Numero WhatsApp del Capo',
  submit: 'Invia',
  uploadLogo: 'Carica Logo',
  storeName: 'Nome Negozio',
  storeEmail: 'E-mail Negozio',
  storeHeadName: 'Nome del Capo del Negozio',
  storeHeadIdNumber: 'Numero ID del Capo del Negozio',
  idFrontPhoto: 'Foto Fronte ID',
  idBackPhoto: 'Foto Retro ID',
  personalHandheldIdPhoto: 'Foto ID Tenuto a Mano',
  storeHeadPhoneNumber: 'Numero di Telefono del Capo del Negozio',
  storeSupportNumber: 'Numero di Supporto del Negozio',
  invitationCode: 'Codice di Invito',
  login: 'Accedi',
  createAccount: 'Crea un Account',
  emailOrPhone: 'E-mail o Numero di Telefono',
  password: 'Password',
  signIn: 'Accedi',
  yourName: 'Il Tuo Nome',
  reEnterPassword: 'Reinserisci la Password',
  emailOrPhoneCode: 'Codice E-mail o Telefono',
  request: 'Richiedi',
  createAccountButton: 'Crea Account',
  privacyPolicy: '《Politica sulla Privacy di TikTok》',
  acknowledgmentText: 'Facendo clic su Salva, riconosci di aver letto la {privacyPolicy}. Le tue informazioni possono essere utilizzate per personalizzare la tua esperienza, inclusi gli annunci, a seconda delle tue impostazioni.',
  buy: 'Acquista',
  exploreBargains: 'Esplora Offerte di Qualità',
  aboutSpotlight: 'Informazioni su Spotlight',
  freeShipping: 'Spedizione Gratuita',
  freeShippingDescription: 'Per ordini superiori a 25$',
  freeReturns: 'Resi Gratuiti',
  freeReturnsDescription: 'Entro 30 giorni',
  shopPerformance: 'Prestazioni del Negozio',
  betterThan: 'Meglio del 80% degli altri negozi',
  starRating: 'Valutazione di 4+ stelle',
  shipsWithin: 'Spedizione entro 2 giorni',
  reviewsCount: '({count} recensioni)',
  cartEmpty: 'Il tuo carrello è vuoto',
  selectAll: 'Seleziona Tutto',
  totalItems: 'Totale ({num} articoli)',
  checkout: 'Checkout',
  shippingReturnsTitle: 'Spedizione e Resi',
  estimatedDelivery: 'Consegna Stimata 11-13 luglio, 7,43$',
  shippingTo: 'Spedizione a Denver, 17517',
  shipsOut: 'Di solito spedisce in 1 giorno lavorativo',
  returnPolicyTitle: 'Politica di Reso',
  addToCart: 'Aggiungi al Carrello',
  aboutThisProduct: 'Informazioni su questo Prodotto',
  shippingAddress: 'Indirizzo di Spedizione',
  addAddress: 'Aggiungi un Indirizzo',
  paymentMethod: 'Metodo di Pagamento',
  shippingMethod: 'Metodo di Spedizione',
  orderSummary: 'Riepilogo Ordine',
  subtotal: 'Subtotale',
  shipping: 'Spedizione',
  shippingToBeCalculated: 'da calcolare',
  total: 'Totale ({num} articoli)',
  firstName: 'Nome',
  lastName: 'Cognome',
  street: 'Via',
  apartment: 'Appartamento, suite, ecc. (opzionale)',
  city: 'Città',
  state: 'Stato',
  zipCode: 'CAP',
  phone: 'Telefono',
  save: 'Salva',
  privacyPolicyDescription: 'Facendo clic su Salva, riconosci di aver letto la {privacyPolicy}. Le tue informazioni possono essere utilizzate per personalizzare la tua esperienza, inclusi gli annunci, a seconda delle tue impostazioni.',
  usCountryCode: 'USA+1',
  logout:'Esci',
  selectShippingMethod: 'Seleziona Metodo di Spedizione',
  standardShipping: "Spedizione Standard: ${amount}",
  estimatedDeliverys: "Consegna Stimata: {date}",
  taxes: "Tasse",
  breadcrumbs: {
    classification: 'Classificazione',
    subClassification: 'Sottoclassificazione',
    selectedProduct: 'Prodotto Selezionato',
  },
  downloadApp: 'Scarica l\'App',
  register: 'Registrati',
  verifiedPurchase: 'Acquisto Verificato',
  pleaseInput: 'Per favore inserisci ',
  code:'Codice',
  effective:'Efficace ',
  pleaseEnterPasswordAgain:'Inserisci di nuovo la tua password',
  passwordInconsistent:'Le password inserite non coincidono!',
  createdSuccessfully:'Creato con successo',
  addedSuccessfully:'Aggiunto con successo',
  modifyAddress:'Modifica Indirizzo',
  modifiedSuccessfully:'Modificato con successo',
  copy:'Il testo è stato copiato negli appunti!',
  rechargeSuccessful:'Ricarica riuscita',
  deleteSuccessfully:'Eliminato con successo',
  paymentSuccessful:'Pagamento riuscito',
  payOrder: 'Paga Ordine',
  pay: 'Paga',
  available: 'Disponibile',
  enterPaymentPassword: 'Inserisci Password di Pagamento di 6 cifre',
  confirmSuccessfulReceiptOfGoods:'Conferma Ricezione dei Beni con Successo',
  correct:' Corretto ',
  pleaseUploadInFull:'Carica per intero le immagini richieste!',
  submittedSuccessfully:'Inviato con successo',
  evaluate:'Valuta',
  PleaseFillInTheFormCompletely:'Compila completamente il modulo!',
  qrCodePlaceholder:'Codice QR',
  verificationCodeSentSuccessfully:'Codice di verifica inviato con successo, completa e invia entro 60 secondi!',
  pleaseEnterYourEmailFirst:'Per favore inserisci prima il tuo indirizzo e-mail',
  ContactTheMerchant: "Contatta il venditore",
  inputIssues: 'Inserisci problemi',
}
export default it

