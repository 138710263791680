const pt = {
  route: {
    home: 'Início',
    shopCart: 'Carrinho',
    popularStore: 'Loja Popular',
    storeDetails: 'Detalhes da Loja',
    hotSellingProducts: 'Produtos Mais Vendidos',
    productClassification: 'Classificação de Produtos',
    productDetails: 'Detalhes do Produto',
    orderPay: 'Pagamento do Pedido',
  },
  home: "Início",
  wallet: "Carteira",
  order: "Centro de Pedidos",
  sell: "Centro de Vendas",
  cart: "Carrinho",
  search: "Buscar",
  deals: "Ofertas do Dia",
  featuredStore: "Loja em Destaque",
  spotlight: "Destaque",
  hotSale: "Venda Quente",
  fashion: "Moda",
  beautyPersonalCare: "Beleza e Cuidados Pessoais",
  homeKitchen: "Casa e Cozinha",
  sportsOutdoors: "Esportes e Lazer",
  electronicsAccessories: "Eletrônicos e Acessórios",
  seeAll: "Ver Todos",
  category: "Categoria",
  beauty: 'Beleza',
  personalCare: 'Cuidados Pessoais',
  homeFurnishing: 'Casa',
  kitchen: 'Cozinha',
  sports: 'Esportes',
  outdoors: 'Lazer',
  electronics: 'Eletrônicos',
  accessories: 'Acessórios',
  office: 'Escritório',
  products: 'Produtos',
  food: 'Comida',
  drinks: 'Bebidas',
  collectibles: 'Colecionáveis',
  automotive: 'Automotivo',
  storeOnTikTokShop: 'Loja no TikTok Shop',
  viewShop: 'Ver Loja',
  reviews: 'Avaliações',
  topProducts: 'Principais Produtos',
  newReleases: 'Novidades',
  balance: 'Saldo',
  recharge: 'Recarregar',
  withdraw: 'Sacar',
  fundAccount: 'Adicionar Fundos',
  add: 'Adicionar',
  addFundAccount: 'Adicionar Conta de Fundos',
  crypto: 'Cripto',
  mainnet: 'Mainnet',
  address: 'Endereço',
  debitCards: 'Cartões de Débito',
  iban: 'IBAN',
  account: 'Conta',
  cardNumber: 'Número do Cartão',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount: 'Quantia',
  qrCode: 'Código QR',
  rechargeAmountUsdt: 'Quantia de Recarga (USDT)',
  pleaseEnterTheRechargeAmountUsdt: 'Por favor, insira a quantia de recarga (USDT)',
  submitForReview: 'Enviar para Revisão',
  purchased: "Comprado",
  carriage: "Frete",
  completed: "Concluído",
  awaitingShipment: 'Aguardando Envio',
  orderDetails: "Detalhes do Pedido",
  receive: "Receber",
  review: 'Avaliação',
  enterprise: 'Empresa',
  personal: 'Pessoal',
  businessName: 'Nome da Empresa',
  headOfEnterprise: 'Chefe da Empresa',
  headPhoneNumber: 'Número de Telefone do Chefe',
  headWhatsAppNumber: 'Número do WhatsApp do Chefe',
  submit: 'Enviar',
  uploadLogo: 'Enviar Logotipo',
  storeName: 'Nome da Loja',
  storeEmail: 'E-mail da Loja',
  storeHeadName: 'Nome do Chefe da Loja',
  storeHeadIdNumber: 'Número de ID do Chefe da Loja',
  idFrontPhoto: 'Foto do Documento de Identidade (Frente)',
  idBackPhoto: 'Foto do Documento de Identidade (Verso)',
  personalHandheldIdPhoto: 'Foto do Documento de Identidade (em Mão)',
  storeHeadPhoneNumber: 'Número de Telefone do Chefe da Loja',
  storeSupportNumber: 'Número de Suporte da Loja',
  invitationCode: 'Código de Convite',
  login: 'Entrar',
  createAccount: 'Criar Conta',
  emailOrPhone: 'E-mail ou Número de Telefone',
  password: 'Senha',
  signIn: 'Entrar',
  yourName: 'Seu Nome',
  reEnterPassword: 'Reinsira a Senha',
  emailOrPhoneCode: 'Código de E-mail ou Telefone',
  request: 'Solicitar',
  createAccountButton: 'Criar Conta',
  privacyPolicy: '《Política de Privacidade do TikTok》',
  acknowledgmentText: 'Ao clicar em Salvar, você reconhece que leu a {privacyPolicy}. Suas informações podem ser usadas para personalizar sua experiência, incluindo anúncios, de acordo com suas configurações.',
  buy: 'Comprar',
  exploreBargains: 'Explore Ofertas de Qualidade',
  aboutSpotlight: 'Sobre o Destaque',
  freeShipping: 'Frete Grátis',
  freeShippingDescription: 'Em pedidos acima de $25',
  freeReturns: 'Devoluções Grátis',
  freeReturnsDescription: 'Dentro de 30 dias',
  shopPerformance: 'Desempenho da Loja',
  betterThan: 'Melhor que 80% das outras lojas',
  starRating: 'Avaliação de 4+ estrelas',
  shipsWithin: 'Despacha dentro de 2 dias',
  reviewsCount: '({count} avaliações)',
  cartEmpty: 'Seu carrinho está vazio',
  selectAll: 'Selecionar Todos',
  totalItems: 'Total ({num} item)',
  checkout: 'Finalizar Compra',
  shippingReturnsTitle: 'Frete e Devoluções',
  estimatedDelivery: 'Entrega Estimada 11-13 de julho, $7.43',
  shippingTo: 'Enviando para Denver, 17517',
  shipsOut: 'Normalmente despacha em 1 dia útil',
  returnPolicyTitle: 'Política de Devolução',
  addToCart: 'Adicionar ao Carrinho',
  aboutThisProduct: 'Sobre este produto',
  shippingAddress: 'Endereço de Envio',
  addAddress: 'Adicionar Endereço',
  paymentMethod: 'Método de Pagamento',
  shippingMethod: 'Método de Envio',
  orderSummary: 'Resumo do Pedido',
  subtotal: 'Subtotal',
  shipping: 'Frete',
  shippingToBeCalculated: 'a ser calculado',
  total: 'Total ({num} item)',
  firstName: 'Primeiro Nome',
  lastName: 'Último Nome',
  street: 'Rua',
  apartment: 'Apartamento, suíte, etc. (opcional)',
  city: 'Cidade',
  state: 'Estado',
  zipCode: 'CEP',
  phone: 'Telefone',
  save: 'Salvar',
  privacyPolicyDescription: 'Ao clicar em Salvar, você reconhece que leu a {privacyPolicy}. Suas informações podem ser usadas para personalizar sua experiência, incluindo anúncios, de acordo com suas configurações.',
  usCountryCode: 'US+1',
  logout: 'Sair',
  selectShippingMethod: 'Escolher Método de Envio',
  standardShipping: "Frete Padrão: ${amount}",
  estimatedDeliverys: "Entrega Estimada: {date}",
  taxes: "Impostos",
  breadcrumbs: {
    classification: 'Classificação',
    subClassification: 'Subclassificação',
    selectedProduct: 'Produto Selecionado',
  },
  downloadApp: 'Baixar App',
  register: 'Registrar',
  verifiedPurchase: 'Compra Verificada',
  pleaseInput: 'Por favor, insira ',
  code: 'Código',
  effective: 'Eficaz',
  pleaseEnterPasswordAgain: 'Por favor, insira sua senha novamente',
  passwordInconsistent: 'A senha inserida não corresponde!',
  createdSuccessfully: 'Criado com Sucesso',
  addedSuccessfully: 'Adicionado com Sucesso',
  modifyAddress: 'Modificar Endereço',
  modifiedSuccessfully: 'Modificado com Sucesso',
  copy: 'Texto copiado para a área de transferência!',
  rechargeSuccessful: 'Recarga Bem-Sucedida',
  deleteSuccessfully: 'Excluído com Sucesso',
  paymentSuccessful: 'Pagamento Bem-Sucedido',
  payOrder: 'Pagar Pedido',
  pay: 'Pagar',
  available: 'Disponível',
  enterPaymentPassword: 'Digite a Senha de Pagamento de 6 dígitos',
  confirmSuccessfulReceiptOfGoods: 'Confirmar Recebimento Bem-Sucedido dos Produtos',
  correct: 'Correto',
  pleaseUploadInFull: 'Por favor, faça o upload de todas as imagens solicitadas!',
  submittedSuccessfully: 'Enviado com Sucesso',
  evaluate: 'Avaliar',
  PleaseFillInTheFormCompletely: 'Por favor, preencha o formulário completamente!',
  qrCodePlaceholder: 'Código QR',
  verificationCodeSentSuccessfully: 'Código de verificação enviado com sucesso, por favor, insira e envie dentro de 60 segundos.',
  pleaseEnterYourEmailFirst: 'Por favor, insira seu e-mail primeiro',
  ContactTheMerchant: "Contactar o comerciante",
  inputIssues: 'Descreva os Problemas',
}
export default pt
