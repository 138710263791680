class websocket {


  constructor(url) {
    this.socket = new WebSocket(url);
    let _this = this;
    this.socket.onopen = function() {
      _this.onHeart();
      _this.onMessage();
      _this.onError();
    }
  }
  //初始化
  init(wssurl, atuh, uid = 0, shop_id, callback) {
    // alert(12)
    this.wssurl = wssurl;
    this.atuh = atuh;
    this.uid = uid;
    this.connect = false;
    this.shop_id = shop_id;

    let login_data = '';
    if (this.atuh == 'admin') {
      login_data = '{"type":"login_service","client_name":"manufacturer","room_id":2,"uuid":"service"}';
    } else if (this.atuh == 'login_client') {
      //用户之间聊天
      login_data = '{"type":"login_client","uid":' + this.uid + ',"shop_id":"' + this.shop_id + '"}';
    }
    this.socket.send(login_data);
    callback(this.socket)

    // this.onOpen(callback);


  }
  onClose() {
    this.socket.close()
  }
  //心跳
  onHeart() {
    let _this = this;
    this.heart = setInterval(() => {
      let msaaeg = '{"type":"pong"}';
      _this.socket.send(msaaeg);
    }, 10000);
  }
  //错误重连
  onError() {
    let _this = this;

    this.socket.onclose = function() {
      clearInterval(_this.heart)
      _this.connect = false;
      console.log('链接错误,尝试重连');
      setTimeout(() => {
        _this.init(_this.wssurl, _this.atuh, _this.uid);
      }, 1000)
    }
  }

  getChatList(shop_id, user_id) {
    let messageTxt = "";
    let arr = {
      type: "getChatList",
      shop_id: shop_id,
      user_id: user_id,
    }
    messageTxt = JSON.stringify(arr);
    this.socket.send(messageTxt);
  }
  getUserList() {
    let messageTxt = "";
    messageTxt = '{"type":"getUserList"}';
    this.socket.send(messageTxt);
  }
  //链接成功
  onOpen(callback) {
    var login_data = "";
    //尝试登陆
    if (this.atuh == 'admin') {
      login_data = '{"type":"login_service","client_name":"manufacturer","room_id":2,"uuid":"service"}';
    } else if (this.atuh == 'login_client') {
      //用户之间聊天
      login_data = '{"type":"login_client","uid":' + this.uid + ',"shop_id":"' + this.shop_id + '"}';
    }


    let _this = this;
    this.socket.onopen = function() {
      alert(132)
      _this.socket.send(login_data);
      // console.log(_this.socket);
      _this.connect = true;

      callback(_this.socket)
      _this.onMessage();
    }
  }

  //向店铺发送消息 shop_id 店铺id message 消息内容 model 消息类型 image 图片 text 文本
  sendShopMessage(uid, shop_id, message, model) {
    let request = {
      type: "say_client",
      model: model,
      content: message,
      shop_id: shop_id,
      uid: uid
    };
    this.socket.send(JSON.stringify(request));
  }



  //发送消息  uuid 用户id  text 消息内容 model 消息类型 text 文本 image 图片
  send(uuid, text, model) {
    let to_client_name = 'sendtxt'
    let messageTxt = "";
    if (this.atuh == 'admin') {
      messageTxt = '{"type":"sayuser","uuid":"' + uuid + '","to_client_name":"' +
        to_client_name +
        '","content":"' + text.replace(/"/g, '\\"').replace(/\n/g, '\\n').replace(/\r/g, '\\r') +
        '","model":"' + model + '"}';
    } else if (this.atuh == 'login_client') {
      messageTxt = '{"type":"say_client","uid":"' + uuid + '","to_client_name":"' +
        to_client_name +
        '","content":"' + text.replace(/"/g, '\\"').replace(/\n/g, '\\n').replace(/\r/g, '\\r') +
        '","model":"' + model + '"}';
    }

    this.socket.send(messageTxt);
  }
  //消息监听
  onMessage() {
    let _this = this;
    this.socket.onmessage = function(res) {
      let data = res.data;
      data = JSON.parse(data);

      window.dispatchEvent(new CustomEvent('onmessageWS', {
        detail: {
          data: data
        }
      }))
    }


  }
}


export default websocket;
