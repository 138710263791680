// 创建文件cs/index.js
const cs = {
  route: {
    home: 'Domů',
    shopCart: 'Nákupní košík',
    popularStore: 'Oblíbený obchod',
    storeDetails: 'Detaily obchodu',
    hotSellingProducts: 'Nejprodávanější produkty',
    productClassification: 'Klasifikace produktů',
    productDetails: 'Detaily produktu',
    orderPay: 'Platba objednávky',
  },
  home: "Domů",
  wallet: "Peněženka",
  order: "Centrum objednávek",
  sell: "Prodejní centrum",
  cart: "Košík",
  search: "Vyhledávání",
  deals: "Dnešní nabídky",
  featuredStore: "Doporučený obchod",
  spotlight: "Na výsluní",
  hotSale: "Horký výprodej",
  fashion: "Móda",
  beautyPersonalCare: "Krása a osobní péče",
  homeKitchen: "Domov a kuchyně",
  sportsOutdoors: "Sport a venkovní aktivity",
  electronicsAccessories: "Elektronika a příslušenství",
  seeAll: "Zobrazit vše",
  category: "Kategorie",
  beauty: 'Krása',
  personalCare: 'Osobní péče',
  homeFurnishing: 'Domácnost',
  kitchen: 'Kuchyně',
  sports: 'Sport',
  outdoors: 'Venkovní aktivity',
  electronics: 'Elektronika',
  accessories: 'Příslušenství',
  office: 'Kancelář',
  products: 'Produkty',
  food: 'Potraviny',
  drinks: 'Nápoje',
  collectibles: 'Sbírky',
  automotive: 'Automobilový průmysl',
  storeOnTikTokShop: 'Obchod na TikTok Shopu',
  viewShop: 'Zobrazit obchod',
  reviews: 'Recenze',
  topProducts: 'Top produkty',
  newReleases: 'Novinky',
  balance: 'Zůstatek',
  recharge: 'Dobití',
  withdraw: 'Výběr',
  fundAccount: 'Fondový účet',
  add: 'Přidat',
  addFundAccount: 'Přidat fondový účet',
  crypto: 'Kryptoměna',
  mainnet: 'Mainnet',
  address: 'Adresa',
  debitCards: 'Debetní karty',
  iban: 'IBAN',
  account: 'Účet',
  cardNumber: 'Číslo karty',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount:'Částka',
  qrCode:'QR kód',
  rechargeAmountUsdt:'Částka dobití (USDT)',
  pleaseEnterTheRechargeAmountUsdt:'Zadejte částku dobití (USDT)',
  submitForReview:'Odeslat k recenzi',
  purchased: "Zakoupeno",
  carriage: "Přeprava",
  completed: "Dokončeno",
  awaitingShipment:'Čeká na odeslání',
  orderDetails: "Detaily objednávky",
  receive: "Přijmout",
  review:'Recenze',
  enterprise: 'Podnik',
  personal:'Osobní',
  businessName: 'Název podniku',
  headOfEnterprise: 'Vedoucí podniku',
  headPhoneNumber: 'Telefon vedoucího',
  headWhatsAppNumber: 'WhatsApp číslo vedoucího',
  submit: 'Odeslat',
  uploadLogo: 'Nahrát logo',
  storeName: 'Název obchodu',
  storeEmail: 'E-mail obchodu',
  storeHeadName: 'Jméno vedoucího obchodu',
  storeHeadIdNumber: 'Číslo OP vedoucího obchodu',
  idFrontPhoto: 'Přední strana OP',
  idBackPhoto: 'Zadní strana OP',
  personalHandheldIdPhoto: 'Osobní foto s OP',
  storeHeadPhoneNumber: 'Telefon vedoucího obchodu',
  storeSupportNumber: 'Podpora obchodu - telefon',
  invitationCode: 'Pozvánkový kód',
  login: 'Přihlášení',
  createAccount: 'Vytvořit účet',
  emailOrPhone: 'E-mail nebo telefonní číslo',
  password: 'Heslo',
  signIn: 'Přihlásit se',
  yourName: 'Vaše jméno',
  reEnterPassword: 'Znovu zadejte heslo',
  emailOrPhoneCode: 'E-mail nebo kód telefonu',
  request: 'Žádost',
  createAccountButton: 'Vytvořit účet',
  privacyPolicy: '《TikTok Zásady ochrany osobních údajů》',
  acknowledgmentText: 'Kliknutím na Uložit potvrzujete, že jste si přečetli {privacyPolicy}. Vaše informace mohou být použity k personalizaci vaší zkušenosti, včetně reklam, podle vašich nastavení.',
  buy: 'Koupit',
  exploreBargains: 'Prozkoumejte kvalitní nabídky',
  aboutSpotlight: 'O Spotlightu',
  freeShipping: 'Doprava zdarma',
  freeShippingDescription: 'U objednávek nad $25',
  freeReturns: 'Bezplatné vrácení',
  freeReturnsDescription: 'Do 30 dnů',
  shopPerformance: 'Výkonnost obchodu',
  betterThan: 'Lepší než 80% ostatních obchodů',
  starRating: 'Hodnocení 4+ hvězdiček',
  shipsWithin: 'Odesláno do 2 dnů',
  reviewsCount: '({count} recenzí)',
  cartEmpty: 'Váš košík je prázdný',
  selectAll: 'Vybrat vše',
  totalItems: 'Celkem ({num} položek)',
  checkout: 'Pokladna',
  shippingReturnsTitle: 'Doprava a vrácení',
  estimatedDelivery: 'Odhadované doručení 11.-13. července, $7.43',
  shippingTo: 'Doprava do Denveru, 17517',
  shipsOut: 'Obvykle odesláno do 1 pracovního dne',
  returnPolicyTitle: 'Zásady vrácení',
  addToCart: 'Přidat do košíku',
  aboutThisProduct: 'O tomto produktu',
  shippingAddress: 'Doručovací adresa',
  addAddress: 'Přidat adresu',
  paymentMethod: 'Způsob platby',
  shippingMethod: 'Způsob dopravy',
  orderSummary: 'Souhrn objednávky',
  subtotal: 'Mezisoučet',
  shipping: 'Doprava',
  shippingToBeCalculated: 'bude vypočteno',
  total: 'Celkem ({num} položek)',
  firstName: 'Křestní jméno',
  lastName: 'Příjmení',
  street: 'Ulice',
  apartment: 'Byt, suite, atd. (volitelně)',
  city: 'Město',
  state: 'Stát',
  zipCode: 'PSČ',
  phone: 'Telefon',
  save: 'Uložit',
  privacyPolicyDescription: 'Kliknutím na Uložit potvrzujete, že jste si přečetli {privacyPolicy}. Vaše informace mohou být použity k personalizaci vaší zkušenosti, včetně reklam, podle vašich nastavení.',
  usCountryCode: 'US+1',
  logout:'Odhlásit se',
  selectShippingMethod: 'Vyberte způsob dopravy',
  standardShipping: "Standardní doprava: ${amount}",
  estimatedDeliverys: "Odhadované doručení: {date}",
  taxes: "Daně",
  breadcrumbs: {
    classification: 'Klasifikace',
    subClassification: 'Podklasifikace',
    selectedProduct: 'Vybraný produkt',
  },
  downloadApp: 'Stáhnout aplikaci',
  register: 'Registrovat',
  verifiedPurchase: 'Ověřený nákup',
  pleaseInput: 'Prosím zadejte ',
  code:'Kód',
  effective:'Platný ',
  pleaseEnterPasswordAgain:'Prosím zadejte heslo znovu',
  passwordInconsistent:'Zadaná hesla se neshodují!',
  createdSuccessfully:'Úspěšně vytvořeno',
  addedSuccessfully:'Úspěšně přidáno',
  modifyAddress:'Upravit adresu',
  modifiedSuccessfully:'Úspěšně upraveno',
  copy:'Text byl zkopírován do schránky!',
  rechargeSuccessful:'Úspěšné dobití',
  deleteSuccessfully:'Úspěšně smazáno',
  paymentSuccessful:'Platba úspěšná',
  payOrder: 'Zaplacení objednávky',
  pay: 'Zaplatit',
  available: 'Dostupné',
  enterPaymentPassword: 'Zadejte 6místné heslo pro platbu',
  confirmSuccessfulReceiptOfGoods:'Potvrdit úspěšné přijetí zboží',
  correct:'Správné',
  pleaseUploadInFull:'Prosím nahrajte všechny požadované obrázky!',
  submittedSuccessfully:'Úspěšně odesláno',
  evaluate:'Hodnotit',
  PleaseFillInTheFormCompletely:'Prosím vyplňte celý formulář!',
  qrCodePlaceholder:'QR kód',
  verificationCodeSentSuccessfully:'Ověřovací kód úspěšně odeslán, vyplňte a odešlete do 60 sekund!',
  pleaseEnterYourEmailFirst:'Nejdříve zadejte svůj e-mail',
  ContactTheMerchant: "Kontaktovat obchodníka",
  inputIssues: 'Zadat problémy',
}
export default cs
