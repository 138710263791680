/**
 * pinia 方法
 */
export default {
  /**
   * token缓存
   * @param token
   */
  setToken(token) {
    if (token) {
      this.token = token;
    } else {
      this.token = '';
    }
  },
  /**
   * uid缓存
   * @param uid
   */
  setUid(uid) {
    if (uid) {
      this.Stid = uid;
    } else {
      this.Stid = ''
    }
  },
  /**
   * 用户信息缓存
   * @param user
   */
  setUser(user) {
    if (user) {
      this.user_info = user;
    } else {
      this.user_info = {}
    }
  },
  /**
   * 菜单缓存
   * @param data
   */
  setRoutingMenu(data) {
    if (data) {
      this.routingMenu = data;
    } else {
      this.routingMenu = []
    }
  },
  /**
   * 状态管理中获取权限菜单进行路由表生成
   * @param modules
   * @returns {*[]}
   */
  getRouterMenu(modules) {
    let routerList = []
    if (this.routingMenu) {
      this.routingMenu.filter(itemRouter => {
        let tempA = {
          path: itemRouter.path,
          name: itemRouter.name,
          hidden:itemRouter.hidden,
          component: () => import('@/vab/layout/index.vue'),
          meta: {
            title: itemRouter.meta.title,
            noKeepAlive: itemRouter.meta.noKeepAlive,
            icon: itemRouter.meta.icon
          },
          children: []
        }
        if (itemRouter.children) {
          itemRouter.children.filter(item => {
            let tempB = {
              path: item.path,
              name: item.name,
              hidden:item.hidden,
              meta: {
                title: item.meta.title,
                noKeepAlive: item.meta.noKeepAlive,
                icon: item.meta.icon
              },
              component: item.component === 'index' ? modules[`../views/${item.component}/${item.component}.vue`] : modules[`../views/${item.component}.vue`]
            }
            tempA.children.push(tempB)
          })
          routerList.push(tempA)
        } else {
          routerList.push(tempA)
        }
      })
    }
    return routerList
  }
}
