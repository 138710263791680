import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '@/i18n'; // 导入你的 i18n 实例

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    // name: 'layout',
    component: () => import('@/vab/layout/index.vue'),
    hidden: true,
    meta: {
      title: '',
      noKeepAlive: true,
      icon: ''
    },
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/view/home/index.vue'),
        hidden: true,
        meta: {
          title: i18n.t('route.home'), // 国际化标题
          noKeepAlive: true,
          icon: ''
        },
      },
      {
        path: 'shopCart',
        name: 'shopCart',
        component: () => import('@/view/shoppingCart/index.vue'),
        hidden: true,
        meta: {
          title: i18n.t('route.shopCart'), // 国际化标题
          noKeepAlive: true,
          icon: ''
        },
      },
      {
        path: 'popularStore',
        name: 'popularStore',
        component: () => import('@/view/popularStore/index.vue'),
        hidden: true,
        meta: {
          title: i18n.t('route.popularStore'), // 国际化标题
          noKeepAlive: true,
          icon: ''
        },
      },
      {
        path: 'storeDetails',
        name: 'storeDetails',
        component: () => import('@/view/storeDetails/index.vue'),
        hidden: true,
        meta: {
          title: i18n.t('route.storeDetails'), // 国际化标题
          noKeepAlive: true,
          icon: ''
        },
      },
      {
        path: 'hotSellingProducts',
        name: 'hotSellingProducts',
        component: () => import('@/view/hotSellingProducts/index.vue'),
        hidden: true,
        meta: {
          title: i18n.t('route.hotSellingProducts'), // 国际化标题
          noKeepAlive: true,
          icon: ''
        },
      },
      {
        path: 'productClassification',
        name: 'productClassification',
        component: () => import('@/view/productClassification/index.vue'),
        hidden: true,
        meta: {
          title: i18n.t('route.productClassification'), // 国际化标题
          noKeepAlive: true,
          icon: ''
        },
      },
      {
        path: 'productDetails',
        name: 'productDetails',
        component: () => import('@/view/productDetails/index.vue'),
        hidden: true,
        meta: {
          title: i18n.t('route.productDetails'), // 国际化标题
          noKeepAlive: true,
          icon: ''
        },
      },
      {
        path: 'orderPay',
        name: 'orderPay',
        component: () => import('@/view/orderPay/index.vue'),
        hidden: true,
        meta: {
          title: i18n.t('route.orderPay'), // 国际化标题
          noKeepAlive: true,
          icon: ''
        },
      },
      {
        path: 'search',
        name: 'search',
        component: () => import('@/view/search/index.vue'),
        hidden: true,
        meta: {
          title: i18n.t('search'), // 国际化标题
          noKeepAlive: true,
          icon: ''
        },
      },
    ]
  },
    // {
    //     path: '/',
    //     name: 'home',
    //     component: () => import('@/vab/layout/index/Header.vue'),
    // },
    // 其他路由配置
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
      return new Promise((resolve) => {
        setTimeout(() => {
          if (savedPosition) {
            window.scrollTo({
              top: savedPosition.y,
              behavior: 'smooth'
            });
          } else {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
          }
          resolve();
        }, 0);
      });
    },
});

router.beforeEach((to, from, next) => {
  // 通过 meta 信息设置页面标题
  // if (to.meta.title) {
  //   document.title = to.meta.title;
  // }
  next();
});

export default router;
