const lt = {
  route: {
    home: 'Pradžia',
    shopCart: 'Pirkinių krepšelis',
    popularStore: 'Populiarus parduotuvė',
    storeDetails: 'Parduotuvės detalės',
    hotSellingProducts: 'Karšti pardavimų produktai',
    productClassification: 'Produkto klasifikacija',
    productDetails: 'Produkto detalės',
    orderPay: 'Užsakymo apmokėjimas',
  },
  home: "Pradžia",
  wallet: "Piniginė",
  order: "Užsakymų centras",
  sell: "Pardavimų centras",
  cart: "Krepšelis",
  search: "Ieškoti",
  deals: "Šiandienos pasiūlymai",
  featuredStore: "Išskirtinė parduotuvė",
  spotlight: "Atskleidimas",
  hotSale: "Karšti pardavimai",
  fashion: "Mada",
  beautyPersonalCare: "Grožis ir asmeninė priežiūra",
  homeKitchen: "Namai ir virtuvė",
  sportsOutdoors: "Sportas ir lauko veikla",
  electronicsAccessories: "Elektronika ir priedai",
  seeAll: "Rodyti viską",
  category: "Kategorija",
  beauty: 'Grožis',
  personalCare: 'Asmeninė priežiūra',
  homeFurnishing: 'Namai',
  kitchen: 'Virtuvė',
  sports: 'Sportas',
  outdoors: 'Lauko veikla',
  electronics: 'Elektronika',
  accessories: 'Priedai',
  office: 'Biuro prekės',
  products: 'Produktai',
  food: 'Maistas',
  drinks: 'Gėrimai',
  collectibles: 'Kolekcionuojami daiktai',
  automotive: 'Automobiliai',
  storeOnTikTokShop: 'Parduotuvė TikTok Shop',
  viewShop: 'Peržiūrėti parduotuvę',
  reviews: 'Atsiliepimai',
  topProducts: 'Top produktai',
  newReleases: 'Nauji leidimai',
  balance: 'Balansas',
  recharge: 'Papildyti',
  withdraw: 'Išimti',
  fundAccount: 'Finansuoti sąskaitą',
  add: 'Pridėti',
  addFundAccount: 'Pridėti finansų sąskaitą',
  crypto: 'Kriptovaliuta',
  mainnet: 'Pagrindinis tinklas',
  address: 'Adresas',
  debitCards: 'Debeto kortelės',
  iban: 'IBAN',
  account: 'Sąskaita',
  cardNumber: 'Kortelės numeris',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount: 'Suma',
  qrCode: 'QR kodas',
  rechargeAmountUsdt: 'Papildymo suma (USDT)',
  pleaseEnterTheRechargeAmountUsdt: 'Prašome įvesti papildymo sumą (USDT)',
  submitForReview: 'Pateikti peržiūrai',
  purchased: "Įsigyta",
  carriage: "Pristatymas",
  completed: "Užbaigta",
  awaitingShipment: 'Laukiama siuntos',
  orderDetails: "Užsakymo detalės",
  receive: "Gauti",
  review: 'Atsiliepimas',
  enterprise: 'Įmonė',
  personal: 'Asmeninis',
  businessName: 'Įmonės pavadinimas',
  headOfEnterprise: 'Įmonės vadovas',
  headPhoneNumber: 'Vadovo telefono numeris',
  headWhatsAppNumber: 'Vadovo WhatsApp numeris',
  submit: 'Pateikti',
  uploadLogo: 'Įkelti logotipą',
  storeName: 'Parduotuvės pavadinimas',
  storeEmail: 'Parduotuvės el. paštas',
  storeHeadName: 'Parduotuvės vadovo vardas',
  storeHeadIdNumber: 'Parduotuvės vadovo ID numeris',
  idFrontPhoto: 'ID priekinio vaizdo',
  idBackPhoto: 'ID galinio vaizdo',
  personalHandheldIdPhoto: 'Asmeninė rankinė ID nuotrauka',
  storeHeadPhoneNumber: 'Parduotuvės vadovo telefono numeris',
  storeSupportNumber: 'Parduotuvės palaikymo numeris',
  invitationCode: 'Kvietimo kodas',
  login: 'Prisijungti',
  createAccount: 'Sukurti paskyrą',
  emailOrPhone: 'El. paštas arba mobiliojo telefono numeris',
  password: 'Slaptažodis',
  signIn: 'Prisijungti',
  yourName: 'Jūsų vardas',
  reEnterPassword: 'Įveskite slaptažodį dar kartą',
  emailOrPhoneCode: 'El. pašto arba telefono kodas',
  request: 'Prašymas',
  createAccountButton: 'Sukurti paskyrą',
  privacyPolicy: '《TikTok Privatumo politika》',
  acknowledgmentText: 'Spustelėjęs Išsaugoti, patvirtinate, kad perskaitėte {privacyPolicy}. Jūsų informacija gali būti naudojama personalizuojant jūsų patirtį, įskaitant skelbimus, pagal jūsų nustatymus.',
  buy: 'Pirkti',
  exploreBargains: 'Ištirkite kokybiškus pasiūlymus',
  aboutSpotlight: 'Apie išskirtinumą',
  freeShipping: 'Nemokamas pristatymas',
  freeShippingDescription: 'Perkant virš $25',
  freeReturns: 'Nemokami grąžinimai',
  freeReturnsDescription: 'Per 30 dienų',
  shopPerformance: 'Parduotuvės veikla',
  betterThan: 'Geresnė nei 80% kitų parduotuvių',
  starRating: '4+ žvaigždučių įvertinimas',
  shipsWithin: 'Išsiunčiama per 2 dienas',
  reviewsCount: '({count} atsiliepimų)',
  cartEmpty: 'Jūsų krepšelis tuščias',
  selectAll: 'Pasirinkti viską',
  totalItems: 'Viso ({num} vienetas)',
  checkout: 'Eiti prie kasos',
  shippingReturnsTitle: 'Pristatymas ir grąžinimas',
  estimatedDelivery: 'Numatoma pristatymo data: Jul 11-13, $7.43',
  shippingTo: 'Pristatymas į Denverį, 17517',
  shipsOut: 'Paprastai išsiunčiama per 1 darbo dieną',
  returnPolicyTitle: 'Grąžinimo politika',
  addToCart: 'Įdėti į krepšelį',
  aboutThisProduct: 'Apie šį produktą',
  shippingAddress: 'Pristatymo adresas',
  addAddress: 'Pridėti adresą',
  paymentMethod: 'Mokėjimo būdas',
  shippingMethod: 'Pristatymo būdas',
  orderSummary: 'Užsakymo santrauka',
  subtotal: 'Tarpinė suma',
  shipping: 'Pristatymas',
  shippingToBeCalculated: 'bus apskaičiuota',
  total: 'Viso ({num} vienetas)',
  firstName: 'Vardas',
  lastName: 'Pavardė',
  street: 'Gatvė',
  apartment: 'Butas, suite, ir kt. (pasirinktinai)',
  city: 'Miestas',
  state: 'Valstija',
  zipCode: 'ZIP kodas',
  phone: 'Telefonas',
  save: 'Išsaugoti',
  privacyPolicyDescription: 'Spustelėjęs Išsaugoti, patvirtinate, kad perskaitėte {privacyPolicy}. Jūsų informacija gali būti naudojama personalizuojant jūsų patirtį, įskaitant skelbimus, pagal jūsų nustatymus.',
  usCountryCode: 'US+1',
  logout: 'Atsijungti',
  selectShippingMethod: 'Pasirinkite pristatymo būdą',
  standardShipping: "Standartinis pristatymas: ${amount}",
  estimatedDeliverys: "Numatoma pristatymo data: {date}",
  taxes: "Mokesčiai",
  breadcrumbs: {
    classification: 'Klasifikacija',
    subClassification: 'Subkategorija',
    selectedProduct: 'Pasirinktas produktas',
  },
  downloadApp: 'Atsisiųsti programą',
  register: 'Registruotis',
  verifiedPurchase: 'Patvirtintas pirkimas',
  pleaseInput: 'Prašome įvesti',
  code: 'Kodas',
  effective: 'Galioja',
  pleaseEnterPasswordAgain: 'Prašome įvesti slaptažodį dar kartą',
  passwordInconsistent: 'Dvi kartus įvestas slaptažodis nesutampa!',
  createdSuccessfully: 'Sukurtas sėkmingai',
  addedSuccessfully: 'Priedėtas sėkmingai',
  modifyAddress: 'Redaguoti adresą',
  modifiedSuccessfully: 'Redaguota sėkmingai',
  copy: 'Tekstas nukopijuotas į iškarpinę!',
  rechargeSuccessful: 'Papildymas sėkmingas',
  deleteSuccessfully: 'Ištrinta sėkmingai',
  paymentSuccessful: 'Mokėjimas sėkmingas',
  payOrder: 'Apmokėti užsakymą',
  pay: 'Mokėti',
  available: 'Pasiekiama',
  enterPaymentPassword: 'Įveskite 6 skaitmenų mokėjimo slaptažodį',
  confirmSuccessfulReceiptOfGoods: 'Patvirtinkite sėkmingą prekių gavimą',
  correct: ' Teisinga ',
  pleaseUploadInFull: 'Prašome įkelti visas reikiamas nuotraukas!',
  submittedSuccessfully: 'Pateikta sėkmingai',
  evaluate: 'Įvertinti',
  PleaseFillInTheFormCompletely: 'Prašome visiškai užpildyti formą!',
  qrCodePlaceholder: 'QR kodas',
  verificationCodeSentSuccessfully: 'Verifikacijos kodas išsiųstas sėkmingai, prašome užpildyti ir pateikti per 60 sekundžių!',
  pleaseEnterYourEmailFirst: 'Prašome pirmiausia įvesti savo el. paštą',
  ContactTheMerchant: "Susisiekite su prekiautoju",
  inputIssues: 'Įveskite Problemas',
}
export default lt
