const mt = {
  route: {
    home: 'Darba',
    shopCart: 'Kartell tal-Aħżen',
    popularStore: 'Maħżen Popolari',
    storeDetails: 'Dettalji tal-Maħżen',
    hotSellingProducts: 'Prodotti Popolari',
    productClassification: 'Klassifikazzjoni tal-Prodott',
    productDetails: 'Dettalji tal-Prodott',
    orderPay: 'Ħlas tal-Ordni',
  },
  home: "Darba",
  wallet: "Portmon",
  order: "Ċentru tal-Ordni",
  sell: "Ċentru tal-Bejgħ",
  cart: "Kartell",
  search: "Fittex",
  deals: "Offerti ta' Dalwaqt",
  featuredStore: "Maħżen Miżjud",
  spotlight: "Fokus",
  hotSale: "Bejgħ Popolari",
  fashion: "Moda",
  beautyPersonalCare: "Ġmiel u Ħarsien Personali",
  homeKitchen: "Dar u Kitchen",
  sportsOutdoors: "Isport u Attivitajiet Barra",
  electronicsAccessories: "Elettronika u Aċċessorji",
  seeAll: "Ara Kollha",
  category: "Kategorija",
  beauty: 'Ġmiel',
  personalCare: 'Ħarsien Personali',
  homeFurnishing: 'Dar',
  kitchen: 'Kitchen',
  sports: 'Isport',
  outdoors: 'Barra',
  electronics: 'Elettronika',
  accessories: 'Aċċessorji',
  office: 'Uffiċċju',
  products: 'Prodotti',
  food: 'Ikel',
  drinks: 'Xorb',
  collectibles: 'Ħafna',
  automotive: 'Karozzi',
  storeOnTikTokShop: 'Maħżen fuq TikTok Shop',
  viewShop: 'Ara l-Maħżen',
  reviews: 'Reviżjonijiet',
  topProducts: 'Prodotti Top',
  newReleases: 'Ġodda',
  balance: 'Balanz',
  recharge: 'Ipprova',
  withdraw: 'Iżżid',
  fundAccount: 'Finanzja l-Kont',
  add: 'Żid',
  addFundAccount: 'Żid Kont tal-Fondi',
  crypto: 'Kripto',
  mainnet: 'Mainnet',
  address: 'Indirizz',
  debitCards: 'Kartijiet ta\' Debitu',
  iban: 'IBAN',
  account: 'Kont',
  cardNumber: 'Numru tal-Karta',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount: 'Ammont',
  qrCode: 'Kod QR',
  rechargeAmountUsdt: 'Ammont ta\' Ipprova (USDT)',
  pleaseEnterTheRechargeAmountUsdt: 'Jekk jogħġbok daħħal l-ammont ta\' ippwim (USDT)',
  submitForReview: 'Ippresenta għar-Revista',
  purchased: "Ix-xiri",
  carriage: "Trasport",
  completed: "Ħlas",
  awaitingShipment: 'Jistenna l-Ispedizzjoni',
  orderDetails: "Dettalji tal-Ordni",
  receive: "Riċevuta",
  review: 'Reviżjoni',
  enterprise: 'Kumpanija',
  personal: 'Personali',
  businessName: 'Isem tal-Kumpanija',
  headOfEnterprise: 'Kap tal-Kumpanija',
  headPhoneNumber: 'Numru tat-Telefon tal-Kap',
  headWhatsAppNumber: 'Numru WhatsApp tal-Kap',
  submit: 'Ippresenta',
  uploadLogo: 'Iċċarġja l-Logo',
  storeName: 'Isem tal-Maħżen',
  storeEmail: 'Email tal-Maħżen',
  storeHeadName: 'Isem tal-Kap tal-Maħżen',
  storeHeadIdNumber: 'Numru ID tal-Kap tal-Maħżen',
  idFrontPhoto: 'Foto tal-Id Minna',
  idBackPhoto: 'Foto tal-Id Warajh',
  personalHandheldIdPhoto: 'ID ma\' id',
  storeHeadPhoneNumber: 'Numru tat-Telefon tal-Kap tal-Maħżen',
  storeSupportNumber: 'Numru ta\' Appoġġ tal-Maħżen',
  invitationCode: 'Kodiċi ta\' Stħarriġ',
  login: 'Bidu',
  createAccount: 'Oħloq Kont',
  emailOrPhone: 'Email jew Numru tat-Telefon',
  password: 'Password',
  signIn: 'Bidu',
  yourName: 'Isem Tiegħek',
  reEnterPassword: 'Daħħal il-Password Mill-ġdid',
  emailOrPhoneCode: 'Kodiċi tal-Email jew tat-Telefon',
  request: 'Talba',
  createAccountButton: 'Oħloq Kont',
  privacyPolicy: '《Politika ta\' Privatezza TikTok》',
  acknowledgmentText: 'Billi tikklikkja l-Ħażna, taqbel li qrajt il-{privacyPolicy}. L-informazzjoni tiegħek tista\' tintuża biex personalizza l-esperjenza tiegħek, inklużi l-ads, skont is-settings tiegħek.',
  buy: 'Xiri',
  exploreBargains: 'Esplora Offerti ta\' Kwalità',
  aboutSpotlight: 'Dwar il-Fokus',
  freeShipping: 'Ħlas Bla Ħlas',
  freeShippingDescription: 'Fuq ordnijiet aktar minn $25',
  freeReturns: 'Ħlas Bla Ħlas',
  freeReturnsDescription: 'Filwaqt ta\' 30 jum',
  shopPerformance: 'Prestazzjoni tal-Maħżen',
  betterThan: 'Aħjar minn 80% tal-maħżenijiet l-oħra',
  starRating: '4+ stella valutazzjoni',
  shipsWithin: 'Jispedixxi fi żmien 2 ijiem',
  reviewsCount: '({count} reviżjonijiet)',
  cartEmpty: 'Il-kartell tiegħek huwa vakwu',
  selectAll: 'Agħżel Kollha',
  totalItems: 'Totali ({num} oġġett)',
  checkout: 'Mur għall-Kasa',
  shippingReturnsTitle: 'Ħlas & Ritorn',
  estimatedDelivery: 'Stmata tal-Kunsinna: Jul 11-13, $7.43',
  shippingTo: 'Ħlas għal Denver, 17517',
  shipsOut: 'Normalment jispedixxi fi 1 jum tax-xogħol',
  returnPolicyTitle: 'Politika ta\' Ritorn',
  addToCart: 'Żid mal-Kartell',
  aboutThisProduct: 'Dwar dan il-Prodott',
  shippingAddress: 'Indirizz tal-Kunsinna',
  addAddress: 'Żid Indirizz',
  paymentMethod: 'Metodu ta\' Ħlas',
  shippingMethod: 'Metodu tal-Kunsinna',
  orderSummary: 'Sommarju tal-Ordni',
  subtotal: 'Subtotali',
  shipping: 'Ħlas',
  shippingToBeCalculated: 'se jiġi kkalkulat',
  total: 'Totali ({num} oġġett)',
  firstName: 'Isem',
  lastName: 'Kunjom',
  street: 'Triq',
  apartment: 'Appartament, suite, eċċ. (opzjonali)',
  city: 'Belt',
  state: 'Stat',
  zipCode: 'Kowd ZIP',
  phone: 'Telefon',
  save: 'Ħażna',
  privacyPolicyDescription: 'Billi tikklikkja l-Ħażna, taqbel li qrajt il-{privacyPolicy}. L-informazzjoni tiegħek tista\' tintuża biex personalizza l-esperjenza tiegħek, inklużi l-ads, skont is-settings tiegħek.',
  usCountryCode: 'US+1',
  logout: 'Ħruġ',
  selectShippingMethod: 'Agħżel Metodu tal-Kunsinna',
  standardShipping: "Ħlas Standard: ${amount}",
  estimatedDeliverys: "Stmata tal-Kunsinna: {date}",
  taxes: "Mikxijiet",
  breadcrumbs: {
    classification: 'Klassifikazzjoni',
    subClassification: 'Sub-Klassifikazzjoni',
    selectedProduct: 'Prodott Magħżul',
  },
  downloadApp: 'Niżżel l-App',
  register: 'Reġistra',
  verifiedPurchase: 'Xiri Verifikat',
  pleaseInput: 'Jekk jogħġbok daħħal',
  code: 'Kodiċi',
  effective: 'Effettiv',
  pleaseEnterPasswordAgain: 'Jekk jogħġbok daħħal il-Password Mill-ġdid',
  passwordInconsistent: 'Il-Password li daħħalt darbtejn mhix konsistenti!',
  createdSuccessfully: 'Ġie maħluq b’suċċess',
  addedSuccessfully: 'Żied b’suċċess',
  modifyAddress: 'Ibdel Indirizz',
  modifiedSuccessfully: 'Ibdel b’suċċess',
  copy: 'It-teksti ġie kkopjat għall-clipboard!',
  rechargeSuccessful: 'Ġie pprovat b’suċċess',
  deleteSuccessfully: 'Ġie eliminat b’suċċess',
  paymentSuccessful: 'Il-ħlas kien suċċess',
  payOrder: 'Ħlas tal-Ordni',
  pay: 'Ħlas',
  available: 'Disponibbli',
  enterPaymentPassword: 'Daħħal il-Password ta’ Ħlas 6 ċifri',
  confirmSuccessfulReceiptOfGoods: 'Ċertifika ta’ Riċevuta Sħiħa tal-Beni',
  correct: 'Ċar',
  pleaseUploadInFull: 'Jekk jogħġbok iċċarġja r-ritratti meħtieġa kollha!',
  submittedSuccessfully: 'Ġie sottomess b’suċċess',
  evaluate: 'Evalwa',
  PleaseFillInTheFormCompletely: 'Jekk jogħġbok imla l-formola kompletament!',
  qrCodePlaceholder: 'Kod QR',
  verificationCodeSentSuccessfully: 'Kodiċi ta\' Verifika mibgħut b’suċċess, jekk jogħġbok imla u sottometti fi żmien 60 sekonda!',
  pleaseEnterYourEmailFirst: 'Jekk jogħġbok daħħal l-email tiegħek l-ewwel',
  ContactTheMerchant: "Ikkomunika mal-bejjiegħ",
  inputIssues: 'Daħħal Problemi',
}
export default mt
