const sv = {
  route: {
    home: 'Hem',
    shopCart: 'Kundvagn',
    popularStore: 'Populär Butik',
    storeDetails: 'Butikens Detaljer',
    hotSellingProducts: 'Bästsäljande Produkter',
    productClassification: 'Produktklassificering',
    productDetails: 'Produktdetaljer',
    orderPay: 'Betalning av Beställning',
  },
  home: "Hem",
  wallet: "Plånbok",
  order: "Beställningscenter",
  sell: "Försäljningscenter",
  cart: "Kundvagn",
  search: "Sök",
  deals: "Dagens Erbjudanden",
  featuredStore: "Utvald Butik",
  spotlight: "Fokus",
  hotSale: "Hett Försäljning",
  fashion: "Mode",
  beautyPersonalCare: "Skönhet och Personlig Vård",
  homeKitchen: "Hem och Kök",
  sportsOutdoors: "Sport och Utomhus",
  electronicsAccessories: "Elektronik och Tillbehör",
  seeAll: "Se Alla",
  category: "Kategori",
  beauty: 'Skönhet',
  personalCare: 'Personlig Vård',
  homeFurnishing: 'Hem',
  kitchen: 'Kök',
  sports: 'Sport',
  outdoors: 'Utomhus',
  electronics: 'Elektronik',
  accessories: 'Tillbehör',
  office: 'Kontor',
  products: 'Produkter',
  food: 'Mat',
  drinks: 'Drycker',
  collectibles: 'Samlarobjekt',
  automotive: 'Bil',
  storeOnTikTokShop: 'Butik på TikTok Shop',
  viewShop: 'Visa Butik',
  reviews: 'Recensioner',
  topProducts: 'Topp Produkter',
  newReleases: 'Nya Utgåvor',
  balance: 'Saldo',
  recharge: 'Ladda',
  withdraw: 'Uttag',
  fundAccount: 'Sätt In Pengar',
  add: 'Lägg till',
  addFundAccount: 'Lägg till Fondkonto',
  crypto: 'Krypto',
  mainnet: 'Mainnet',
  address: 'Adress',
  debitCards: 'Bankkort',
  iban: 'IBAN',
  account: 'Konto',
  cardNumber: 'Kortnummer',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount: 'Belopp',
  qrCode: 'QR-kod',
  rechargeAmountUsdt: 'Inbetalningsbelopp (USDT)',
  pleaseEnterTheRechargeAmountUsdt: 'Vänligen ange inbetalningsbelopp (USDT)',
  submitForReview: 'Skicka för Granskning',
  purchased: "Köpt",
  carriage: "Frakt",
  completed: "Slutförd",
  awaitingShipment: 'Väntar på Leverans',
  orderDetails: "Beställningsdetaljer",
  receive: "Ta emot",
  review: 'Recension',
  enterprise: 'Företag',
  personal: 'Personlig',
  businessName: 'Företagsnamn',
  headOfEnterprise: 'Företagschef',
  headPhoneNumber: 'Chefens Telefonnummer',
  headWhatsAppNumber: 'Chefens WhatsApp-nummer',
  submit: 'Skicka',
  uploadLogo: 'Ladda upp Logotyp',
  storeName: 'Butikens Namn',
  storeEmail: 'Butikens E-post',
  storeHeadName: 'Butikens Chefens Namn',
  storeHeadIdNumber: 'Butikens Chefens ID-nummer',
  idFrontPhoto: 'ID Framsida Foto',
  idBackPhoto: 'ID Baksida Foto',
  personalHandheldIdPhoto: 'ID Foto Hållen i Hand',
  storeHeadPhoneNumber: 'Butikens Chefens Telefonnummer',
  storeSupportNumber: 'Butikens Supportnummer',
  invitationCode: 'Inbjudningskod',
  login: 'Logga in',
  createAccount: 'Skapa konto',
  emailOrPhone: 'E-post eller Telefonnummer',
  password: 'Lösenord',
  signIn: 'Logga in',
  yourName: 'Ditt Namn',
  reEnterPassword: 'Skriv in Lösenordet Igen',
  emailOrPhoneCode: 'E-post eller telefonkod',
  request: 'Begär',
  createAccountButton: 'Skapa konto',
  privacyPolicy: '《TikTok Integritetspolicy》',
  acknowledgmentText: 'Genom att klicka på Spara bekräftar du att du har läst {privacyPolicy}. Din information kan användas för att anpassa din upplevelse, inklusive annonser, beroende på dina inställningar.',
  buy: 'Köp',
  exploreBargains: 'Utforska Kvalitetserbjudanden',
  aboutSpotlight: 'Om Spotlight',
  freeShipping: 'Fri Frakt',
  freeShippingDescription: 'Vid beställningar över $25',
  freeReturns: 'Fri Retur',
  freeReturnsDescription: 'Inom 30 dagar',
  shopPerformance: 'Butikens Prestanda',
  betterThan: 'Bättre än 80% av andra butiker',
  starRating: '4+ stjärnor',
  shipsWithin: 'Skeppas inom 2 dagar',
  reviewsCount: '({count} recensioner)',
  cartEmpty: 'Din kundvagn är tom',
  selectAll: 'Välj Alla',
  totalItems: 'Totalt ({num} artiklar)',
  checkout: 'Kassa',
  shippingReturnsTitle: 'Frakt och Returer',
  estimatedDelivery: 'Beräknad leverans 11-13 juli, $7.43',
  shippingTo: 'Frakt till Denver, 17517',
  shipsOut: 'Skeppas vanligtvis inom 1 arbetsdag',
  returnPolicyTitle: 'Returpolicy',
  addToCart: 'Lägg till i kundvagn',
  aboutThisProduct: 'Om denna produkt',
  shippingAddress: 'Fraktadress',
  addAddress: 'Lägg till Adress',
  paymentMethod: 'Betalningsmetod',
  shippingMethod: 'Fraktmetod',
  orderSummary: 'Beställningsöversikt',
  subtotal: 'Delsumma',
  shipping: 'Frakt',
  shippingToBeCalculated: 'att beräknas',
  total: 'Total ({num} artiklar)',
  firstName: 'Förnamn',
  lastName: 'Efternamn',
  street: 'Gata',
  apartment: 'Lägenhet, svit, etc. (valfritt)',
  city: 'Stad',
  state: 'Stat',
  zipCode: 'Postnummer',
  phone: 'Telefon',
  save: 'Spara',
  privacyPolicyDescription: 'Genom att klicka på Spara bekräftar du att du har läst {privacyPolicy}. Din information kan användas för att anpassa din upplevelse, inklusive annonser, beroende på dina inställningar.',
  usCountryCode: 'US+1',
  logout: 'Logga ut',
  selectShippingMethod: 'Välj Fraktmetod',
  standardShipping: "Standardfrakt: ${amount}",
  estimatedDeliverys: "Beräknad Leverans: {date}",
  taxes: "Skatter",
  breadcrumbs: {
    classification: 'Klassificering',
    subClassification: 'Underklassificering',
    selectedProduct: 'Vald Produkt',
  },
  downloadApp: 'Ladda ner Appen',
  register: 'Registrera',
  verifiedPurchase: 'Verifierat Köp',
  pleaseInput: 'Vänligen ange ',
  code: 'Kod',
  effective: 'Giltig',
  pleaseEnterPasswordAgain: 'Vänligen ange ditt lösenord igen',
  passwordInconsistent: 'Det angivna lösenordet stämmer inte överens!',
  createdSuccessfully: 'Skapat Framgångsrikt',
  addedSuccessfully: 'Tillagt Framgångsrikt',
  modifyAddress: 'Ändra Adress',
  modifiedSuccessfully: 'Ändrad Framgångsrikt',
  copy: 'Texten har kopierats till urklipp!',
  rechargeSuccessful: 'Uppladdning Framgångsrik',
  deleteSuccessfully: 'Raderad Framgångsrikt',
  paymentSuccessful: 'Betalning Framgångsrik',
  payOrder: 'Betala Beställning',
  pay: 'Betala',
  available: 'Tillgänglig',
  enterPaymentPassword: 'Ange 6-siffrig Betalningslösenord',
  confirmSuccessfulReceiptOfGoods: 'Bekräfta Framgångsrik Mottagning av Varor',
  correct: 'Korrekt',
  pleaseUploadInFull: 'Vänligen ladda upp alla begärda bilder i sin helhet!',
  submittedSuccessfully: 'Inskickat Framgångsrikt',
  evaluate: 'Utvärdera',
  PleaseFillInTheFormCompletely: 'Vänligen fyll i formuläret helt!',
  qrCodePlaceholder: 'QR-kod',
  verificationCodeSentSuccessfully: 'Verifieringskod skickad framgångsrikt, vänligen ange och skicka inom 60 sekunder.',
  pleaseEnterYourEmailFirst: 'Vänligen ange din e-post först',
  ContactTheMerchant: "Kontakta handlaren",
  inputIssues: 'Skriv in problem',
}
export default sv
