const lv = {
  route: {
    home: 'Sākums',
    shopCart: 'Iepirkumu grozs',
    popularStore: 'Populāra veikals',
    storeDetails: 'Veikala detaļas',
    hotSellingProducts: 'Karstie pārdošanas produkti',
    productClassification: 'Produktu klasifikācija',
    productDetails: 'Produkta detaļas',
    orderPay: 'Pasūtījuma apmaksa',
  },
  home: "Sākums",
  wallet: "Maka",
  order: "Pasūtījumu centrs",
  sell: "Pārdošanas centrs",
  cart: "Grozs",
  search: "Meklēt",
  deals: "Šodienas piedāvājumi",
  featuredStore: "Izcils veikals",
  spotlight: "Izcelšana",
  hotSale: "Karstais pārdošana",
  fashion: "Modes",
  beautyPersonalCare: "Skaistums un personīgā aprūpe",
  homeKitchen: "Mājas un virtuve",
  sportsOutdoors: "Sports un āra aktivitātes",
  electronicsAccessories: "Elektronika un piederumi",
  seeAll: "Skatīt visu",
  category: "Kategorija",
  beauty: 'Skaistums',
  personalCare: 'Personīgā aprūpe',
  homeFurnishing: 'Mājas',
  kitchen: 'Virtuve',
  sports: 'Sports',
  outdoors: 'Āra aktivitātes',
  electronics: 'Elektronika',
  accessories: 'Piederumi',
  office: 'Kancelejas preces',
  products: 'Produkti',
  food: 'Uzturs',
  drinks: 'Dzērieni',
  collectibles: 'Kolekcionējamie priekšmeti',
  automotive: 'Automobiļi',
  storeOnTikTokShop: 'Veikals TikTok Shop',
  viewShop: 'Skatīt veikalu',
  reviews: 'Atsauksmes',
  topProducts: 'Top produkti',
  newReleases: 'Jaunumi',
  balance: 'Bilance',
  recharge: 'Papildināt',
  withdraw: 'Izņemt',
  fundAccount: 'Finansēt kontu',
  add: 'Pievienot',
  addFundAccount: 'Pievienot finanšu kontu',
  crypto: 'Kriptovalūta',
  mainnet: 'Galvenais tīkls',
  address: 'Adrese',
  debitCards: 'Debetkartes',
  iban: 'IBAN',
  account: 'Konts',
  cardNumber: 'Kartes numurs',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount: 'Summa',
  qrCode: 'QR kods',
  rechargeAmountUsdt: 'Papildināšanas summa (USDT)',
  pleaseEnterTheRechargeAmountUsdt: 'Lūdzu, ievadiet papildināšanas summu (USDT)',
  submitForReview: 'Iesniegt pārskatīšanai',
  purchased: "Nopirkts",
  carriage: "Piegāde",
  completed: "Pabeigts",
  awaitingShipment: 'Gaida piegādi',
  orderDetails: "Pasūtījuma detaļas",
  receive: "Saņemt",
  review: 'Atsauksme',
  enterprise: 'Uzņēmums',
  personal: 'Personīgs',
  businessName: 'Uzņēmuma nosaukums',
  headOfEnterprise: 'Uzņēmuma vadītājs',
  headPhoneNumber: 'Vadītāja tālruņa numurs',
  headWhatsAppNumber: 'Vadītāja WhatsApp numurs',
  submit: 'Iesniegt',
  uploadLogo: 'Augšupielādēt logotipu',
  storeName: 'Veikala nosaukums',
  storeEmail: 'Veikala e-pasts',
  storeHeadName: 'Veikala vadītāja vārds',
  storeHeadIdNumber: 'Veikala vadītāja ID numurs',
  idFrontPhoto: 'ID priekšpuses foto',
  idBackPhoto: 'ID aizmugures foto',
  personalHandheldIdPhoto: 'Personīgā rokas ID foto',
  storeHeadPhoneNumber: 'Veikala vadītāja tālruņa numurs',
  storeSupportNumber: 'Veikala atbalsta numurs',
  invitationCode: 'Ielūguma kods',
  login: 'Pierakstīties',
  createAccount: 'Izveidot kontu',
  emailOrPhone: 'E-pasts vai mobilā tālruņa numurs',
  password: 'Parole',
  signIn: 'Pieteikties',
  yourName: 'Jūsu vārds',
  reEnterPassword: 'Ievadiet paroli vēlreiz',
  emailOrPhoneCode: 'E-pasta vai tālruņa kods',
  request: 'Pieprasījums',
  createAccountButton: 'Izveidot kontu',
  privacyPolicy: '《TikTok Privātuma politika》',
  acknowledgmentText: 'Noklikšķinot uz Saglabāt, jūs apstiprināt, ka esat izlasījis {privacyPolicy}. Jūsu informācija var tikt izmantota, lai personalizētu jūsu pieredzi, tostarp reklāmas, atkarībā no jūsu iestatījumiem.',
  buy: 'Pirkt',
  exploreBargains: 'Izpētiet kvalitatīvus piedāvājumus',
  aboutSpotlight: 'Par izcelšanu',
  freeShipping: 'Bezmaksas piegāde',
  freeShippingDescription: 'Pasūtījumiem virs $25',
  freeReturns: 'Bezmaksas atgriešana',
  freeReturnsDescription: 'Līdz 30 dienām',
  shopPerformance: 'Veikala sniegums',
  betterThan: 'Labāks nekā 80% citu veikalu',
  starRating: '4+ zvaigžņu vērtējums',
  shipsWithin: 'Nosūta 2 dienu laikā',
  reviewsCount: '({count} atsauksmes)',
  cartEmpty: 'Jūsu grozs ir tukšs',
  selectAll: 'Izvēlēties visu',
  totalItems: 'Kopā ({num} vienība)',
  checkout: 'Pāriet pie kases',
  shippingReturnsTitle: 'Piegāde un atgriešana',
  estimatedDelivery: 'Ierestimated delivery: Jul 11-13, $7.43',
  shippingTo: 'Piegāde uz Denveru, 17517',
  shipsOut: 'Parasti izsūta 1 darba dienas laikā',
  returnPolicyTitle: 'Atgriešanas politika',
  addToCart: 'Pievienot grozam',
  aboutThisProduct: 'Par šo produktu',
  shippingAddress: 'Piegādes adrese',
  addAddress: 'Pievienot adresi',
  paymentMethod: 'Maksājuma metode',
  shippingMethod: 'Piegādes metode',
  orderSummary: 'Pasūtījuma kopsavilkums',
  subtotal: 'Starpsumma',
  shipping: 'Piegāde',
  shippingToBeCalculated: 'tiks aprēķināta',
  total: 'Kopā ({num} vienība)',
  firstName: 'Vārds',
  lastName: 'Uzvārds',
  street: 'Iela',
  apartment: 'Dzīvoklis, suites, utt. (pēc izvēles)',
  city: 'Pilsēta',
  state: 'Valsts',
  zipCode: 'ZIP kods',
  phone: 'Tālrunis',
  save: 'Saglabāt',
  privacyPolicyDescription: 'Noklikšķinot uz Saglabāt, jūs apstiprināt, ka esat izlasījis {privacyPolicy}. Jūsu informācija var tikt izmantota, lai personalizētu jūsu pieredzi, tostarp reklāmas, atkarībā no jūsu iestatījumiem.',
  usCountryCode: 'US+1',
  logout: 'Izlogoties',
  selectShippingMethod: 'Izvēlēties piegādes metodi',
  standardShipping: "Standarta piegāde: ${amount}",
  estimatedDeliverys: "Ierestimated delivery: {date}",
  taxes: "Nodokļi",
  breadcrumbs: {
    classification: 'Klasifikācija',
    subClassification: 'Apakšklasifikācija',
    selectedProduct: 'Izvēlētais produkts',
  },
  downloadApp: 'Lejupielādēt lietotni',
  register: 'Reģistrēties',
  verifiedPurchase: 'Verificēts pirkums',
  pleaseInput: 'Lūdzu, ievadiet ',
  code: 'Kod',
  effective: 'Efektīvs ',
  pleaseEnterPasswordAgain: 'Lūdzu, ievadiet savu paroli vēlreiz',
  passwordInconsistent: 'Divas reizes ievadītā parole nav vienāda!',
  createdSuccessfully: 'Izveidots veiksmīgi',
  addedSuccessfully: 'Pievienots veiksmīgi',
  modifyAddress: 'Modificēt adresi',
  modifiedSuccessfully: 'Modificēts veiksmīgi',
  copy: 'Teksts ir nokopēts uz starpliktuvi!',
  rechargeSuccessful: 'Papildināšana veiksmīga',
  deleteSuccessfully: 'Dzēsts veiksmīgi',
  paymentSuccessful: 'Maksājums veiksmīgs',
  payOrder: 'Apmaksāt pasūtījumu',
  pay: 'Maksāt',
  available: 'Pieejams',
  enterPaymentPassword: 'Ievadiet 6 ciparu maksājuma paroli',
  confirmSuccessfulReceiptOfGoods: 'Apstipriniet veiksmīgu preču saņemšanu',
  correct: ' Pareizi ',
  pleaseUploadInFull: 'Lūdzu, augšupielādējiet nepieciešamās bildes pilnībā!',
  submittedSuccessfully: 'Iesniegts veiksmīgi',
  evaluate: 'Novērtēt',
  PleaseFillInTheFormCompletely: 'Lūdzu, aizpildiet veidlapu pilnībā!',
  qrCodePlaceholder: 'QR kods',
  verificationCodeSentSuccessfully: 'Verifikācijas kods nosūtīts veiksmīgi, lūdzu, aizpildiet un iesniedziet 60 sekunžu laikā!',
  pleaseEnterYourEmailFirst: 'Lūdzu, ievadiet savu e-pastu vispirms',
  ContactTheMerchant: "Sazinieties ar tirgotāju",
  inputIssues: 'Ievadiet Problēmas',
}
export default lv
