// Opret filen da/index.js
const da = {
  route: {
    home: 'Hjem',
    shopCart: 'Indkøbskurv',
    popularStore: 'Populær Butik',
    storeDetails: 'Butiksdetaljer',
    hotSellingProducts: 'Bestsellere',
    productClassification: 'Produktklassifikation',
    productDetails: 'Produktdetaljer',
    orderPay: 'Bestilling Betaling',
  },
  home: "Hjem",
  wallet: "Pung",
  order: "Ordrecenter",
  sell: "Salgcenter",
  cart: "Kurv",
  search: "Søg",
  deals: "Dagens tilbud",
  featuredStore: "Udvalgt Butik",
  spotlight: "Spotlight",
  hotSale: "Hotsalg",
  fashion: "Mode",
  beautyPersonalCare: "Skønhed & Personlig Pleje",
  homeKitchen: "Hjem & Køkken",
  sportsOutdoors: "Sport & Udendørs",
  electronicsAccessories: "Elektronik & Tilbehør",
  seeAll: "Se alle",
  category: "Kategori",
  beauty: 'Skønhed',
  personalCare: 'Personlig pleje',
  homeFurnishing: 'Hjem',
  kitchen: 'Køkken',
  sports: 'Sport',
  outdoors: 'Udendørs',
  electronics: 'Elektronik',
  accessories: 'Tilbehør',
  office: 'Kontor',
  products: 'Produkter',
  food: 'Mad',
  drinks: 'Drikkevarer',
  collectibles: 'Samleobjekter',
  automotive: 'Biler',
  storeOnTikTokShop:'Butik på TikTok Shop',
  viewShop:'Vis Butik',
  reviews:'Anmeldelser',
  topProducts:'Topprodukter',
  newReleases:'Nye Udgivelser',
  balance: 'Saldo',
  recharge: 'Genoplad',
  withdraw: 'Hæv',
  fundAccount: 'Konto',
  add: 'Tilføj',
  addFundAccount: 'Tilføj Konto',
  crypto: 'Krypto',
  mainnet: 'Hovednet',
  address: 'Adresse',
  debitCards: 'Debetkort',
  iban: 'IBAN',
  account: 'Konto',
  cardNumber: 'Kortnummer',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount:'Beløb',
  qrCode:'QR-kode',
  rechargeAmountUsdt:'Genoplad beløb (USDT)',
  pleaseEnterTheRechargeAmountUsdt:'Indtast venligst genopladningsbeløbet (USDT)',
  submitForReview:'Indsend til gennemsyn',
  purchased: "Købt",
  carriage: "Transport",
  completed: "Fuldført",
  awaitingShipment:'Afventer Forsendelse',
  orderDetails: "Ordre Detaljer",
  receive: "Modtag",
  review:'Anmeldelse',
  enterprise: 'Virksomhed',
  personal:'Personlig',
  businessName: 'Virksomhedsnavn',
  headOfEnterprise: 'Virksomhedschef',
  headPhoneNumber: 'Chefens Telefonnummer',
  headWhatsAppNumber: 'Chefens WhatsApp-nummer',
  submit: 'Indsend',
  uploadLogo: 'Upload Logo',
  storeName: 'Butiksnavn',
  storeEmail: 'Butik Email',
  storeHeadName: 'Butikschefens Navn',
  storeHeadIdNumber: 'Butikschefens ID-nummer',
  idFrontPhoto: 'ID Foran Billede',
  idBackPhoto: 'ID Bagside Billede',
  personalHandheldIdPhoto: 'Personligt Håndholdt ID-billede',
  storeHeadPhoneNumber: 'Butikschefens Telefonnummer',
  storeSupportNumber: 'Butik Support Nummer',
  invitationCode: 'Invitation Kode',
  login: 'Log ind',
  createAccount: 'Opret konto',
  emailOrPhone: 'Email eller telefonnummer',
  password: 'Adgangskode',
  signIn: 'Log ind',
  yourName: 'Dit navn',
  reEnterPassword: 'Indtast adgangskode igen',
  emailOrPhoneCode: 'Email eller telefonkode',
  request: 'Anmodning',
  createAccountButton: 'Opret konto',
  privacyPolicy: '《TikTok Privatlivspolitik》',
  acknowledgmentText: 'Ved at klikke på Gem bekræfter du, at du har læst {privacyPolicy}. Dine oplysninger kan bruges til at personalisere din oplevelse, inklusive annoncer, afhængigt af dine indstillinger.',
  buy: 'Køb',
  exploreBargains: 'Udforsk kvalitetskuponer',
  aboutSpotlight: 'Om Spotlight',
  freeShipping: 'Gratis fragt',
  freeShippingDescription: 'På ordrer over $25',
  freeReturns: 'Gratis returnering',
  freeReturnsDescription: 'Inden for 30 dage',
  shopPerformance: 'Butiksydelse',
  betterThan: 'Bedre end 80% af andre butikker',
  starRating: '4+ stjerner',
  shipsWithin: 'Afsendes inden for 2 dage',
  reviewsCount: '({count} anmeldelser)',
  cartEmpty: 'Din kurv er tom',
  selectAll: 'Vælg alle',
  totalItems: 'I alt ({num} vare)',
  checkout: 'Til kassen',
  shippingReturnsTitle: 'Forsendelse & returnering',
  estimatedDelivery: 'Forventet levering 11-13 juli, $7.43',
  shippingTo: 'Forsendelse til Denver, 17517',
  shipsOut: 'Afsendes normalt inden for 1 hverdag',
  returnPolicyTitle: 'Returneringspolitik',
  addToCart: 'Læg i kurv',
  aboutThisProduct: 'Om dette produkt',
  shippingAddress: 'Leveringsadresse',
  addAddress: 'Tilføj en adresse',
  paymentMethod: 'Betalingsmetode',
  shippingMethod: 'Leveringsmetode',
  orderSummary: 'Ordreoversigt',
  subtotal: 'Subtotal',
  shipping: 'Forsendelse',
  shippingToBeCalculated: 'Skal beregnes',
  total: 'I alt ({num} vare)',
  firstName: 'Fornavn',
  lastName: 'Efternavn',
  street: 'Gade',
  apartment: 'Lejlighed, suite, etc. (valgfrit)',
  city: 'By',
  state: 'Stat',
  zipCode: 'Postnummer',
  phone: 'Telefon',
  save: 'Gem',
  privacyPolicyDescription: 'Ved at klikke på Gem bekræfter du, at du har læst {privacyPolicy}. Dine oplysninger kan bruges til at personalisere din oplevelse, inklusive annoncer, afhængigt af dine indstillinger.',
  usCountryCode: 'US+1',
  logout:'Log ud',
  selectShippingMethod: 'Vælg leveringsmetode',
  standardShipping: "Standardforsendelse: ${amount}",
  estimatedDeliverys: "Forventet levering: {date}",
  taxes: "Skatter",
  breadcrumbs: {
    classification: 'Klassifikation',
    subClassification: 'Underklassifikation',
    selectedProduct: 'Valgt Produkt',
  },
  downloadApp: 'Download App',
  register: 'Registrer',
  verifiedPurchase: 'Bekræftet Køb',
  pleaseInput: 'Indtast venligst ',
  code:'Kode',
  effective:'Effektiv ',
  pleaseEnterPasswordAgain:'Indtast venligst din adgangskode igen',
  passwordInconsistent:'De indtastede adgangskoder stemmer ikke overens!',
  createdSuccessfully:'Oprettet med succes',
  addedSuccessfully:'Tilføjet med succes',
  modifyAddress:'Rediger adresse',
  modifiedSuccessfully:'Ændret med succes',
  copy:'Teksten er kopieret til udklipsholderen!',
  rechargeSuccessful:'Genopladning vellykket',
  deleteSuccessfully:'Slettet med succes',
  paymentSuccessful:'Betaling gennemført',
  payOrder: 'Betal ordre',
  pay: 'Betal',
  available: 'Tilgængelig',
  enterPaymentPassword: 'Indtast 6-cifret betalingskode',
  confirmSuccessfulReceiptOfGoods:'Bekræft vellykket modtagelse af varer',
  correct:' Korrekt ',
  pleaseUploadInFull:'Upload venligst de nødvendige billeder fuldt ud!',
  submittedSuccessfully:'Indsendt med succes',
  evaluate:'Vurder',
  PleaseFillInTheFormCompletely:'Udfyld venligst formularen helt!',
  qrCodePlaceholder:'QR-kode',
  verificationCodeSentSuccessfully:'Verifikationskode sendt med succes, udfyld og indsend inden for 60 sekunder!',
  pleaseEnterYourEmailFirst:'Indtast venligst din email først',
  ContactTheMerchant: "Kontakt sælgeren",
  inputIssues: 'Indtast problemer',
}
export default da
