const es = {
  route: {
    home: 'Inicio',
    shopCart: 'Carrito de Compras',
    popularStore: 'Tienda Popular',
    storeDetails: 'Detalles de la Tienda',
    hotSellingProducts: 'Productos Más Vendidos',
    productClassification: 'Clasificación de Productos',
    productDetails: 'Detalles del Producto',
    orderPay: 'Pago de Pedido',
  },
  home: "Inicio",
  wallet: "Billetera",
  order: "Centro de Pedidos",
  sell: "Centro de Ventas",
  cart: "Carrito",
  search: "Buscar",
  deals: "Ofertas del Día",
  featuredStore: "Tienda Destacada",
  spotlight: "Destacado",
  hotSale: "Venta Caliente",
  fashion: "Moda",
  beautyPersonalCare: "Belleza y Cuidado Personal",
  homeKitchen: "Hogar y Cocina",
  sportsOutdoors: "Deportes y Aire Libre",
  electronicsAccessories: "Electrónica y Accesorios",
  seeAll: "Ver Todo",
  category: "Categoría",
  beauty: 'Belleza',
  personalCare: 'Cuidado Personal',
  homeFurnishing: 'Hogar',
  kitchen: 'Cocina',
  sports: 'Deportes',
  outdoors: 'Aire Libre',
  electronics: 'Electrónica',
  accessories: 'Accesorios',
  office: 'Oficina',
  products: 'Productos',
  food: 'Comida',
  drinks: 'Bebidas',
  collectibles: 'Coleccionables',
  automotive: 'Automotriz',
  storeOnTikTokShop: 'Tienda en TikTok Shop',
  viewShop: 'Ver Tienda',
  reviews: 'Reseñas',
  topProducts: 'Productos Destacados',
  newReleases: 'Nuevos Lanzamientos',
  balance: 'Saldo',
  recharge: 'Recargar',
  withdraw: 'Retirar',
  fundAccount: 'Cargar Cuenta',
  add: 'Agregar',
  addFundAccount: 'Agregar Cuenta de Fondos',
  crypto: 'Cripto',
  mainnet: 'Mainnet',
  address: 'Dirección',
  debitCards: 'Tarjetas de Débito',
  iban: 'IBAN',
  account: 'Cuenta',
  cardNumber: 'Número de Tarjeta',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount: 'Monto',
  qrCode: 'Código QR',
  rechargeAmountUsdt: 'Monto de Recarga (USDT)',
  pleaseEnterTheRechargeAmountUsdt: 'Por favor, ingrese el monto de recarga (USDT)',
  submitForReview: 'Enviar para Revisión',
  purchased: "Comprado",
  carriage: "Transporte",
  completed: "Completado",
  awaitingShipment: 'En Espera de Envío',
  orderDetails: "Detalles del Pedido",
  receive: "Recibir",
  review: 'Revisar',
  enterprise: 'Empresa',
  personal: 'Personal',
  businessName: 'Nombre de la Empresa',
  headOfEnterprise: 'Jefe de la Empresa',
  headPhoneNumber: 'Número de Teléfono del Jefe',
  headWhatsAppNumber: 'Número de WhatsApp del Jefe',
  submit: 'Enviar',
  uploadLogo: 'Subir Logotipo',
  storeName: 'Nombre de la Tienda',
  storeEmail: 'Correo Electrónico de la Tienda',
  storeHeadName: 'Nombre del Encargado de la Tienda',
  storeHeadIdNumber: 'Número de Identificación del Encargado de la Tienda',
  idFrontPhoto: 'Foto Frontal del ID',
  idBackPhoto: 'Foto Trasera del ID',
  personalHandheldIdPhoto: 'Foto del ID Sostenido en Mano',
  storeHeadPhoneNumber: 'Número de Teléfono del Encargado de la Tienda',
  storeSupportNumber: 'Número de Soporte de la Tienda',
  invitationCode: 'Código de Invitación',
  login: 'Iniciar Sesión',
  createAccount: 'Crear Cuenta',
  emailOrPhone: 'Correo Electrónico o Número de Teléfono',
  password: 'Contraseña',
  signIn: 'Iniciar Sesión',
  yourName: 'Tu Nombre',
  reEnterPassword: 'Reingresar Contraseña',
  emailOrPhoneCode: 'Código de Correo Electrónico o Teléfono',
  request: 'Solicitar',
  createAccountButton: 'Crear Cuenta',
  privacyPolicy: '《Política de Privacidad de TikTok》',
  acknowledgmentText: 'Al hacer clic en Guardar, reconoce que ha leído la {privacyPolicy}. Su información puede ser utilizada para personalizar su experiencia, incluidos anuncios, dependiendo de su configuración.',
  buy: 'Comprar',
  exploreBargains: 'Explorar Ofertas de Calidad',
  aboutSpotlight: 'Sobre Spotlight',
  freeShipping: 'Envío Gratis',
  freeShippingDescription: 'En pedidos superiores a $25',
  freeReturns: 'Devoluciones Gratis',
  freeReturnsDescription: 'Dentro de 30 días',
  shopPerformance: 'Rendimiento de la Tienda',
  betterThan: 'Mejor que el 80% de otras tiendas',
  starRating: 'Calificación de 4+ estrellas',
  shipsWithin: 'Envía en 2 días',
  reviewsCount: '({count} reseñas)',
  cartEmpty: 'Tu carrito está vacío',
  selectAll: 'Seleccionar Todo',
  totalItems: 'Total ({num} artículos)',
  checkout: 'Pagar',
  shippingReturnsTitle: 'Envíos y Devoluciones',
  estimatedDelivery: 'Entrega Estimada 11-13 de julio, $7.43',
  shippingTo: 'Envío a Denver, 17517',
  shipsOut: 'Suele enviarse en 1 día hábil',
  returnPolicyTitle: 'Política de Devoluciones',
  addToCart: 'Agregar al Carrito',
  aboutThisProduct: 'Acerca de este Producto',
  shippingAddress: 'Dirección de Envío',
  addAddress: 'Agregar Dirección',
  paymentMethod: 'Método de Pago',
  shippingMethod: 'Método de Envío',
  orderSummary: 'Resumen del Pedido',
  subtotal: 'Subtotal',
  shipping: 'Envío',
  shippingToBeCalculated: 'por calcular',
  total: 'Total ({num} artículos)',
  firstName: 'Nombre',
  lastName: 'Apellido',
  street: 'Calle',
  apartment: 'Apartamento, suite, etc. (opcional)',
  city: 'Ciudad',
  state: 'Estado',
  zipCode: 'Código Postal',
  phone: 'Teléfono',
  save: 'Guardar',
  privacyPolicyDescription: 'Al hacer clic en Guardar, reconoce que ha leído la {privacyPolicy}. Su información puede ser utilizada para personalizar su experiencia, incluidos anuncios, dependiendo de su configuración.',
  usCountryCode: 'US+1',
  logout: 'Cerrar Sesión',
  selectShippingMethod: 'Seleccione el Método de Envío',
  standardShipping: "Envío Estándar: ${amount}",
  estimatedDeliverys: "Entrega Estimada: {date}",
  taxes: "Impuestos",
  breadcrumbs: {
    classification: 'Clasificación',
    subClassification: 'Subclasificación',
    selectedProduct: 'Producto Seleccionado',
  },
  downloadApp: 'Descargar Aplicación',
  register: 'Registrarse',
  verifiedPurchase: 'Compra Verificada',
  pleaseInput: 'Por favor, ingrese ',
  code: 'Código',
  effective: 'Efectivo',
  pleaseEnterPasswordAgain: 'Por favor, reingrese su contraseña',
  passwordInconsistent: '¡Las contraseñas no coinciden!',
  createdSuccessfully: 'Creado con Éxito',
  addedSuccessfully: 'Agregado con Éxito',
  modifyAddress: 'Modificar Dirección',
  modifiedSuccessfully: 'Modificado con Éxito',
  copy: '¡El texto ha sido copiado al portapapeles!',
  rechargeSuccessful: 'Recarga Exitosa',
  deleteSuccessfully: 'Eliminado con Éxito',
  paymentSuccessful: 'Pago Exitoso',
  payOrder: 'Pagar Pedido',
  pay: 'Pagar',
  available: 'Disponible',
  enterPaymentPassword: 'Ingrese Contraseña de Pago de 6 Dígitos',
  confirmSuccessfulReceiptOfGoods: 'Confirmar Recepción Exitosa de Bienes',
  correct: 'Correcto',
  pleaseUploadInFull: '¡Por favor, suba las imágenes requeridas en su totalidad!',
  submittedSuccessfully: 'Enviado con Éxito',
  evaluate: 'Evaluar',
  PleaseFillInTheFormCompletely: '¡Por favor, complete el formulario en su totalidad!',
  qrCodePlaceholder: 'Código QR',
  verificationCodeSentSuccessfully: 'Código de Verificación Enviado con Éxito, por favor ingréselo y envíelo en 60 segundos.',
  pleaseEnterYourEmailFirst: 'Por favor, ingrese primero su correo electrónico',
  ContactTheMerchant: "Contactar con el comerciante",
  inputIssues: 'Introducir problemas',
}

export default es
