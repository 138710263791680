// 创建文件de/index.js
const de = {
  route: {
    home: 'Startseite',
    shopCart: 'Warenkorb',
    popularStore: 'Beliebter Laden',
    storeDetails: 'Ladendetails',
    hotSellingProducts: 'Bestseller-Produkte',
    productClassification: 'Produktklassifizierung',
    productDetails: 'Produktdetails',
    orderPay: 'Bestellung bezahlen',
  },
  home: "Startseite",
  wallet: "Geldbörse",
  order: "Bestellzentrum",
  sell: "Verkaufszentrum",
  cart: "Warenkorb",
  search: "Suche",
  deals: "Angebote des Tages",
  featuredStore: "Empfohlener Laden",
  spotlight: "Im Rampenlicht",
  hotSale: "Heiße Verkäufe",
  fashion: "Mode",
  beautyPersonalCare: "Schönheit & Körperpflege",
  homeKitchen: "Haushalt & Küche",
  sportsOutdoors: "Sport & Outdoor",
  electronicsAccessories: "Elektronik & Zubehör",
  seeAll: "Alle ansehen",
  category: "Kategorie",
  beauty: 'Schönheit',
  personalCare: 'Körperpflege',
  homeFurnishing: 'Einrichtung',
  kitchen: 'Küche',
  sports: 'Sport',
  outdoors: 'Outdoor',
  electronics: 'Elektronik',
  accessories: 'Zubehör',
  office: 'Büro',
  products: 'Produkte',
  food: 'Lebensmittel',
  drinks: 'Getränke',
  collectibles: 'Sammlerstücke',
  automotive: 'Automobil',
  storeOnTikTokShop:'Geschäft im TikTok Shop',
  viewShop:'Shop ansehen',
  reviews:'Bewertungen',
  topProducts:'Top-Produkte',
  newReleases:'Neuerscheinungen',
  balance: 'Guthaben',
  recharge: 'Aufladen',
  withdraw: 'Abheben',
  fundAccount: 'Konto aufladen',
  add: 'Hinzufügen',
  addFundAccount: 'Konto hinzufügen',
  crypto: 'Krypto',
  mainnet: 'Mainnet',
  address: 'Adresse',
  debitCards: 'Debitkarten',
  iban: 'IBAN',
  account: 'Konto',
  cardNumber: 'Kartennummer',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount:'Betrag',
  qrCode:'QR-Code',
  rechargeAmountUsdt:'Aufladebetrag (USDT)',
  pleaseEnterTheRechargeAmountUsdt:'Bitte geben Sie den Aufladebetrag (USDT) ein',
  submitForReview:'Zur Überprüfung einreichen',
  purchased: "Gekauft",
  carriage: "Transport",
  completed: "Abgeschlossen",
  awaitingShipment:'Warten auf Versand',
  orderDetails: "Bestelldetails",
  receive: "Erhalten",
  review:'Bewertung',
  enterprise: 'Unternehmen',
  personal:'Persönlich',
  businessName: 'Firmenname',
  headOfEnterprise: 'Unternehmensleiter',
  headPhoneNumber: 'Telefonnummer des Leiters',
  headWhatsAppNumber: 'WhatsApp-Nummer des Leiters',
  submit: 'Absenden',
  uploadLogo: 'Logo hochladen',
  storeName: 'Geschäftsname',
  storeEmail: 'E-Mail des Geschäfts',
  storeHeadName: 'Name des Geschäftsleiters',
  storeHeadIdNumber: 'Ausweisnummer des Geschäftsleiters',
  idFrontPhoto: 'Vorderseite des Ausweises',
  idBackPhoto: 'Rückseite des Ausweises',
  personalHandheldIdPhoto: 'Persönliches Handgehaltenes Ausweisfoto',
  storeHeadPhoneNumber: 'Telefonnummer des Geschäftsleiters',
  storeSupportNumber: 'Support-Nummer des Geschäfts',
  invitationCode: 'Einladungscode',
  login: 'Anmelden',
  createAccount: 'Konto erstellen',
  emailOrPhone: 'E-Mail oder Telefonnummer',
  password: 'Passwort',
  signIn: 'Einloggen',
  yourName: 'Ihr Name',
  reEnterPassword: 'Passwort erneut eingeben',
  emailOrPhoneCode: 'E-Mail- oder Telefoncode',
  request: 'Anfordern',
  createAccountButton: 'Konto erstellen',
  privacyPolicy: '《TikTok-Datenschutzrichtlinie》',
  acknowledgmentText: 'Mit dem Klicken auf Speichern bestätigen Sie, dass Sie die {privacyPolicy} gelesen haben. Ihre Informationen können verwendet werden, um Ihr Erlebnis, einschließlich Anzeigen, zu personalisieren, je nach Ihren Einstellungen.',
  buy: 'Kaufen',
  exploreBargains: 'Entdecken Sie hochwertige Angebote',
  aboutSpotlight: 'Über Spotlight',
  freeShipping: 'Kostenloser Versand',
  freeShippingDescription: 'Bei Bestellungen über 25 $',
  freeReturns: 'Kostenlose Rücksendungen',
  freeReturnsDescription: 'Innerhalb von 30 Tagen',
  shopPerformance: 'Leistung des Shops',
  betterThan: 'Besser als 80 % der anderen Geschäfte',
  starRating: '4+ Sterne Bewertung',
  shipsWithin: 'Versand innerhalb von 2 Tagen',
  reviewsCount: '({count} Bewertungen)',
  cartEmpty: 'Ihr Warenkorb ist leer',
  selectAll: 'Alle auswählen',
  totalItems: 'Insgesamt ({num} Artikel)',
  checkout: 'Zur Kasse',
  shippingReturnsTitle: 'Versand & Rücksendungen',
  estimatedDelivery: 'Voraussichtliche Lieferung 11.-13. Juli, 7,43 $',
  shippingTo: 'Versand nach Denver, 17517',
  shipsOut: 'Versand normalerweise innerhalb eines Werktages',
  returnPolicyTitle: 'Rückgaberichtlinie',
  addToCart: 'In den Warenkorb',
  aboutThisProduct: 'Über dieses Produkt',
  shippingAddress: 'Lieferadresse',
  addAddress: 'Adresse hinzufügen',
  paymentMethod: 'Zahlungsmethode',
  shippingMethod: 'Versandart',
  orderSummary: 'Bestellübersicht',
  subtotal: 'Zwischensumme',
  shipping: 'Versand',
  shippingToBeCalculated: 'wird berechnet',
  total: 'Gesamt ({num} Artikel)',
  firstName: 'Vorname',
  lastName: 'Nachname',
  street: 'Straße',
  apartment: 'Wohnung, Suite usw. (optional)',
  city: 'Stadt',
  state: 'Bundesland',
  zipCode: 'Postleitzahl',
  phone: 'Telefon',
  save: 'Speichern',
  privacyPolicyDescription: 'Mit dem Klicken auf Speichern bestätigen Sie, dass Sie die {privacyPolicy} gelesen haben. Ihre Informationen können verwendet werden, um Ihr Erlebnis, einschließlich Anzeigen, zu personalisieren, je nach Ihren Einstellungen.',
  usCountryCode: 'US+1',
  logout:'Abmelden',
  selectShippingMethod: 'Versandmethode auswählen',
  standardShipping: "Standardversand: ${amount}",
  estimatedDeliverys: "Voraussichtliche Lieferung: {date}",
  taxes: "Steuern",
  breadcrumbs: {
    classification: 'Klassifizierung',
    subClassification: 'Unterkategorisierung',
    selectedProduct: 'Ausgewähltes Produkt',
  },
  downloadApp: 'App herunterladen',
  register: 'Registrieren',
  verifiedPurchase: 'Verifizierter Kauf',
  pleaseInput: 'Bitte eingeben ',
  code:'Code',
  effective:'Wirksam ',
  pleaseEnterPasswordAgain:'Bitte geben Sie Ihr Passwort erneut ein',
  passwordInconsistent:'Das eingegebene Passwort stimmt nicht überein!',
  createdSuccessfully:'Erfolgreich erstellt',
  addedSuccessfully:'Erfolgreich hinzugefügt',
  modifyAddress:'Adresse ändern',
  modifiedSuccessfully:'Erfolgreich geändert',
  copy:'Der Text wurde in die Zwischenablage kopiert!',
  rechargeSuccessful:'Aufladung erfolgreich',
  deleteSuccessfully:'Erfolgreich gelöscht',
  paymentSuccessful:'Zahlung erfolgreich',
  payOrder: 'Bestellung bezahlen',
  pay: 'Bezahlen',
  available: 'Verfügbar',
  enterPaymentPassword: '6-stelliges Zahlungspasswort eingeben',
  confirmSuccessfulReceiptOfGoods:'Erfolgreichen Erhalt der Waren bestätigen',
  correct:' Korrekt ',
  pleaseUploadInFull:'Bitte laden Sie die erforderlichen Bilder vollständig hoch!',
  submittedSuccessfully:'Erfolgreich eingereicht',
  evaluate:'Bewerten',
  PleaseFillInTheFormCompletely:'Bitte füllen Sie das Formular vollständig aus!',
  qrCodePlaceholder:'QR-Code',
  verificationCodeSentSuccessfully:'Verifizierungscode erfolgreich gesendet, bitte innerhalb von 60 Sekunden ausfüllen und absenden!',
  pleaseEnterYourEmailFirst:'Bitte geben Sie zuerst Ihre E-Mail-Adresse ein',
  ContactTheMerchant: "Kontaktieren Sie den Händler",
  inputIssues: 'Probleme eingeben',
}
export default de
