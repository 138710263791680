// 创建文件en/index.js
const en = {
  route: {
    home: 'Home',
    shopCart: 'Shopping Cart',
    popularStore: 'Popular Store',
    storeDetails: 'Store Details',
    hotSellingProducts: 'Hot Selling Products',
    productClassification: 'Product Classification',
    productDetails: 'Product Details',
    orderPay: 'Order Pay',
  },
  home: "Home",
  wallet: "Wallet",
  order: "Order Center",
  sell: "Sell Center",
  cart: "Cart",
  search: "Search",
  deals: "Today's deals",
  featuredStore: "Featured Store",
  spotlight: "Spotlight",
  hotSale: "Hot Sale",
  fashion: "Fashion",
  beautyPersonalCare: "Beauty & Personal Care",
  homeKitchen: "Home & Kitchen",
  sportsOutdoors: "Sports & Outdoors",
  electronicsAccessories: "Electronics & Accessories",
  seeAll: "See All",
  category: "Category",
  beauty: 'Beauty',
  personalCare: 'Personal Care',
  homeFurnishing: 'Home',
  kitchen: 'Kitchen',
  sports: 'Sports',
  outdoors: 'Outdoors',
  electronics: 'Electronics',
  accessories: 'Accessories',
  office: 'Office',
  products: 'Products',
  food: 'Food',
  drinks: 'Drinks',
  collectibles: 'Collectibles',
  automotive: 'Automotive',
  storeOnTikTokShop:'Store on TikTok Shop',
  viewShop:'View Shop',
  reviews:'Reviews',
  topProducts:'Top products',
  newReleases:'New releases',
  balance: 'Balance',
  recharge: 'Recharge',
  withdraw: 'Withdraw',
  fundAccount: 'Fund Account',
  add: 'Add',
  addFundAccount: 'Add Fund Account',
  crypto: 'Crypto',
  mainnet: 'Mainnet',
  address: 'Address',
  debitCards: 'Debit Cards',
  iban: 'IBAN',
  account: 'Account',
  cardNumber: 'Card Number',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount:'Amount',
  qrCode:'QR code',
  rechargeAmountUsdt:'Recharge amount (USDT)',
  pleaseEnterTheRechargeAmountUsdt:'Please enter the recharge amount (USDT)',
  submitForReview:'Submit for review',
  purchased: "Purchased",
  carriage: "Carriage",
  completed: "Completed",
  awaitingShipment:'Awaiting Shipment',
  orderDetails: "Order Details",
  receive: "Receive",
  review:'Review',
  enterprise: 'Enterprise',
  personal:'Personal',
  businessName: 'Business Name',
  headOfEnterprise: 'Head of Enterprise',
  headPhoneNumber: 'Head Phone Number',
  headWhatsAppNumber: 'Head WhatsApp Number',
  submit: 'Submit',
  uploadLogo: 'Upload Logo',
  storeName: 'Store Name',
  storeEmail: 'Store E-mail',
  storeHeadName: 'Store Head Name',
  storeHeadIdNumber: 'Store Head ID Number',
  idFrontPhoto: 'ID Front Photo',
  idBackPhoto: 'ID Back Photo',
  personalHandheldIdPhoto: 'Personal hand-held IDphoto',
  storeHeadPhoneNumber: 'Store Head Phone Number',
  storeSupportNumber: 'Store Support Number',
  invitationCode: 'Invitation Code',
  login: 'Log in',
  createAccount: 'Create account',
  emailOrPhone: 'Email or mobile phone number',
  password: 'Password',
  signIn: 'Sign in',
  yourName: 'Your name',
  reEnterPassword: 'Re-enter password',
  emailOrPhoneCode: 'Email or phone code',
  request: 'Request',
  createAccountButton: 'Create account',
  privacyPolicy: '《TikTok Privacy Policy》',
  acknowledgmentText: 'By clicking Save, you acknowledge that you have read the {privacyPolicy}. Your information may be used to personalize your experience, including ads, depending on your settings.',
  buy: 'Buy',
  exploreBargains: 'Explore quality bargains',
  aboutSpotlight: 'About Spotlight',
  freeShipping: 'Free shipping',
  freeShippingDescription: 'On orders over $25',
  freeReturns: 'Free returns',
  freeReturnsDescription: 'Within 30 days',
  shopPerformance: 'Shop performance',
  betterThan: 'Better than 80% of other shops',
  starRating: '4+ star rating',
  shipsWithin: 'Ships within 2 days',
  reviewsCount: '({count} reviews)',
  cartEmpty: 'Your cart is empty',
  selectAll: 'Select all',
  totalItems: 'Total ({num} item)',
  checkout: 'Checkout',
  shippingReturnsTitle: 'Shipping & returns',
  estimatedDelivery: 'Estimated delivery Jul 11-13, $7.43',
  shippingTo: 'Shipping to Denver, 17517',
  shipsOut: 'Usually ships out in 1 business day',
  returnPolicyTitle: 'Return policy',
  addToCart: 'Add to cart',
  aboutThisProduct: 'About this product',
  shippingAddress: 'Shipping address',
  addAddress: 'Add an address',
  paymentMethod: 'Payment method',
  shippingMethod: 'Shipping method',
  orderSummary: 'Order summary',
  subtotal: 'Subtotal',
  shipping: 'Shipping',
  shippingToBeCalculated: 'to be calculated',
  total: 'Total ({num} item)',
  firstName: 'First name',
  lastName: 'Last name',
  street: 'Street',
  apartment: 'Apartment, suite, etc. (optional)',
  city: 'City',
  state: 'State',
  zipCode: 'ZIP code',
  phone: 'Phone',
  save: 'Save',
  privacyPolicyDescription: 'By clicking Save, you acknowledge that you have read the {privacyPolicy}. Your information may be used to personalize your experience, including ads, depending on your settings.',
  usCountryCode: 'US+1',
  logout:'Log Out',
  selectShippingMethod: 'Select Shipping Method',
  standardShipping: "Standard Shipping: ${amount}",
  estimatedDeliverys: "Estimated Delivery: {date}",
  taxes: "Taxes",
  breadcrumbs: {
    classification: 'Classification',
    subClassification: 'Subclassification',
    selectedProduct: 'Selected Product',
  },
  downloadApp: 'Download App',
  register: 'Register',
  verifiedPurchase: 'Verified Purchase',
  pleaseInput: 'Please Input ',
  code:'Code',
  effective:'Effective ',
  pleaseEnterPasswordAgain:'Please Enter Your Password Again',
  passwordInconsistent:'The Password Entered Twice Is Inconsistent!',
  createdSuccessfully:'Created Successfully',
  addedSuccessfully:'Added Successfully',
  modifyAddress:'Modify Address',
  modifiedSuccessfully:'Modified Successfully',
  copy:'The Text Has Been Copied To The Clipboard!',
  rechargeSuccessful:'Recharge Successful',
  deleteSuccessfully:'Delete Successfully',
  paymentSuccessful:'Payment Successful',
  payOrder: 'Pay Order',
  pay: 'Pay',
  available: 'Available',
  enterPaymentPassword: 'Enter 6-digit Payment Password',
  confirmSuccessfulReceiptOfGoods:'Confirm Successful Receipt Of Goods',
  correct:' Correct ',
  pleaseUploadInFull:'Please Upload The Required Images In Full !',
  submittedSuccessfully:'Submitted Successfully',
  evaluate:'Evaluate',
  PleaseFillInTheFormCompletely:'Please Fill In The Form Completely !',
  qrCodePlaceholder:'QR Code',
  verificationCodeSentSuccessfully:'Verification Code Sent Successfully, Please Fill In And Submit Within 60 Seconds!',
  pleaseEnterYourEmailFirst:'Please Enter Your Email First',
  ContactTheMerchant:'Contact The Merchant',
  inputIssues:'Input Issues',
}
export default en
