// 创建文件hu/index.js
const hu = {
  route: {
    home: 'Főoldal',
    shopCart: 'Kosár',
    popularStore: 'Népszerű Bolt',
    storeDetails: 'Bolt Részletei',
    hotSellingProducts: 'Forró Termékek',
    productClassification: 'Termék Kategória',
    productDetails: 'Termék Részletei',
    orderPay: 'Rendelés Fizetés',
  },
  home: "Főoldal",
  wallet: "Pénztárca",
  order: "Rendelés Központ",
  sell: "Eladási Központ",
  cart: "Kosár",
  search: "Keresés",
  deals: "Mai Akciók",
  featuredStore: "Kiemelt Bolt",
  spotlight: "Fókuszban",
  hotSale: "Forró Akciók",
  fashion: "Divat",
  beautyPersonalCare: "Szépség és Személyes Ápolás",
  homeKitchen: "Otthon és Konyha",
  sportsOutdoors: "Sport és Szabadtéri",
  electronicsAccessories: "Elektronika és Tartozékok",
  seeAll: "Összes Megtekintése",
  category: "Kategória",
  beauty: 'Szépség',
  personalCare: 'Személyes Ápolás',
  homeFurnishing: 'Otthon',
  kitchen: 'Konyha',
  sports: 'Sport',
  outdoors: 'Szabadtéri',
  electronics: 'Elektronika',
  accessories: 'Tartozékok',
  office: 'Iroda',
  products: 'Termékek',
  food: 'Étel',
  drinks: 'Italok',
  collectibles: 'Gyűjtemények',
  automotive: 'Autóipar',
  storeOnTikTokShop:'Bolt a TikTok Shop-on',
  viewShop:'Bolt Megtekintése',
  reviews:'Vélemények',
  topProducts:'Legjobb Termékek',
  newReleases:'Újdonságok',
  balance: 'Egyenleg',
  recharge: 'Töltés',
  withdraw: 'Kivonás',
  fundAccount: 'Pénzalap Számla',
  add: 'Hozzáadás',
  addFundAccount: 'Pénzalap Számla Hozzáadása',
  crypto: 'Kriptovaluta',
  mainnet: 'Főhálózat',
  address: 'Cím',
  debitCards: 'Betéti Kártyák',
  iban: 'IBAN',
  account: 'Számla',
  cardNumber: 'Kártyaszám',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount:'Összeg',
  qrCode:'QR Kód',
  rechargeAmountUsdt:'Töltési Összeg (USDT)',
  pleaseEnterTheRechargeAmountUsdt:'Kérjük, adja meg a töltési összeget (USDT)',
  submitForReview:'Benyújtás Ellenőrzésre',
  purchased: "Megvásárolva",
  carriage: "Szállítás",
  completed: "Befejezve",
  awaitingShipment:'Várakozás Szállításra',
  orderDetails: "Rendelés Részletei",
  receive: "Átvétel",
  review:'Vélemény',
  enterprise: 'Vállalat',
  personal:'Személyes',
  businessName: 'Vállalat Neve',
  headOfEnterprise: 'Vállalat Vezetője',
  headPhoneNumber: 'Vezető Telefon Száma',
  headWhatsAppNumber: 'Vezető WhatsApp Száma',
  submit: 'Benyújtás',
  uploadLogo: 'Logó Feltöltése',
  storeName: 'Bolt Neve',
  storeEmail: 'Bolt Email',
  storeHeadName: 'Bolt Vezető Neve',
  storeHeadIdNumber: 'Bolt Vezető Személyi Igazolvány Száma',
  idFrontPhoto: 'Személyi Igazolvány Elülső Oldal Fotója',
  idBackPhoto: 'Személyi Igazolvány Hátsó Oldal Fotója',
  personalHandheldIdPhoto: 'Személyi Kézben Tartott Igazolvány Fotója',
  storeHeadPhoneNumber: 'Bolt Vezető Telefon Száma',
  storeSupportNumber: 'Bolt Támogatási Száma',
  invitationCode: 'Meghívó Kód',
  login: 'Bejelentkezés',
  createAccount: 'Fiók Létrehozása',
  emailOrPhone: 'Email vagy Mobiltelefon Szám',
  password: 'Jelszó',
  signIn: 'Bejelentkezés',
  yourName: 'Neved',
  reEnterPassword: 'Jelszó Újra Beírása',
  emailOrPhoneCode: 'Email vagy Telefonszám Kód',
  request: 'Kérés',
  createAccountButton: 'Fiók Létrehozása',
  privacyPolicy: '《TikTok Adatvédelmi Szabályzat》',
  acknowledgmentText: 'A Mentés gombra kattintva elismeri, hogy elolvasta a {privacyPolicy}-t. Az adatait felhasználhatjuk a személyre szabott élmény biztosítására, beleértve a hirdetéseket is, a beállításai alapján.',
  buy: 'Vásárlás',
  exploreBargains: 'Kiváló Akciók Felfedezése',
  aboutSpotlight: 'A Fókuszban Rólunk',
  freeShipping: 'Ingyenes Szállítás',
  freeShippingDescription: '25 USD feletti rendelés esetén',
  freeReturns: 'Ingyenes Visszaküldés',
  freeReturnsDescription: '30 napon belül',
  shopPerformance: 'Bolt Teljesítménye',
  betterThan: 'Jobb, mint a boltok 80%-a',
  starRating: '4+ csillagos értékelés',
  shipsWithin: '2 napon belül szállítjuk',
  reviewsCount: '({count} vélemény)',
  cartEmpty: 'A kosarad üres',
  selectAll: 'Összes Kiválasztása',
  totalItems: 'Összesen ({num} termék)',
  checkout: 'Fizetés',
  shippingReturnsTitle: 'Szállítás és Visszaküldés',
  estimatedDelivery: 'Becsült Kiszállítás: Július 11-13, $7.43',
  shippingTo: 'Szállítás ide: Denver, 17517',
  shipsOut: 'Általában 1 munkanapon belül szállítjuk',
  returnPolicyTitle: 'Visszaküldési Politika',
  addToCart: 'Kosárba Tétel',
  aboutThisProduct: 'A Termékről',
  shippingAddress: 'Szállítási Cím',
  addAddress: 'Cím Hozzáadása',
  paymentMethod: 'Fizetési Mód',
  shippingMethod: 'Szállítási Mód',
  orderSummary: 'Rendelés Összefoglaló',
  subtotal: 'Részösszeg',
  shipping: 'Szállítás',
  shippingToBeCalculated: 'kiszámítandó',
  total: 'Összesen ({num} termék)',
  firstName: 'Keresztnév',
  lastName: 'Vezetéknév',
  street: 'Utca',
  apartment: 'Lakás, iroda, stb. (opcionális)',
  city: 'Város',
  state: 'Állam',
  zipCode: 'Irányítószám',
  phone: 'Telefon',
  save: 'Mentés',
  privacyPolicyDescription: 'A Mentés gombra kattintva elismeri, hogy elolvasta a {privacyPolicy}-t. Az adatait felhasználhatjuk a személyre szabott élmény biztosítására, beleértve a hirdetéseket is, a beállításai alapján.',
  usCountryCode: 'US+1',
  logout:'Kijelentkezés',
  selectShippingMethod: 'Szállítási Mód Kiválasztása',
  standardShipping: "Standard Szállítás: ${amount}",
  estimatedDeliverys: "Becsült Szállítási Idő: {date}",
  taxes: "Adók",
  breadcrumbs: {
    classification: 'Kategória',
    subClassification: 'Alkategória',
    selectedProduct: 'Kiválasztott Termék',
  },
  downloadApp: 'Alkalmazás Letöltése',
  register: 'Regisztráció',
  verifiedPurchase: 'Ellenőrzött Vásárlás',
  pleaseInput: 'Kérjük, adja meg a ',
  code:'kód',
  effective:'hatékony ',
  pleaseEnterPasswordAgain:'Kérjük, írja be újra a jelszót',
  passwordInconsistent:'A két jelszó nem egyezik!',
  createdSuccessfully:'Sikeresen létrehozva',
  addedSuccessfully:'Sikeresen hozzáadva',
  modifyAddress:'Cím Módosítása',
  modifiedSuccessfully:'Sikeresen módosítva',
  copy:'Szöveg a vágólapra másolva!',
  rechargeSuccessful:'Töltés Sikeres',
  deleteSuccessfully:'Sikeres Törlés',
  paymentSuccessful:'Fizetés Sikeres',
  payOrder: 'Rendelés Fizetése',
  pay: 'Fizetés',
  available: 'Elérhető',
  enterPaymentPassword: 'Írja be a 6 számjegyű fizetési jelszót',
  confirmSuccessfulReceiptOfGoods:'Sikeres Átvétel Megerősítése',
  correct:' Helyes ',
  pleaseUploadInFull:'Kérjük, teljesen töltse fel a szükséges képeket!',
  submittedSuccessfully:'Sikeresen benyújtva',
  evaluate:'Értékelés',
  PleaseFillInTheFormCompletely:'Kérjük, töltse ki teljesen a űrlapot!',
  qrCodePlaceholder:'QR Kód',
  verificationCodeSentSuccessfully:'A megerősítő kód sikeresen elküldve, kérjük, töltse ki és nyújtsa be 60 másodpercen belül!',
  pleaseEnterYourEmailFirst:'Kérjük, először adja meg az e-mail címét',
  ContactTheMerchant: "Lépjen kapcsolatba az eladóval",
  inputIssues: 'Problémák megadása',
}
export default hu
