// 创建文件zh-TW/index.js
const zhs = {
  route: {
    home: '首頁',
    shopCart: '購物車',
    popularStore: '熱門商店',
    storeDetails: '商店詳情',
    hotSellingProducts: '熱銷商品',
    productClassification: '商品分類',
    productDetails: '商品詳情',
    orderPay: '訂單支付',
  },
  home: "首頁",
  wallet: "錢包",
  order: "訂單中心",
  sell: "銷售中心",
  cart: "購物車",
  search: "搜索",
  deals: "今日優惠",
  featuredStore: "精選商店",
  spotlight: "聚焦",
  hotSale: "熱賣",
  fashion: "時尚",
  beautyPersonalCare: "美容與個人護理",
  homeKitchen: "家居與廚房",
  sportsOutdoors: "運動與戶外",
  electronicsAccessories: "電子與配件",
  seeAll: "查看全部",
  category: "分類",
  beauty: '美容',
  personalCare: '個人護理',
  homeFurnishing: '家居',
  kitchen: '廚房',
  sports: '運動',
  outdoors: '戶外',
  electronics: '電子產品',
  accessories: '配件',
  office: '辦公室',
  products: '產品',
  food: '食品',
  drinks: '飲料',
  collectibles: '收藏品',
  automotive: '汽車',
  storeOnTikTokShop:'TikTok 商店',
  viewShop:'查看商店',
  reviews:'評論',
  topProducts:'頂級產品',
  newReleases:'新品上市',
  balance: '餘額',
  recharge: '充值',
  withdraw: '提現',
  fundAccount: '資金賬戶',
  add: '添加',
  addFundAccount: '添加資金賬戶',
  crypto: '加密貨幣',
  mainnet: '主網',
  address: '地址',
  debitCards: '借記卡',
  iban: 'IBAN',
  account: '賬戶',
  cardNumber: '卡號',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: '匯豐銀行',
  ubs: '瑞銀',
  bnp: '法國巴黎銀行',
  amount:'金額',
  qrCode:'二維碼',
  rechargeAmountUsdt:'充值金額（USDT）',
  pleaseEnterTheRechargeAmountUsdt:'請輸入充值金額（USDT）',
  submitForReview:'提交審核',
  purchased: "已購買",
  carriage: "運輸",
  completed: "已完成",
  awaitingShipment:'等待發貨',
  orderDetails: "訂單詳情",
  receive: "收貨",
  review:'評論',
  enterprise: '企業',
  personal:'個人',
  businessName: '企業名稱',
  headOfEnterprise: '企業負責人',
  headPhoneNumber: '負責人電話',
  headWhatsAppNumber: '負責人 WhatsApp 號碼',
  submit: '提交',
  uploadLogo: '上傳標誌',
  storeName: '商店名稱',
  storeEmail: '商店郵箱',
  storeHeadName: '商店負責人名稱',
  storeHeadIdNumber: '商店負責人身份證號',
  idFrontPhoto: '身份證正面照片',
  idBackPhoto: '身份證背面照片',
  personalHandheldIdPhoto: '個人手持身份證照片',
  storeHeadPhoneNumber: '商店負責人電話',
  storeSupportNumber: '商店支持電話',
  invitationCode: '邀請碼',
  login: '登錄',
  createAccount: '創建賬戶',
  emailOrPhone: '郵箱或手機號碼',
  password: '密碼',
  signIn: '登錄',
  yourName: '您的名字',
  reEnterPassword: '再次輸入密碼',
  emailOrPhoneCode: '郵箱或手機驗證碼',
  request: '請求',
  createAccountButton: '創建賬戶',
  privacyPolicy: '《TikTok 隱私政策》',
  acknowledgmentText: '點擊保存即表示您已閱讀 {privacyPolicy}。您的信息可能會用於個性化您的體驗，包括廣告，具體取決於您的設置。',
  buy: '購買',
  exploreBargains: '探索優質特價品',
  aboutSpotlight: '關於聚焦',
  freeShipping: '免費送貨',
  freeShippingDescription: '訂單滿 $25 免運費',
  freeReturns: '免費退貨',
  freeReturnsDescription: '30 天內退貨免費',
  shopPerformance: '商店表現',
  betterThan: '優於 80% 的其他商店',
  starRating: '4 星以上評級',
  shipsWithin: '2 天內發貨',
  reviewsCount: '（{count} 條評論）',
  cartEmpty: '您的購物車是空的',
  selectAll: '全選',
  totalItems: '總計（{num} 件商品）',
  checkout: '結賬',
  shippingReturnsTitle: '送貨與退貨',
  estimatedDelivery: '預計交貨時間 7 月 11-13 日，$7.43',
  shippingTo: '送貨到丹佛，17517',
  shipsOut: '通常在 1 個工作日內發貨',
  returnPolicyTitle: '退貨政策',
  addToCart: '加入購物車',
  aboutThisProduct: '關於此產品',
  shippingAddress: '送貨地址',
  addAddress: '添加地址',
  paymentMethod: '付款方式',
  shippingMethod: '送貨方式',
  orderSummary: '訂單摘要',
  subtotal: '小計',
  shipping: '運費',
  shippingToBeCalculated: '待計算',
  total: '總計（{num} 件商品）',
  firstName: '名字',
  lastName: '姓氏',
  street: '街道',
  apartment: '公寓、套房等（可選）',
  city: '城市',
  state: '州',
  zipCode: '郵政編碼',
  phone: '電話',
  save: '保存',
  privacyPolicyDescription: '點擊保存即表示您已閱讀 {privacyPolicy}。您的信息可能會用於個性化您的體驗，包括廣告，具體取決於您的設置。',
  usCountryCode: '美國+1',
  logout:'登出',
  selectShippingMethod: '選擇送貨方式',
  standardShipping: "標準運送：${amount}",
  estimatedDeliverys: "預計送達時間：{date}",
  taxes: "稅金",
  breadcrumbs: {
    classification: '分類',
    subClassification: '子分類',
    selectedProduct: '已選擇產品',
  },
  downloadApp: '下載應用',
  register: '註冊',
  verifiedPurchase: '已驗證購買',
  pleaseInput: '請輸入 ',
  code:'代碼',
  effective:'有效 ',
  pleaseEnterPasswordAgain:'請再次輸入您的密碼',
  passwordInconsistent:'兩次輸入的密碼不一致！',
  createdSuccessfully:'創建成功',
  addedSuccessfully:'添加成功',
  modifyAddress:'修改地址',
  modifiedSuccessfully:'修改成功',
  copy:'文本已複製到剪貼板！',
  rechargeSuccessful:'充值成功',
  deleteSuccessfully:'刪除成功',
  paymentSuccessful:'支付成功',
  payOrder: '支付訂單',
  pay: '支付',
  available: '可用',
  enterPaymentPassword: '輸入 6 位支付密碼',
  confirmSuccessfulReceiptOfGoods:'確認成功收貨',
  correct:' 正確 ',
  pleaseUploadInFull:'請完整上傳所需圖片！',
  submittedSuccessfully:'提交成功',
  evaluate:'評價',
  PleaseFillInTheFormCompletely:'請完整填寫表格！',
  qrCodePlaceholder:'二維碼',
  verificationCodeSentSuccessfully:'驗證碼發送成功，請在 60 秒內填寫並提交！',
  pleaseEnterYourEmailFirst:'請先輸入您的郵箱',
  ContactTheMerchant: "聯絡商家",
  inputIssues: '輸入問題',
}
export default zhs
