/**
 * 系统配置文件
 */
module.exports = {
  // 标题
  title: '',
  // 是否开启调试（控制台打印响应数据）
  debugging: false,
  // token在localStorage、sessionStorage、cookie存储的key的名称
  tokenTableName: 'access-token',
  // token存储位置localStorage sessionStorage cookiesStorage
  storage: localStorage,
  // pinia 持久化存储key值
  piniaKey: 'user',
  // 不经过token校验的路由
  routesWhiteList: ['/login', '/404', '/403'],
  // 路由模式，可选值为 history 或 hash
  routerMode: 'history',
  // footer字段
  footerTitle:'',
}
