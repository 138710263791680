<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data(){
    return {

    }
  },
  created() {

  }
}
</script>

<style>
html,body{
  margin: 0;
  height: 100%;
}
</style>
