const sl = {
  route: {
    home: 'Domov',
    shopCart: 'Nakupovalni voziček',
    popularStore: 'Priljubljena trgovina',
    storeDetails: 'Podrobnosti trgovine',
    hotSellingProducts: 'Najbolje prodajani izdelki',
    productClassification: 'Razvrstitev izdelkov',
    productDetails: 'Podrobnosti o izdelku',
    orderPay: 'Plačilo naročila',
  },
  home: "Domov",
  wallet: "Denarnica",
  order: "Center naročil",
  sell: "Prodajni center",
  cart: "Voziček",
  search: "Iskanje",
  deals: "Današnje ponudbe",
  featuredStore: "Izbrana trgovina",
  spotlight: "Poudarek",
  hotSale: "Vroča prodaja",
  fashion: "Moda",
  beautyPersonalCare: "Lepota in osebna nega",
  homeKitchen: "Dom in kuhinja",
  sportsOutdoors: "Šport in prosti čas",
  electronicsAccessories: "Elektronika in dodatki",
  seeAll: "Poglej vse",
  category: "Kategorija",
  beauty: 'Lepota',
  personalCare: 'Osebna nega',
  homeFurnishing: 'Dom',
  kitchen: 'Kuhinja',
  sports: 'Šport',
  outdoors: 'Na prostem',
  electronics: 'Elektronika',
  accessories: 'Dodatki',
  office: 'Pisarna',
  products: 'Izdelki',
  food: 'Hrana',
  drinks: 'Pijače',
  collectibles: 'Zbirateljski predmeti',
  automotive: 'Avtomobilizem',
  storeOnTikTokShop: 'Trgovina na TikTok Shopu',
  viewShop: 'Poglej trgovino',
  reviews: 'Ocene',
  topProducts: 'Najboljši izdelki',
  newReleases: 'Nove izdaje',
  balance: 'Stanje',
  recharge: 'Polnjenje',
  withdraw: 'Dvig',
  fundAccount: 'Napolni račun',
  add: 'Dodaj',
  addFundAccount: 'Dodaj račun za sredstva',
  crypto: 'Kriptovalute',
  mainnet: 'Mainnet',
  address: 'Naslov',
  debitCards: 'Debetne kartice',
  iban: 'IBAN',
  account: 'Račun',
  cardNumber: 'Številka kartice',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount: 'Znesek',
  qrCode: 'QR koda',
  rechargeAmountUsdt: 'Znesek polnjenja (USDT)',
  pleaseEnterTheRechargeAmountUsdt: 'Vnesite znesek polnjenja (USDT)',
  submitForReview: 'Pošlji v pregled',
  purchased: "Kupljeno",
  carriage: "Prevoz",
  completed: "Zaključeno",
  awaitingShipment: 'Čakanje na pošiljko',
  orderDetails: "Podrobnosti naročila",
  receive: "Prejmi",
  review: 'Pregled',
  enterprise: 'Podjetje',
  personal: 'Osebno',
  businessName: 'Ime podjetja',
  headOfEnterprise: 'Vodja podjetja',
  headPhoneNumber: 'Telefonska številka vodje',
  headWhatsAppNumber: 'WhatsApp številka vodje',
  submit: 'Pošlji',
  uploadLogo: 'Naloži logotip',
  storeName: 'Ime trgovine',
  storeEmail: 'E-pošta trgovine',
  storeHeadName: 'Ime vodje trgovine',
  storeHeadIdNumber: 'ID številka vodje trgovine',
  idFrontPhoto: 'Fotografija sprednje strani ID',
  idBackPhoto: 'Fotografija zadnje strani ID',
  personalHandheldIdPhoto: 'Fotografija ID v roki',
  storeHeadPhoneNumber: 'Telefonska številka vodje trgovine',
  storeSupportNumber: 'Podporna številka trgovine',
  invitationCode: 'Vabilo koda',
  login: 'Prijava',
  createAccount: 'Ustvari račun',
  emailOrPhone: 'E-pošta ali telefonska številka',
  password: 'Geslo',
  signIn: 'Prijava',
  yourName: 'Tvoje ime',
  reEnterPassword: 'Ponovno vnesite geslo',
  emailOrPhoneCode: 'Koda za e-pošto ali telefon',
  request: 'Zahteva',
  createAccountButton: 'Ustvari račun',
  privacyPolicy: '《Politika zasebnosti TikTok》',
  acknowledgmentText: 'S klikom na Shrani potrjujete, da ste prebrali {privacyPolicy}. Vaši podatki se lahko uporabijo za personalizacijo vaše izkušnje, vključno z oglasi, odvisno od vaših nastavitev.',
  buy: 'Kupi',
  exploreBargains: 'Raziskuj kakovostne ponudbe',
  aboutSpotlight: 'O poudarku',
  freeShipping: 'Brezplačna dostava',
  freeShippingDescription: 'Pri naročilih nad 25 $',
  freeReturns: 'Brezplačna vračila',
  freeReturnsDescription: 'V 30 dneh',
  shopPerformance: 'Učinkovitost trgovine',
  betterThan: 'Boljša kot 80 % drugih trgovin',
  starRating: 'Ocena z več kot 4 zvezdicami',
  shipsWithin: 'Pošiljka v 2 dneh',
  reviewsCount: '({count} ocen)',
  cartEmpty: 'Vaš voziček je prazen',
  selectAll: 'Izberi vse',
  totalItems: 'Skupaj ({num} predmetov)',
  checkout: 'Na blagajno',
  shippingReturnsTitle: 'Dostava in vračila',
  estimatedDelivery: 'Predvidena dostava 11.-13. julij, 7,43 $',
  shippingTo: 'Dostava v Denver, 17517',
  shipsOut: 'Običajno pošilja v 1 delovnem dnevu',
  returnPolicyTitle: 'Politika vračila',
  addToCart: 'Dodaj v voziček',
  aboutThisProduct: 'O tem izdelku',
  shippingAddress: 'Naslov za dostavo',
  addAddress: 'Dodaj naslov',
  paymentMethod: 'Način plačila',
  shippingMethod: 'Način dostave',
  orderSummary: 'Povzetek naročila',
  subtotal: 'Vmesni seštevek',
  shipping: 'Dostava',
  shippingToBeCalculated: 'bo izračunano',
  total: 'Skupaj ({num} predmetov)',
  firstName: 'Ime',
  lastName: 'Priimek',
  street: 'Ulica',
  apartment: 'Stanovanje, apartma itd. (neobvezno)',
  city: 'Mesto',
  state: 'Država',
  zipCode: 'Poštna številka',
  phone: 'Telefon',
  save: 'Shrani',
  privacyPolicyDescription: 'S klikom na Shrani potrjujete, da ste prebrali {privacyPolicy}. Vaši podatki se lahko uporabijo za personalizacijo vaše izkušnje, vključno z oglasi, odvisno od vaših nastavitev.',
  usCountryCode: 'US+1',
  logout: 'Odjava',
  selectShippingMethod: 'Izberite način dostave',
  standardShipping: "Standardna dostava: ${amount}",
  estimatedDeliverys: "Predvidena dostava: {date}",
  taxes: "Davki",
  breadcrumbs: {
    classification: 'Klasifikacija',
    subClassification: 'Podkategorija',
    selectedProduct: 'Izbran izdelek',
  },
  downloadApp: 'Prenesi aplikacijo',
  register: 'Registracija',
  verifiedPurchase: 'Preverjen nakup',
  pleaseInput: 'Vnesite prosim ',
  code: 'Koda',
  effective: 'Učinkovito',
  pleaseEnterPasswordAgain: 'Prosimo, ponovno vnesite geslo',
  passwordInconsistent: 'Gesli se ne ujemata!',
  createdSuccessfully: 'Uspešno ustvarjeno',
  addedSuccessfully: 'Uspešno dodano',
  modifyAddress: 'Spremeni naslov',
  modifiedSuccessfully: 'Uspešno spremenjeno',
  copy: 'Besedilo je bilo kopirano v odložišče!',
  rechargeSuccessful: 'Polnjenje uspešno',
  deleteSuccessfully: 'Uspešno izbrisano',
  paymentSuccessful: 'Plačilo uspešno',
  payOrder: 'Plačaj naročilo',
  pay: 'Plačaj',
  available: 'Na voljo',
  enterPaymentPassword: 'Vnesite 6-mestno geslo za plačilo',
  confirmSuccessfulReceiptOfGoods: 'Potrdite uspešen prejem blaga',
  correct: 'Pravilno',
  pleaseUploadInFull: 'Prosimo, naložite vse zahtevane slike!',
  submittedSuccessfully: 'Uspešno poslano',
  evaluate: 'Ocenite',
  PleaseFillInTheFormCompletely: 'Prosimo, popolnoma izpolnite obrazec!',
  qrCodePlaceholder: 'QR koda',
  verificationCodeSentSuccessfully: 'Preveritvena koda uspešno poslana, prosimo, izpolnite in pošljite v 60 sekundah!',
  pleaseEnterYourEmailFirst: 'Prosimo, najprej vnesite svoj e-poštni naslov',
  ContactTheMerchant: "Kontaktirajte trgovca",
  inputIssues: 'Vnesite težave',
}
export default sl
