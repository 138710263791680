import { defineStore } from 'pinia'
import actions from "@/stores/modules/user/userModule/actions";
import getters from "@/stores/modules/user/userModule/getters";
import state from "@/stores/modules/user/userModule/state";
import {storage,piniaKey} from '@/config/index'

export const useCounterStore = defineStore('counter', {
  id: 'User',
  state,
  getters,
  actions,
  /**
   * pinia持久化储存
   */
  persist: {
    enabled: true,
    strategies: [
      {
        key: piniaKey,
        storage: storage, // 指定存储的位置
        paths: ['token', 'uid','user_info'], //指定要长久化的字段
      }
    ]
  }
})
